import { showError } from "./dialogs"

declare var __isDevEnv: boolean

window.addEventListener('error', function (e: ErrorEvent) {
  if (!(e instanceof ReferenceError) && __isDevEnv)
    showError(e.message)
})

window.addEventListener('unhandledrejection', function (e: PromiseRejectionEvent) {
  showError(e.reason.message)
})