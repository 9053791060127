import { onDOMContentLoaded } from './util/index.js';
const CLASS_NAME_HOLD_TRANSITIONS = 'hold-transition';
const CLASS_NAME_APP_LOADED = 'app-loaded';
class Layout {
    _element;
    constructor(element) {
        this._element = element;
    }
    holdTransition() {
        let resizeTimer;
        window.addEventListener('resize', () => {
            document.body.classList.add(CLASS_NAME_HOLD_TRANSITIONS);
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => {
                document.body.classList.remove(CLASS_NAME_HOLD_TRANSITIONS);
            }, 400);
        });
    }
}
onDOMContentLoaded(() => {
    const data = new Layout(document.body);
    data.holdTransition();
    setTimeout(() => {
        document.body.classList.add(CLASS_NAME_APP_LOADED);
    }, 400);
});
export default Layout;
