import apiEndpoints from "../../api";
import { _fetchJson } from "ts/autoload/_fetch";
import { Datatable } from "../datatable";
import { nextTick } from "#page-builder/nextTick";
export class BlockedItem {
    id;
    ip;
    user_agent;
    denial_reason;
    created_at;
    constructor(i) {
        Object.assign(this, i);
    }
    formattedCreatedAt() {
        return new Date(this.created_at).toLocaleString();
    }
    formattedDenialReason() {
        switch (parseInt(this.denial_reason)) {
            case 1: return 'Did not click Sponsered Ads';
            case 2: return 'Fake mobile browsers or mobile apps';
            case 3: return 'Desktop browsers';
            case 4: return 'Tablet browsers';
            case 5: return 'IP address blocked temporarily';
            case 6: return 'VPN/Proxy';
            case 7: return 'Blocked by Blacklist';
            case 8: return '';
            case 9: return '';
        }
    }
}
export class BlockedListModal extends Datatable {
    _modal;
    loading;
    constructor(items, selectAllCheckbox) {
        super(items, {
            ipOrUserAgent: '',
        });
        this._selectAllCheckbox = selectAllCheckbox;
    }
    static create(items, selectAllCheckbox) {
        let blockedList = [];
        for (let i of items) {
            const p = new BlockedItem(i);
            blockedList.push(p);
        }
        return new BlockedListModal(blockedList, selectAllCheckbox);
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            ipOrUserAgent: '',
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        return item.ip.toLowerCase().includes(this.search.ipOrUserAgent.toLowerCase())
            || item.user_agent.toLowerCase().includes(this.search.ipOrUserAgent.toLowerCase());
    }
    async refresh(type) {
        try {
            const json = await _fetchJson(apiEndpoints.setQueryParams({ type }).CAMPAIGN_BLOCKED_LIST_URI, {});
            if (json) {
                const items = [];
                for (let i of json.items) {
                    items.push(new BlockedItem(i));
                }
                this.clear();
                this.addItems(items);
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(type) {
        this.loading = false;
        this.refresh(type);
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
    closeToolsDropdown(el) {
        nextTick(function () {
            const btn = el.querySelector('[data-bs-toggle="dropdown"]');
            const dropdown = bootstrap.Dropdown.getInstance(btn);
            if (dropdown) {
                dropdown.hide();
            }
        });
    }
}
