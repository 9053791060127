export class Datatable {
    _items;
    _search;
    _filteredItems = [];
    _selectAllCheckbox;
    _currentPage = 0;
    itemsPerPage = 100;
    constructor(_items = [], _search = null) {
        this._items = _items;
        this._search = _search;
        this.filterItems();
    }
    isEqual(a, b) {
        return a === b;
    }
    matchesSearchCriteria(_item) {
        return true;
    }
    doBeforePageHide() { }
    doAfterPageShow() { }
    checkIndeterminate() {
        if (this._selectAllCheckbox) {
            let hasSelected = false;
            let hasUnSelected = false;
            for (let i of this._filteredItems) {
                if (this.isSelected(i)) {
                    hasSelected = true;
                }
                else {
                    hasUnSelected = true;
                }
                if (hasSelected && hasUnSelected) {
                    break;
                }
            }
            this._selectAllCheckbox.indeterminate = hasSelected && hasUnSelected;
        }
    }
    filterItems() {
        this._filteredItems = this._items.filter(i => {
            let result = !this._search || this.matchesSearchCriteria(i);
            return result;
        });
    }
    doSearch(keepCurrentPagerIndex = false) {
        try {
            this.doBeforePageHide();
            this.filterItems();
            if (!keepCurrentPagerIndex) {
                this.currentPage = 0;
                this.doAfterPageShow();
            }
            else if (this.currentPage >= this.pageCount) {
                this.currentPage = this.currentPage - 1;
                this.doAfterPageShow();
            }
        }
        finally {
            this.checkIndeterminate();
        }
    }
    get currentPage() {
        return this._currentPage;
    }
    set currentPage(value) {
        if (value >= 0 && value < this.pageCount) {
            this._currentPage = value;
        }
        else {
            this._currentPage = 0;
        }
    }
    get search() {
        return this._search;
    }
    set search(value) {
        try {
            this.doBeforePageHide();
            this._search = value;
            this.filterItems();
            this.currentPage = 0;
            this.doAfterPageShow();
        }
        finally {
            this.checkIndeterminate();
        }
    }
    get items() {
        return this._filteredItems;
    }
    get currentPageItems() {
        let result = [];
        for (let i = this.currentPage * this.itemsPerPage; i < (this.currentPage + 1) * this.itemsPerPage; i++) {
            if (i < this._filteredItems.length) {
                result.push(this._filteredItems[i]);
            }
        }
        return result;
    }
    get pageCount() {
        return Math.ceil(this._filteredItems.length / this.itemsPerPage);
    }
    get firstShowingItem() {
        return this._filteredItems.length ? this.currentPage * this.itemsPerPage + 1 : 0;
    }
    get lastShowingItem() {
        const result = this._filteredItems.length ? (this.currentPage + 1) * this.itemsPerPage : 0;
        return Math.min(this._filteredItems.length, result);
    }
    get numOfItems() {
        return this._filteredItems.length;
    }
    clear() {
        this._items = [];
        this._currentPage = 0;
        this.filterItems();
    }
    gotoPage(page) {
        if (page >= 0 && page < this.pageCount) {
            this.doBeforePageHide();
            this.currentPage = page;
            this.doAfterPageShow();
        }
    }
    add(item) {
        this._items.push(item);
        this.doSearch();
    }
    addItems(items) {
        this._items.push(...items);
        this.doSearch();
    }
    remove(item) {
        this._items = this._items.filter(i => !this.isEqual(i, item));
        this._filteredItems = this._filteredItems.filter(i => !this.isEqual(i, item));
    }
    select(item, select) {
        item.__selected = select;
        this.checkIndeterminate();
    }
    selectAll(select) {
        for (let item of this._filteredItems) {
            this.select(item, select);
        }
    }
    isSelected(item) {
        return !!item.__selected;
    }
    get isAllSelected() {
        for (let i of this._filteredItems) {
            if (!this.isSelected(i)) {
                return false;
            }
        }
        return true;
    }
    get allPagerItems() {
        let result = [];
        for (let i = 0; i < this.pageCount; i++) {
            result.push(i + 1);
        }
        return result;
    }
}
