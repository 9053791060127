import { isEmail } from "../../utils";
export class Member {
    isEditingMember = false;
    email = '';
    insight = false;
    content = false;
    people = false;
    constructor(options = {}) {
        this.reset();
        this.merge(options);
    }
    get isValidEmail() {
        return isEmail(this.email);
    }
    isValid(field = '*') {
        switch (field) {
            case '*': return this.isValidEmail;
            case 'email': return this.isValidEmail;
        }
        return false;
    }
    reset() {
        this.email = '';
        setDefaultPermissions(this);
    }
    merge(data) {
        if (this instanceof Member) {
            for (const field of Object.getOwnPropertyNames(this)) {
                if (typeof data[field] !== 'undefined')
                    this[field] = data[field];
            }
        }
        else {
            for (const field of Object.getOwnPropertyNames(new Member())) {
                if (typeof data[field] !== 'undefined')
                    this[field] = data[field];
            }
        }
    }
    clone() {
        let result = new Member();
        for (let k in result) {
            result[k] = this[k];
        }
        return result;
    }
    showModal($data, $refs) {
        this.merge($data.member);
        this.isEditingMember = $data.edit;
        bootstrap.Modal.getOrCreateInstance($refs.modal, {}).show();
    }
    save($dispatch, $refs) {
        $dispatch('saveMember', this.clone());
        bootstrap.Modal.getOrCreateInstance($refs.modal, {}).hide();
    }
}
