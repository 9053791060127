import { _fetchJson } from "./_fetch";
import apiEndpoints from "./api";
import { showError } from "./dialogs";
export { nextTick } from "#page-builder/nextTick";
export { stripTags } from '../util/strip_tags/striptags';
export { resolveButtonStyle, ButtonStyle } from '#page-builder/types/buttons';
export function getCookie(name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match)
        return match[2];
}
export function setCookie(name, value) {
    document.cookie = `${name}=${value}; path=/; domain=${getDomainName()}`;
}
export function getDomainName() {
    const hostName = location.hostname;
    return hostName.substring(hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1);
}
export function isEmail(str) {
    return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(str);
}
export function blockForm(form, blocked) {
    if (blocked) {
        form.setAttribute('inert', '');
    }
    else {
        form.removeAttribute('inert');
    }
}
export function htmlEncode(input) {
    return input
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
}
export function formatNumber(number) {
    return Intl.NumberFormat('en-US').format(number);
}
export function formatPercentage(number1, number2) {
    const p = number2 > 0 ? number1 / number2 * 100 : 0;
    return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(p) + '%';
}
export function hasInputError(form) {
    const hasError = form.querySelector('[__invalid], [__required]');
    if (hasError && hasError.checkVisibility()) {
        showError('Invalid input found, please correct the input data');
        return true;
    }
    return false;
}
export async function refreshActivePagesList() {
    const json = await _fetchJson(apiEndpoints.CAMPAIGN_ACTIVE_PAGES_URI);
    if (json) {
        activePages = json.pages;
    }
}
export function getPageName(id) {
    for (let i of activePages) {
        if (i.id == id) {
            return i.name;
        }
    }
    return '<unknown>';
}
export function parseSearchData(campaigns) {
    for (let c of campaigns) {
        function findDomain(domainId) {
            for (let d of c.domains) {
                if (d.id == domainId) {
                    return d.domain;
                }
            }
            return '';
        }
        for (let p of c.pages) {
            p.fullPath = findDomain(p.domainId) + '/' + p.path;
        }
    }
    return campaigns;
}
export function trim(str, ch) {
    let start = 0, end = str.length;
    while (start < end && str[start] === ch)
        ++start;
    while (end > start && str[end - 1] === ch)
        --end;
    return (start > 0 || end < str.length) ? str.substring(start, end) : str;
}
export function fireEvent(event, detail = undefined) {
    window.dispatchEvent(new CustomEvent(event, { detail }));
}
export function fireUpdateLeaderLinesEvent() {
    window.dispatchEvent(new CustomEvent('updateLeaderLines'));
}
