export function makeid(length: number = 32): string {
  let secret = ""
  let randomSeed: Uint8Array
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'

  while (secret.length < length) {
    randomSeed = crypto.getRandomValues(new Uint8Array(256))
    for (let i = 0; i < randomSeed.length; i++) {
      const char = String.fromCharCode(randomSeed[i])

      if (characters.includes(char)) {
        secret += char
      }

      if (secret.length === length) {
        break
      }
    }
  }

  return secret
}

export function makeid10(): string {
  return makeid(10)
}

export function camelCaseToDash(str: string) {
  return str
      .replace(/[^a-zA-Z0-9]+/g, '-')
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1-$2')
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/([0-9])([^0-9])/g, '$1-$2')
      .replace(/([^0-9])([0-9])/g, '$1-$2')
      .replace(/-+/g, '-')
      .toLowerCase();
}

export function opacity100ToHex(opacity: number): string {
  return Math.trunc(opacity / 100 * 255).toString(16)
}

export function image(img: string): string {
  return `/images/${img}`
}