import apiEndpoints from "../../api";
import { csrfToken } from "../../csrf";
import { ButtonKind, Icon, showDialog } from "../../dialogs";
import { nextTick } from "#page-builder/nextTick";
import { Datatable } from "../datatable";
import { _fetchJson2 } from "ts/autoload/_fetch";
export class CampaignInfo {
    id;
    name;
    owner;
    requests;
    visitors;
    conversions;
    blocked;
    updatedAt;
    archived;
    breakdown;
    statistics6w;
    statistics6m;
    constructor(i) {
        Object.assign(this, i);
    }
    toJSONStr() {
        return JSON.stringify(this);
    }
    reCalcStats(period) {
        const data = period == 1 ? this.statistics6w : this.statistics6m;
        this.requests = data.requests;
        this.visitors = data.visitors;
        this.conversions = data.conversions;
        this.blocked = data.blocked;
        this.breakdown = data.breakdown;
    }
    get blockedTotal() {
        let result = 0;
        for (let i in this.blocked) {
            result += parseInt(this.blocked[i]);
        }
        return result;
    }
}
export class CampaignIndex extends Datatable {
    _period = 1;
    _charts = {};
    constructor(items, selectAllCheckbox) {
        super(items, {
            name: '',
            owner: '',
            archived: false,
        });
        this._selectAllCheckbox = selectAllCheckbox;
    }
    static create(items, selectAllCheckbox) {
        let campaigns = [];
        for (let i of items) {
            const p = new CampaignInfo(i);
            p.reCalcStats(1);
            campaigns.push(p);
        }
        return new CampaignIndex(campaigns, selectAllCheckbox);
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            name: '',
            owner: '',
            archived: false,
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        return item.name.toLowerCase().includes(this.search.name.toLowerCase())
            && (!this.search.owner || item.owner.id == this.search.owner)
            && (this.search.archived == !!item.archived);
    }
    createChart(item) {
        const id = parseInt(item.id);
        const result = document.createElement('div');
        result.id = `chart-${id}-${this.period}`;
        this._charts[`${id}-${this.period}`] = result;
        chartsContainer.appendChild(result);
        createCampaignStatasticsChart(result, item);
        return result;
    }
    getChart(item, $el) {
        const id = parseInt(item.id);
        if (!Number.isNaN(id)) {
            const result = this._charts[`${id}-${this.period}`] ? this._charts[`${id}-${this.period}`] : this.createChart(item);
            if (result.parentElement) {
                result.parentElement.removeChild(result);
            }
            nextTick(function () { $el.appendChild(result); });
        }
        return '';
    }
    getOwnerOptions() {
        let owners = [];
        let result = [`<option value="" selected>-- Select --</option>`];
        for (let i of this._items) {
            if (!owners.includes(i.owner.id)) {
                result.push(`<option value="${i.owner.id}">${i.owner.fullName} [${i.owner.email}]</option>`);
                owners.push(i.owner.id);
            }
        }
        return result.join('\n');
    }
    showStatisticsPopover(item, el) {
        nextTick(function () {
            const popover = bootstrap.Popover.getOrCreateInstance(el, {
                html: true,
                content: `
          <span class="badge rounded-pill text-bg-primary">Requests</span>
          <span class="badge rounded-pill text-bg-secondary">Unique Visitors</span>
          <span class="badge rounded-pill text-bg-success">Conversions</span>
          <span class="badge rounded-pill text-bg-danger">Blocked</span>
        `,
                customClass: 'campaignsIndexPopover',
                placement: 'left',
                sanitize: false,
            });
            popover.toggle();
        });
    }
    hideStatisticsPopover(_item, el) {
        nextTick(function () {
            const popover = bootstrap.Popover.getInstance(el);
            if (popover) {
                popover.hide();
            }
        });
    }
    closeToolsDropdown(el) {
        nextTick(function () {
            const btn = el.querySelector('[data-bs-toggle="dropdown"]');
            const dropdown = bootstrap.Dropdown.getInstance(btn);
            if (dropdown) {
                dropdown.hide();
            }
        });
    }
    get period() {
        return this._period;
    }
    set period(value) {
        this._period = value;
        for (let i of this._items) {
            i.reCalcStats(value);
        }
    }
    editCampaignSuccess(jsonStr) {
        const json = JSON.parse(jsonStr);
        for (let i of this._items) {
            if (i.id == json.id) {
                i.name = json.name;
                i.formatedUpdatedAt = json.formatedUpdatedAt;
            }
        }
    }
    archive(campaign) {
        const self = this;
        showDialog(!campaign.archived ? 'Are you sure you would like to archive this campaign?' : 'Are you sure you would like to unarchive this campaign?', {
            icon: Icon.Question,
            buttons: [
                {
                    kind: ButtonKind.Cancel,
                    dismiss: true,
                },
                {
                    kind: ButtonKind.OK,
                    dismiss: true,
                    onclick: async function () {
                        const res = await _fetchJson2(apiEndpoints.setParams({ campaign_id: campaign.id }).CAMPAIGN_ARCHIVE_URI, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'x-csrf-token': csrfToken()
                            },
                            body: JSON.stringify({ value: !campaign.archived })
                        });
                        if (res) {
                            campaign.archived = !campaign.archived;
                            setTimeout(function () {
                                self.doSearch(true);
                            }, 1);
                        }
                    },
                }
            ]
        });
    }
}
export function createCampaignStatasticsChart(elm, item) {
    const chart_options = {
        series: [
            {
                name: "Requests",
                data: item.breakdown.requests,
            },
            {
                name: "Visitors",
                data: item.breakdown.visitors,
            },
            {
                name: "Conversions",
                data: item.breakdown.conversions,
            },
            {
                name: "Blocked",
                data: item.breakdown.blocked,
            },
        ],
        chart: {
            height: 80,
            type: "area",
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        colors: ['#1163ce', "#6c757d", "#198754", '#dc3545',],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 1,
        },
        xaxis: {
            categories: item.breakdown.labels,
            show: false,
            labels: {
                show: false,
            },
            axisTicks: {
                show: false,
                height: 0,
            }
        },
        yaxis: {
            show: false,
            labels: {
                show: false,
            },
        },
        tooltip: {},
    };
    const chart = new ApexCharts(elm, chart_options);
    chart.render();
}
