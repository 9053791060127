export var DomainStatus;
(function (DomainStatus) {
    DomainStatus[DomainStatus["Pending"] = 0] = "Pending";
    DomainStatus[DomainStatus["AppDnsRecordCreated"] = 1] = "AppDnsRecordCreated";
    DomainStatus[DomainStatus["Connected"] = 2] = "Connected";
    DomainStatus[DomainStatus["DnsDoesNotPointToServer"] = 10] = "DnsDoesNotPointToServer";
    DomainStatus[DomainStatus["SslError"] = 20] = "SslError";
    DomainStatus[DomainStatus["Disconnected"] = 100] = "Disconnected";
})(DomainStatus || (DomainStatus = {}));
export default DomainStatus;
