import { compose } from "#page-builder/compose";
import { nextTick } from "#page-builder/nextTick";
import { makeid, makeid10 } from "#page-builder/utils";
import { Button, ButtonType } from "#page-builder/types/buttons";
import { withImageCropper } from "#page-builder/utils/image-cropper";
const LEADER_LINE_SIZE = 2;
function makeNodeID() {
    return makeid(10);
}
export var LivechatNodeType;
(function (LivechatNodeType) {
    LivechatNodeType[LivechatNodeType["start"] = 0] = "start";
    LivechatNodeType[LivechatNodeType["text"] = 1] = "text";
    LivechatNodeType[LivechatNodeType["image"] = 2] = "image";
    LivechatNodeType[LivechatNodeType["voice"] = 3] = "voice";
    LivechatNodeType[LivechatNodeType["video"] = 4] = "video";
    LivechatNodeType[LivechatNodeType["file"] = 5] = "file";
    LivechatNodeType[LivechatNodeType["input"] = 6] = "input";
    LivechatNodeType[LivechatNodeType["cta"] = 10] = "cta";
    LivechatNodeType[LivechatNodeType["quiz"] = 20] = "quiz";
    LivechatNodeType[LivechatNodeType["end"] = 100] = "end";
})(LivechatNodeType || (LivechatNodeType = {}));
class Node {
    uuid = '';
    type = LivechatNodeType.text;
    content = '';
    x = 0;
    y = 0;
    leaderLine = null;
    nextNode = null;
    _nextNodeUuid = '';
    _el = null;
    constructor(meta = {}) {
        const defaultOptions = {
            uuid: '',
            type: LivechatNodeType.text,
            content: '',
            x: 0,
            y: 0,
            _nextNodeUuid: ''
        };
        meta = { ...defaultOptions, ...meta };
        this.uuid = meta.uuid || makeNodeID();
        this.type = meta.type;
        this.content = meta.content;
        this.x = meta.x;
        this.y = meta.y;
        this._nextNodeUuid = meta.nextNode || meta._nextNodeUuid;
    }
    get isStart() {
        return this.type == LivechatNodeType.start;
    }
    get isEnd() {
        return this.type == LivechatNodeType.end;
    }
    get isText() {
        return this.type == LivechatNodeType.text;
    }
    get isImage() {
        return this.type == LivechatNodeType.image;
    }
    get isVoice() {
        return this.type == LivechatNodeType.voice;
    }
    get isVideo() {
        return this.type == LivechatNodeType.video;
    }
    get isFile() {
        return this.type == LivechatNodeType.file;
    }
    get isInput() {
        return this.type == LivechatNodeType.input;
    }
    get isCta() {
        return this.type == LivechatNodeType.cta;
    }
    link(node, board) {
        if (this.uuid != node.uuid) {
            this.nextNode = node;
            if (this.leaderLine) {
                this.leaderLine().end = node._el();
            }
            else {
                const ll = new LeaderLine(this._el(), node._el(), { size: LEADER_LINE_SIZE, parent: board });
                this.leaderLine = () => ll;
            }
            this.showLeaderLines();
        }
    }
    destroyLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().remove();
            this.leaderLine = null;
        }
    }
    showLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().show();
        }
    }
    hideLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().hide();
        }
    }
    highlightLeaderLines(highlight) {
        if (this.leaderLine) {
            this.leaderLine().size = highlight ? 3 : 2;
            this.leaderLine().color = highlight ? '#1163ce' : 'coral';
            this.leaderLine().setZIndex(highlight ? 3 : '');
        }
        if (typeof this.children != 'undefined') {
            for (let c of this.children) {
                c.highlightLeaderLines(highlight);
            }
        }
    }
    get pojo() {
        const result = {
            uuid: this.uuid,
            type: this.type,
            content: this.content,
            x: this.x,
            y: this.y,
            nextNode: this.nextNode?.uuid || this._nextNodeUuid
        };
        return result;
    }
    static fromJSON(json) {
        let result = new NodeClassMap[LivechatNodeType[json.type]](json);
        return result;
    }
}
class StartNode extends Node {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.start });
    }
}
class TextNode extends Node {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.text });
    }
}
class ImageNode extends compose(Node, withImageCropper('content', Node)) {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.image });
    }
}
class VoiceNode extends Node {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.voice });
    }
}
class VideoNode extends Node {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.video });
    }
}
class FileNode extends Node {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.file });
    }
}
class CallToActionButton extends Button {
    leaderLine = null;
    nextNode;
    _el = null;
    constructor(options = {}) {
        super(options);
        this.nextNode = options.nextNode || '';
    }
    get pojo() {
        let result = super.pojo;
        return {
            ...result,
            nextNode: this.nextNode
        };
    }
    merge(that) {
        super.merge(that);
        this.nextNode = that.nextNode;
        return this;
    }
    link(node, board) {
        this.nextNode = node.uuid;
        if (this.leaderLine) {
            this.leaderLine().end = node._el();
        }
        else {
            const ll = new LeaderLine(this._el(), node._el(), { size: LEADER_LINE_SIZE, startSocket: 'right', parent: board });
            this.leaderLine = () => ll;
        }
        this.showLeaderLines();
    }
    destroyLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().remove();
            this.leaderLine = null;
        }
    }
    showLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().show();
        }
    }
    hideLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().hide();
        }
    }
    highlightLeaderLines(highlight) {
        if (this.leaderLine) {
            this.leaderLine().size = highlight ? 3 : 2;
            this.leaderLine().color = highlight ? '#1163ce' : 'coral';
            this.leaderLine().setZIndex(highlight ? 3 : '');
        }
    }
}
class CtaNode extends Node {
    buttons = [
        new CallToActionButton({
            text: 'Edit me',
            type: ButtonType.Continue
        })
    ];
    editing = false;
    editButton = null;
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.cta });
        if (meta.buttons) {
            let buttons = [];
            for (let i of meta.buttons) {
                const b = new CallToActionButton(i);
                buttons.push(b);
            }
            this.buttons = buttons;
        }
    }
    get children() {
        return this.buttons;
    }
    updateLeaderLines(uuid = '') {
        for (let i of this.buttons) {
            if (!uuid || i.nextNode == uuid) {
                if (i.leaderLine) {
                    i.leaderLine().position();
                }
            }
        }
    }
    find(uuid) {
        for (let i of this.buttons) {
            if (i.uuid == uuid) {
                return i;
            }
        }
    }
    move(btn, index) {
        if (index >= 0 && index < this.buttons.length) {
            const array = this.buttons.filter(i => i.uuid != btn.uuid);
            array.splice(index, 0, btn);
            this.buttons = array;
            fireUpdateLeaderLinesEvent();
        }
    }
    moveUp(btn) {
        const index = this.buttons.findIndex(i => i.uuid == btn.uuid);
        this.move(btn, index - 1);
    }
    moveDown(btn) {
        const index = this.buttons.findIndex(i => i.uuid == btn.uuid);
        this.move(btn, index + 1);
    }
    newButton() {
        this.editing = true;
        this.editButton = new CallToActionButton();
    }
    edit(button) {
        this.editing = true;
        this.editButton = new CallToActionButton(button);
        fireUpdateLeaderLinesEvent();
    }
    delete(button) {
        this.buttons = this.buttons.filter(i => i.uuid != button.uuid);
        button.destroyLeaderLines();
        fireUpdateLeaderLinesEvent();
    }
    cancel() {
        this.editing = false;
        const self = this;
        queueMicrotask(function () {
            self.editButton = null;
            fireUpdateLeaderLinesEvent();
        });
    }
    save() {
        this.editing = false;
        const self = this;
        queueMicrotask(function () {
            if (self.editButton && self.editButton.validate()) {
                try {
                    for (let i of self.buttons) {
                        if (i.uuid == self.editButton.uuid) {
                            i.merge(self.editButton);
                            return;
                        }
                    }
                    self.buttons.push(self.editButton);
                }
                finally {
                    self.editButton = null;
                    fireUpdateLeaderLinesEvent();
                }
            }
        });
    }
    applyStyle(btn, style) {
        if (this.editButton?.uuid == btn) {
            this.editButton.styleUUID = style;
        }
    }
    destroyLeaderLines() {
        super.destroyLeaderLines();
        for (let i of this.buttons) {
            i.destroyLeaderLines();
        }
    }
    showLeaderLines() {
        super.showLeaderLines();
        for (let i of this.buttons) {
            i.showLeaderLines();
        }
    }
    hideLeaderLines() {
        super.hideLeaderLines();
        for (let i of this.buttons) {
            i.hideLeaderLines();
        }
    }
    get pojo() {
        let result = super.pojo;
        result.buttons = [];
        for (let i of this.buttons) {
            result.buttons.push(i.pojo);
        }
        return result;
    }
}
class InputAction {
    uuid = '';
    comparison = '$Others';
    value = '';
    value2 = '';
    leaderLine = null;
    nextNode;
    _el = null;
    constructor(options = {}) {
        this.merge(options);
        this.nextNode = options.nextNode || '';
    }
    get valueText() {
        return this.comparison == '$InRange' || this.comparison == '$NotInRange' ? `${this.value}-${this.value2}` : this.value;
    }
    get comparisonText() {
        return this.comparison.substring(1);
    }
    get pojo() {
        return {
            uuid: this.uuid,
            comparison: this.comparison,
            value: this.value,
            value2: this.value2,
            nextNode: this.nextNode
        };
    }
    merge(that) {
        this.uuid = that.uuid || makeid10();
        this.comparison = that.comparison;
        this.value = that.value;
        this.value2 = that.value2;
        this.nextNode = that.nextNode || '';
        return this;
    }
    link(node, board) {
        this.nextNode = node.uuid;
        if (this.leaderLine) {
            this.leaderLine().end = node._el();
        }
        else {
            const ll = new LeaderLine(this._el(), node._el(), { size: LEADER_LINE_SIZE, startSocket: 'right', parent: board });
            this.leaderLine = () => ll;
        }
        this.showLeaderLines();
    }
    destroyLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().remove();
            this.leaderLine = null;
        }
    }
    showLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().show();
        }
    }
    hideLeaderLines() {
        if (this.leaderLine) {
            this.leaderLine().hide();
        }
    }
    highlightLeaderLines(highlight) {
        if (this.leaderLine) {
            this.leaderLine().size = highlight ? 3 : 2;
            this.leaderLine().color = highlight ? '#1163ce' : 'coral';
            this.leaderLine().setZIndex(highlight ? 3 : '');
        }
    }
}
class InputNode extends Node {
    actions = [
        new InputAction({
            comparison: '$Equal',
            value: 'Yes'
        })
    ];
    editing = false;
    editAction = null;
    inputType = 'number';
    constructor(options = {}) {
        super({ ...options, type: LivechatNodeType.input });
        let actions = [];
        if (options.actions) {
            for (let i of options.actions) {
                const b = new InputAction(i);
                actions.push(b);
            }
        }
        this.actions = actions;
    }
    get children() {
        return this.actions;
    }
    updateLeaderLines(uuid = '') {
        for (let i of this.actions) {
            if (!uuid || i.nextNode == uuid) {
                if (i.leaderLine) {
                    i.leaderLine().position();
                }
            }
        }
    }
    find(uuid) {
        for (let i of this.actions) {
            if (i.uuid == uuid) {
                return i;
            }
        }
    }
    move(action, index) {
        if (index >= 0 && index < this.actions.length) {
            const array = this.actions.filter(i => i.uuid != action.uuid);
            array.splice(index, 0, action);
            this.actions = array;
            fireUpdateLeaderLinesEvent();
        }
    }
    moveUp(action) {
        const index = this.actions.findIndex(i => i.uuid == action.uuid);
        this.move(action, index - 1);
    }
    moveDown(action) {
        const index = this.actions.findIndex(i => i.uuid == action.uuid);
        this.move(action, index + 1);
    }
    newButton() {
        this.editing = true;
        this.editAction = new InputAction();
    }
    edit(action) {
        this.editing = true;
        this.editAction = new InputAction(action);
        fireUpdateLeaderLinesEvent();
    }
    delete(action) {
        this.actions = this.actions.filter(i => i.uuid != action.uuid);
        action.destroyLeaderLines();
        fireUpdateLeaderLinesEvent();
    }
    cancel() {
        this.editing = false;
        const self = this;
        queueMicrotask(function () {
            self.editAction = null;
            fireUpdateLeaderLinesEvent();
        });
    }
    save() {
        this.editing = false;
        const self = this;
        queueMicrotask(function () {
            if (self.editAction) {
                try {
                    for (let i of self.actions) {
                        if (i.uuid == self.editAction.uuid) {
                            i.merge(self.editAction);
                            return;
                        }
                    }
                    self.actions.push(self.editAction);
                }
                finally {
                    self.editAction = null;
                    fireUpdateLeaderLinesEvent();
                }
            }
        });
    }
    destroyLeaderLines() {
        super.destroyLeaderLines();
        for (let i of this.actions) {
            i.destroyLeaderLines();
        }
    }
    showLeaderLines() {
        super.showLeaderLines();
        for (let i of this.actions) {
            i.showLeaderLines();
        }
    }
    hideLeaderLines() {
        super.hideLeaderLines();
        for (let i of this.actions) {
            i.hideLeaderLines();
        }
    }
    get pojo() {
        let result = super.pojo;
        result.inputType = this.inputType;
        result.actions = [];
        for (let i of this.actions) {
            result.actions.push(i.pojo);
        }
        return result;
    }
}
class EndNode extends Node {
    constructor(meta = {}) {
        super({ ...meta, type: LivechatNodeType.end });
    }
}
const NodeClassMap = {
    start: StartNode,
    text: TextNode,
    image: ImageNode,
    voice: VoiceNode,
    video: VideoNode,
    file: FileNode,
    input: InputNode,
    cta: CtaNode,
    quiz: EndNode,
    end: EndNode,
};
export class LiveChat {
    isSurvey;
    uuid = makeid10();
    _zoom = 1;
    _newNode = null;
    _movingNode = null;
    _movingBoard = false;
    _el = null;
    _updateLeaderLinesExTimer = null;
    nodes = [
        new StartNode({ x: 200, y: 50 }),
    ];
    constructor(isSurvey = false) {
        this.isSurvey = isSurvey;
    }
    isMovingNode(node) {
        return node?.uuid === this._movingNode?.uuid;
    }
    getNodeByID(id) {
        const [n, b] = id.split('/');
        const node = this.nodes.find(i => i.uuid == n);
        if (node && typeof node.find === 'function') {
            const child = node.find(b);
            return child ? child : node;
        }
        return node;
    }
    linkNodes(srcID, destID, board) {
        const src = this.getNodeByID(srcID);
        const dest = this.getNodeByID(destID);
        nextTick(function () {
            if (src && dest) {
                src.link(dest, board);
            }
        });
    }
    updateLeaderLines() {
        if (this._movingNode) {
            if (this._movingNode.leaderLine) {
                this._movingNode.leaderLine().position();
            }
            if (this._movingNode.isCta || this._movingNode.isInput) {
                this._movingNode.updateLeaderLines();
            }
            for (let i of this.nodes) {
                if (i.nextNode?.uuid == this._movingNode.uuid) {
                    i.leaderLine().position();
                }
                if (i.isCta || i.isInput) {
                    i.updateLeaderLines(this._movingNode.uuid);
                }
            }
        }
    }
    updateLeaderLinesEx() {
        const self = this;
        if (self._updateLeaderLinesExTimer) {
            clearTimeout(self._updateLeaderLinesExTimer);
        }
        self._updateLeaderLinesExTimer = setTimeout(function () {
            for (let i of self.nodes) {
                if (i.leaderLine) {
                    i.leaderLine().position();
                }
                if (i.isCta || i.isInput) {
                    for (let j of i.children) {
                        if (j.leaderLine) {
                            j.leaderLine().position();
                        }
                    }
                }
            }
            self._updateLeaderLinesExTimer = 0;
            self.adjustBoardSize();
        }, 25);
    }
    showLeaderLines() {
        for (let i of this.nodes) {
            i.showLeaderLines();
        }
    }
    newTextNode(options) {
        this._newNode = new TextNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    newImageNode(options) {
        this._newNode = new ImageNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    newVoiceNode(options) {
        this._newNode = new VoiceNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    newVideoNode(options) {
        this._newNode = new VideoNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    newFileNode(options) {
        this._newNode = new FileNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    newInputNode(options) {
        this._newNode = new InputNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    newCtaNode(options) {
        this._newNode = new CtaNode(options);
        if (options?.finishAddingNewNode) {
            this.finishAddingNewNode();
        }
    }
    finishAddingNewNode() {
        if (this._newNode) {
            this.nodes.push(this._newNode);
            this._newNode = null;
        }
    }
    reset() {
        for (let i of this.nodes) {
            this.deleteNode(i);
        }
        this._movingNode = null;
        this._newNode = null;
        const self = this;
        setTimeout(function () {
            self.nodes = [
                new StartNode({ x: 200, y: 50 }),
            ];
        }, 25);
    }
    deleteNode(node) {
        this.nodes = this.nodes.filter(i => i.uuid != node.uuid);
        node.destroyLeaderLines();
        for (let i of this.nodes) {
            if (i.nextNode?.uuid == node.uuid) {
                i.destroyLeaderLines();
            }
        }
    }
    saveToJSON() {
        let nodes = [];
        for (let i of this.nodes) {
            nodes.push(i.pojo);
        }
        return {
            isSurvey: this.isSurvey,
            uuid: this.uuid,
            nodes,
        };
    }
    saveToStr() {
        const result = JSON.stringify(this.saveToJSON());
        return result;
    }
    loadFromJson(json, checkUUID = false, board = null) {
        const self = this;
        function loadNodes() {
            const nodes = [];
            if (json.nodes && Array.isArray(json.nodes)) {
                for (let i of json.nodes) {
                    nodes.push(Node.fromJSON(i));
                }
            }
            self.nodes = nodes;
        }
        if (checkUUID && this.uuid != json.uuid)
            return;
        while (this.nodes.length) {
            this.deleteNode(this.nodes.at(0));
        }
        this.isSurvey = !!json.isSurvey;
        this.uuid = json.uuid;
        this._movingNode = null;
        this._newNode = null;
        this._zoom = 1;
        if (board) {
            setTimeout(function () {
                loadNodes();
                self.fixLeaderLines(json, board);
            }, 150);
        }
        else {
            loadNodes();
        }
    }
    loadFromStr(str, checkUUID = false, board = null) {
        const json = JSON.parse(str);
        this.loadFromJson(json, checkUUID, board);
    }
    fixLeaderLines(json, board) {
        if (json.nodes && Array.isArray(json.nodes)) {
            for (let i of json.nodes) {
                if (i.nextNode) {
                    this.linkNodes(i.uuid, i.nextNode, board);
                }
                if (i.type == LivechatNodeType.cta || i.type == LivechatNodeType.input) {
                    const self = this;
                    setTimeout(function () {
                        const node = self.getNodeByID(i.uuid);
                        if (node) {
                            for (let j of (i.buttons || i.actions)) {
                                const child = node.find(j.uuid);
                                const target = self.getNodeByID(j.nextNode);
                                if (child && target) {
                                    child.link(target, board);
                                }
                            }
                        }
                    }, 10);
                }
            }
        }
    }
    adjustBoardSize() {
        let [x, y] = [0, 0];
        for (let i of this.nodes) {
            const rect = i._el().getBoundingClientRect();
            x = i.x + rect.width > x ? i.x + rect.width : x;
            y = i.y + rect.height > y ? i.y + rect.height : y;
        }
        this._el().style.width = `${x + 100}px`;
        this._el().style.height = `${y + 100}px`;
    }
    zoomIn() {
        this._zoom += 0.25;
        this.updateLeaderLines();
    }
    zoomOut() {
        this._zoom -= 0.25;
        this.updateLeaderLines();
    }
}
