document.addEventListener('alpine:init', () => {
  Alpine.directive('inject', (el: HTMLElement, { expression, value, modifiers }, { evaluate, cleanup } ) => {
    if (value == 'into') {
      if (modifiers && modifiers.length) {
        const target = 
          modifiers[0] == 'this'
            ? Alpine.closestRoot(el)._x_dataStack[0]
            : evaluate(modifiers[0].replace(/[-_]([a-z])/g, function (g: string[]) { return g[1].toUpperCase(); }))

        const field = expression? expression: '_el'
        // Do $watch dùng JSON.stringify nên sẽ bị lỗi "cyclic object value" => chuyển sang function
        // để tránh JSON.stringify xử lý những field này
        target[field] = function() {
          return el
        }
        //
        if (typeof target.afterInject === 'function') {
          target.afterInject(field)
        }

        cleanup(() => {
          delete target[field]
        })
      }
      //
      return
    }
    //
    throw new Error('x-inject went wrong!')
  });
});