document.addEventListener('alpine:init', () => {
    Alpine.directive('inject', (el, { expression, value, modifiers }, { evaluate, cleanup }) => {
        if (value == 'into') {
            if (modifiers && modifiers.length) {
                const target = modifiers[0] == 'this'
                    ? Alpine.closestRoot(el)._x_dataStack[0]
                    : evaluate(modifiers[0].replace(/[-_]([a-z])/g, function (g) { return g[1].toUpperCase(); }));
                const field = expression ? expression : '_el';
                target[field] = function () {
                    return el;
                };
                if (typeof target.afterInject === 'function') {
                    target.afterInject(field);
                }
                cleanup(() => {
                    delete target[field];
                });
            }
            return;
        }
        throw new Error('x-inject went wrong!');
    });
});
