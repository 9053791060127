import apiEndpoints from "../../api";
import { _fetchJson } from "../../_fetch";
import { showInfo } from "ts/autoload/dialogs";
export class CopyPageUrls {
    id;
    _modal;
    loading = true;
    expiresIn = 1;
    adFB = '';
    unprotected1 = '';
    unprotected3 = '';
    unprotected5 = '';
    unprotected7 = '';
    latestRevisionPublished = false;
    showPublishedMessage = false;
    readAloudIsEnabled = false;
    constructor(id = 0) {
        this.id = id;
        this.reset();
    }
    reset() {
    }
    async refresh() {
        try {
            this.loading = true;
            const json = await _fetchJson(apiEndpoints.setParams({ page_id: this.id }).PAGE_URLS_URI);
            if (json) {
                Object.assign(this, json);
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(detail) {
        this.loading = true;
        this.id = detail.id;
        this.showPublishedMessage = !!detail.showPublishedMessage;
        this.readAloudIsEnabled = !!detail.readAloudIsEnabled;
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
    copy(type) {
        let text = '';
        if (type == 'adFB') {
            text = this.adFB;
        }
        if (type == 'unprotected-url') {
            if (this.expiresIn == 1) {
                text = this.unprotected1;
            }
            if (this.expiresIn == 3) {
                text = this.unprotected3;
            }
            if (this.expiresIn == 5) {
                text = this.unprotected5;
            }
            if (this.expiresIn == 7) {
                text = this.unprotected7;
            }
        }
        if (text) {
            navigator.clipboard.writeText(text).then(() => showInfo('Copied'));
        }
    }
}
