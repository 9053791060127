import { OptionalOf } from "#page-builder/optional-of"
import { opacity100ToHex } from "#page-builder/utils"

function merge(_this: any, _that: any) {
  for (let i in _that) {
    if (typeof _this[i] == 'object') {
      merge(_this[i], _that[i])
      //_this[i].fromJSON.apply(_this[i], [_that[i]])
    } else {
      _this[i] = _that[i]
    }
  }
}

// Mod 10 để cùng nhóm
export enum ItemRelative {
  // Những trường hợp này sẽ dùng BLOCK để thao tác
  AfterCurrentElement          = 1,
  BeforeCurrentElement         = 11,
  AtTheBeginningOfCurrentBlock = 21,
  AtTheEndOfCurrentBlock       = 31,
  // Những trường hợp dùng SECTION để thao tác
  AtTheBeginningOfCurrentSection = 2,
  AtTheEndOfCurrentSection       = 12,
  // Những trường hợp dùng PAGE để thao tác
  AtTheBeginningOfThePage = 3,
  AtTheEndOfThePage       = 13
}

export enum ItemRelativeGroup {
  None = 0,
  Block = 1,
  Section = 2,
  Page = 3
}

export enum Protection {
  None = '',
  ShowToBlockedVisitors = 'show-to-blocked-visitors',
  HideFromBlockedVisitors = 'hide-from-blocked-visitors'
}

export enum DeviceTypes {
  Desktop = 'desktop',
  Tablet = 'tablet',
  Mobile = 'mobile'
}

export class ScreenBreakpoints {
  public mobile: number = 576
  public tablet: number = 992
  public desktop: number = 999999

  public get pojo() {
    return {
      mobile: this.mobile,
      tablet: this.tablet,
      desktop: this.desktop
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.mobile = json.mobile
    this.tablet = json.tablet
    this.desktop = json.desktop
  }
}

export interface ScreenResolution {
  width: `${number}px`
  height: `${number}px`
}

export enum VerticalAlignment {
  Auto = 'auto',
  Start = 'start',
  Center = 'center',
  End = 'end',
  Stretch = 'stretch',
}

export enum HorizontalAlignment {
  Start = 'start',
  Center = 'center',
  End = 'end',
}

export type BoxSide = 'top' | 'right' | 'bottom' | 'left' | ''
export type CssUnit = 'px' | 'em' | 'rem' | '%' | 'vw' | 'vh' | 'calc'

export class CssSizeValue {
  public value: number = 0
  public unit : CssUnit = 'px'
  public auto : boolean = false

  constructor(options: OptionalOf<CssSizeValue> = {}) {
    options.auto !== undefined && (this.auto = options.auto)
    options.unit !== undefined && (this.unit = options.unit)
    options.value !== undefined && (this.value = options.value)
  }

  public get pojo() {
    return {
      value: this.value,
      unit : this.unit,
      auto : this.auto
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.value = json.value
    this.unit  = json.unit || 'px'
    this.auto  = json.auto
  }

  public toCss(scale: number = 1) {
    switch (this.unit) {
      case 'vh': return `calc(var(--vh) * ${this.value})`
      case 'vw': return `calc(var(--vw) * ${this.value})`
      case 'calc': return `calc(${this.value})`.replaceAll('vh', '*var(--vh)').replaceAll('vw', '*var(--vw)')
      //@ts-ignore
      default: return parseInt(this.value)? `${this.value * scale}${this.unit}`: '0px'
    }
  }
}

export class Margin {
  public top: CssSizeValue = new CssSizeValue()
  public right: CssSizeValue = new CssSizeValue()
  public bottom: CssSizeValue = new CssSizeValue()
  public left: CssSizeValue = new CssSizeValue()

  public get cssStyle(): string {
    const result: string[] = []
    //
    if (this.top.value) {
      result.push(`margin-top: ${this.top.value}${this.top.unit}`)
    }
    //
    if (this.right.value) {
      result.push(`margin-right: ${this.right.value}${this.right.unit}`)
    }
    //
    if (this.bottom.value) {
      result.push(`margin-bottom: ${this.bottom.value}${this.bottom.unit}`)
    }
    //
    if (this.left.value) {
      result.push(`margin-left: ${this.left.value}${this.left.unit}`)
    }
    //
    return result.join(' ;')
  }

  public get pojo() {
    return {
      top: this.top.pojo,
      right: this.right.pojo,
      bottom: this.bottom.pojo,
      left: this.left.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    json.top && this.top.fromJSON(json.top)
    json.right && this.right.fromJSON(json.right)
    json.bottom && this.bottom.fromJSON(json.bottom)
    json.left && this.left.fromJSON(json.left)
  }
}

export class Padding {
  public top: CssSizeValue = new CssSizeValue()
  public right: CssSizeValue = new CssSizeValue()
  public bottom: CssSizeValue = new CssSizeValue()
  public left: CssSizeValue = new CssSizeValue()

  public get cssStyle(): string {
    const result: string[] = []
    //
    if (this.top.value) {
      result.push(`padding-top: ${this.top.value}${this.top.unit}`)
    }
    //
    if (this.right.value) {
      result.push(`padding-right: ${this.right.value}${this.right.unit}`)
    }
    //
    if (this.bottom.value) {
      result.push(`padding-bottom: ${this.bottom.value}${this.bottom.unit}`)
    }
    //
    if (this.left.value) {
      result.push(`padding-left: ${this.left.value}${this.left.unit}`)
    }
    //
    return result.join(' ;')
  }

  public get pojo() {
    return {
      top: this.top.pojo,
      right: this.right.pojo,
      bottom: this.bottom.pojo,
      left: this.left.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    json.top && this.top.fromJSON(json.top)
    json.right && this.right.fromJSON(json.right)
    json.bottom && this.bottom.fromJSON(json.bottom)
    json.left && this.left.fromJSON(json.left)
  }
}

export enum Overflow {
  Visible = 'visible',
  Hidden = 'hidden',
}

export class Dimension {
  public width: CssSizeValue = new CssSizeValue({ auto: true })
  public height: CssSizeValue = new CssSizeValue({ auto: true })
  public maxWidth: CssSizeValue = new CssSizeValue({ auto: true })
  public minWidth: CssSizeValue = new CssSizeValue({ auto: true })
  public maxHeight: CssSizeValue = new CssSizeValue({ auto: true })
  public minHeight: CssSizeValue = new CssSizeValue({ auto: true })
  public overflow: Overflow = Overflow.Hidden

  public get cssStyle(): string {
    const result: string[] = []
    //
    if (!this.width.auto) {
      result.push(`width: ${this.width.toCss()}`)
    }
    //
    if (!this.height.auto) {
      result.push(`height: ${this.height.toCss()}`)
    }
    //
    if (!this.maxWidth.auto) {
      result.push(`max-width: ${this.maxWidth.toCss()}`)
    }
    //
    if (!this.minWidth.auto) {
      result.push(`min-width: ${this.minWidth.toCss()}`)
    }
    //
    if (!this.maxHeight.auto) {
      result.push(`max-height: ${this.maxHeight.toCss()}`)
    }
    //
    if (!this.minHeight.auto) {
      result.push(`min-height: ${this.minHeight.toCss()}`)
    }
    //
    result.push(`overflow: ${this.overflow}`)
    //
    return result.join(';')
  }

  public get pojo() {
    return {
      width: this.width.pojo,
      height: this.height.pojo,
      maxWidth: this.maxWidth.pojo,
      minWidth: this.minWidth.pojo,
      maxHeight: this.maxHeight.pojo,
      minHeight: this.minHeight.pojo,
      overflow: this.overflow
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.overflow = json.overflow || Overflow.Hidden
    json.width && this.width.fromJSON(json.width)
    json.height && this.height.fromJSON(json.height)
    json.maxWidth && this.maxWidth.fromJSON(json.maxWidth)
    json.minWidth && this.minWidth.fromJSON(json.minWidth)
    json.maxHeight && this.maxHeight.fromJSON(json.maxHeight)
    json.minHeight && this.minHeight.fromJSON(json.minHeight)
  }
}

export enum BgStyle {
  None       = 0,
  SolidColor = 1,
  Image      = 2,
  Video      = 3,
  Pattern    = 4,
  Gradient   = 5
}

export enum BgImagePosition {
  Top = 'top',
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Center = 'center'
}

export class ColorWithOpacity {
  public color: string = '#f0f0f0'
  /**
   * Nhận giá trị từ 0 đến 100
   */
  public opacity: number = 100 // 0..100

  constructor(options: OptionalOf<ColorWithOpacity> = {}) {
    this.fromJSON(options as any)
  }

  public get pojo() {
    return {
      color: this.color,
      opacity: this.opacity
    }
  }

  public fromJSON(json: Record<keyof ColorWithOpacity, any>) {
    this.color = json.color || '#f0f0f0'
    this.opacity = json.opacity || 100
  }

  public toCss(): string {
    return `${this.color}${opacity100ToHex(this.opacity)}`
  }
}

export class Typography {
  public color: ColorWithOpacity = new ColorWithOpacity({ color: '#212529' })
  public family: string = ''
  public size: number = 100
  public weight: number = 400
  public italic: boolean = false
  public underline: boolean = false
  public lineHeight: number = 150
  public override: boolean = false

  constructor(options: OptionalOf<Typography> = {}) {
    this.fromJSON(options as any)
  }

  public get pojo() {
    return {
      color: this.color.pojo,
      family: this.family,
      size: this.size,
      weight: this.weight,
      italic: this.italic,
      underline: this.underline,
      lineHeight: this.lineHeight,
      override: this.override
    }
  }

  public fromJSON(json: Record<keyof Typography, any>) {
    this.family = json.family,
    this.size = json.size || 100
    this.weight = json.weight || 400
    this.italic = json.italic || false
    this.underline = json.underline || false
    this.override = json.override || false
    this.lineHeight = json.lineHeight || 150
    json.color && this.color.fromJSON(json.color)
  }

  public get cssStyle(): string {
    const result: string[] = []
    //
    if (this.override) {
      this.family && result.push(`font-family: "${this.family}"`)
      this.italic && result.push(`font-style: italic`)
      this.underline && result.push(`text-decoration: underline`)
      //
      result.push(`font-size: ${(this.size/ 100).toFixed(2)}em`)
      result.push(`font-weight: ${this.weight}`)
      result.push(`line-height: ${(this.lineHeight/ 100).toFixed(2)}em`)
      result.push(`color: ${this.color.toCss()}`)
    }
    //
    return result.join(' ;')
  }
}

export class TypographyPerDevice {
  // Mặc định là mobile
  public mobile: Typography = new Typography()
  public tablet: Typography = new Typography()
  public desktop: Typography = new Typography()

  constructor() {
    // this.mobile.family = `"IBM Plex Sans", "Montserrat", "Open Sans", "Source Sans 3", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
  }

  public get pojo() {
    return {
      mobile: this.mobile.pojo,
      tablet: this.tablet.pojo,
      desktop: this.desktop.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    json.mobile && this.mobile.fromJSON(json.mobile)
    json.tablet && this.tablet.fromJSON(json.tablet)
    json.desktop && this.desktop.fromJSON(json.desktop)
  }

  public get cssStyle(): string {
    return ''
  }

  public getFontFaces() {
    const result: string[] = []
    if (this.mobile.override) {
      result.push(this.mobile.family)
    }
    if (this.tablet.override) {
      result.push(this.tablet.family)
    }
    if (this.desktop.override) {
      result.push(this.desktop.family)
    }
    return result
  }
}

export class GradientStop {
  constructor(
    public color: string = '#000000',
    public stop : number = 50 // 0..100
  ) {

  }
}

export enum GradientStyle {
  Linear = 'linear',
  Radial = 'radial'
}

export class BgGradient {
  public style: GradientStyle = GradientStyle.Linear
  public angle: number = 0 // 0..359
  public stops: GradientStop[] = []
  //
  public get pojo() {
    const stops: GradientStop[] = []
    //
    for (let i of this.stops) {
      stops.push({
        color: i.color,
        stop : i.stop
      })
    }
    //
    return {
      style: this.style,
      angle: this.angle,
      stops
    }
  }
  //
  public fromJSON(json: Record<keyof BgGradient, any>) {
    this.style = json.style
    this.angle = json.angle
    //
    const stops: GradientStop[] = []
    //
    if (Array.isArray(json.stops) && json.stops.length) {
      for (let i of json.stops) {
        stops.push(new GradientStop(i.color, i.stop))
      }
    }
    //
    this.stops = stops
  }
}

export class Background {
  public parallaxEffect: boolean = false
  // Stretch background to page edges
  public stretch: boolean = true
  // Fit background to container
  public fitContainer: boolean = true
  //
  public position: BgImagePosition | string = BgImagePosition.Center
  //
  public color: ColorWithOpacity = new ColorWithOpacity()
  //
  public gradient: BgGradient = new BgGradient()
  //
  public style: BgStyle = BgStyle.None

  constructor(
    options: OptionalOf<Background> = {}
  ) {
    merge(this, options)
  }

  //
  public get imageFilename() {
    return 'file-name.jpg'
  }
  //
  public get cssStyle(): string {
    if (this.style == BgStyle.SolidColor)
      return `background-color: ${this.color.toCss()}`
    return ''
  }
  //
  public get pojo() {
    return {
      parallaxEffect: this.parallaxEffect,
      stretch: this.stretch,
      fitContainer: this.fitContainer,
      position: this.position,
      color: this.color.pojo,
      style: this.style,
      gradient: this.gradient.pojo
    }
  }
  //
  public fromJSON(json: Record<keyof this, any>) {
    this.parallaxEffect = json.parallaxEffect
    this.stretch = json.stretch
    this.fitContainer = json.fitContainer
    this.position = json.position
    this.style = json.style
    json.color && this.color.fromJSON(json.color)
    json.gradient && this.gradient.fromJSON(json.gradient)
  }
}

export enum BorderStyle {
  None = 'none',
  Dotted = 'dotted',
  Dashed = 'dashed',
  Solid = 'solid',
  Double = 'double',
  Groove = 'groove',
  Ridge = 'ridge',
  Inset = 'inset',
  Outset = 'outset'
}

export class Border {
  public width: CssSizeValue = new CssSizeValue({ value: 1 })
  public style: BorderStyle = BorderStyle.None
  public color: string = '#cccccc'

  constructor(private side: BoxSide = '') {
  }

  public get pojo() {
    return {
      width: this.width.pojo,
      style: this.style,
      color: this.color
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.width.fromJSON(json.width)
    this.style = json.style
    this.color = json.color
  }

  public get cssStyle(): string {
    if ((this.style != BorderStyle.None) && this.width.value) {
      return `border${this.side? '-' : ''}${this.side}: ${this.width.value}${this.width.unit} ${this.style} ${this.color}`
    }
    return `border${this.side? '-' : ''}${this.side}: 0 none`
  }
}

export class Borders {
  public top: Border = new Border('top')
  public right: Border = new Border('right')
  public bottom: Border = new Border('bottom')
  public left: Border = new Border('left')

  public expandTop: boolean = false
  public expandBottom: boolean = false

  public get pojo() {
    return {
      top: this.top.pojo,
      right: this.right.pojo,
      bottom: this.bottom.pojo,
      left: this.left.pojo,
      expandTop: this.expandTop,
      expandBottom: this.expandBottom
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    json.top && this.top.fromJSON(json.top)
    json.right && this.right.fromJSON(json.right)
    json.bottom && this.bottom.fromJSON(json.bottom)
    json.left && this.left.fromJSON(json.left)
    //
    this.expandTop = json.expandTop
    this.expandBottom = json.expandBottom
  }

  public get cssStyle(): string {
    return [this.top.cssStyle, this.right.cssStyle, this.bottom.cssStyle, this.left.cssStyle].filter(i => i) .join(';')
  }
}

export class Visibility {
  public mobile: boolean = true
  public tablet: boolean = true
  public desktop: boolean = true

  public get pojo() {
    return {
      mobile: this.mobile,
      tablet: this.tablet,
      desktop: this.desktop
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.mobile = json.mobile
    this.tablet = json.tablet
    this.desktop = json.desktop
  }
}

export type GridSize = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 // 0: auto

export class GridSizing {
  /**
   *
   */
  public mobile: GridSize = 12
  /**
   *
   */
  public tablet: GridSize = 12
  /**
   *
   */
  public desktop: GridSize = 12
  /**
   *
   */
  public get pojo() {
    return {
      mobile: this.mobile,
      tablet: this.tablet,
      desktop: this.desktop
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.mobile = json.mobile
    this.tablet = json.tablet
    this.desktop = json.desktop
  }
}

export class Metadata {
  public id: string = ''
  public class: string = ''

  public get pojo() {
    return {
      id: this.id,
      class: this.class,
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.id = json.id
    this.class = json.class
  }
}

export enum ShadowStyle {
  None = '-',
  Style1 = 'style-1',
  Style2 = 'style-2',
  Style3 = 'style-3',
  Style4 = 'style-4',
  Style5 = 'style-5',
}

export function shadowStyleToCss(style: ShadowStyle): string {
  switch (style) {
    case ShadowStyle.Style1: return '0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 5px 0 rgba(0,0,0,0.19)'
    case ShadowStyle.Style2: return '0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19)'
    case ShadowStyle.Style3: return '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'
    case ShadowStyle.Style4: return '5px 5px rgba(0,0,0,0.5)'
    case ShadowStyle.Style5: return '-5px -5px rgba(0,0,0,0.5)'
  }
  return ''
}

export enum D3Style {
  None = '-',
  Style1 = 'style-1',
  Style2 = 'style-2',
  Style3 = 'style-3',
  Style4 = 'style-4',
  Style5 = 'style-5',
}

export function d3StyleToCss(style: D3Style): string {
  switch (style) {
    case D3Style.Style1: return 'inset 1px 2px 0 rgba(255, 255, 255, 0.15), inset -1px -2px 0 rgba(255, 255, 255, 0.15)'
    case D3Style.Style2: return 'inset -2px -2px 0 rgba(255, 255, 255, 0.25),inset 2px 2px 0 rgba(255, 255, 255, 0.25)'
    case D3Style.Style3: return 'inset 2px 2px 0 rgba(255, 255, 255, 0.25)'
  }
  return ''
}

export class BoxShadowValue {
  public color: ColorWithOpacity = new ColorWithOpacity({
    color: '#000000',
    opacity: 100
  })
  public positionX: CssSizeValue = new CssSizeValue()
  public positionY: CssSizeValue = new CssSizeValue()
  public blur: CssSizeValue = new CssSizeValue()
  public spread: CssSizeValue = new CssSizeValue()
  public inset: boolean = false

  constructor(options: OptionalOf<BoxShadowValue> = {}) {
    this.fromJSON(options as any)
  }

  public get pojo() {
    return {
      color: this.color,
      positionX: this.positionX.pojo,
      positionY: this.positionY.pojo,
      blur: this.blur.pojo,
      spread: this.spread.pojo,
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.color = json.color
    json.positionX && this.positionX.fromJSON(json.positionX)
    json.positionY && this.positionY.fromJSON(json.positionY)
    json.blur && this.blur.fromJSON(json.blur)
    json.spread && this.spread.fromJSON(json.spread)
  }

  public toCss(): string {
    let result = [
      this.inset? 'inset': '',
      this.positionX.toCss(),
      this.positionY.toCss(),
      this.blur.toCss(),
      this.spread.toCss(),
      this.color
    ].filter(i => i).join(' ')

    return result
  }
}

export class BoxShadow {
  public values: BoxShadowValue[] = []

  public get pojo() {
    return this.values.map(i => i.pojo)
  }

  public fromJSON(json: object) {
    if (Array.isArray(json)) {
      this.values = json.map(i => new BoxShadowValue(i as any))
    }
  }

  public get cssStyle(): string {
    return this.values.length? `box-shadow: ${
      this.values.map(i => i.toCss()).join(',')
    }`: ''
  }
}

// filter: drop-shadow(0.5px 2px 3px #9e9e9f);
export class DropShadow {
  public color: ColorWithOpacity = new ColorWithOpacity({
    color: '#9e9e9f',
    opacity: 100
  })
  public positionX: CssSizeValue = new CssSizeValue({ value: 1 })
  public positionY: CssSizeValue = new CssSizeValue({ value: 2 })
  public blur: CssSizeValue = new CssSizeValue({ value: 3 })
  public borderOnly: boolean = true
  public enabled: boolean = false

  constructor(options: OptionalOf<BoxShadowValue> = {}) {
    this.fromJSON(options as any)
  }

  public get pojo() {
    return {
      color: this.color,
      positionX: this.positionX.pojo,
      positionY: this.positionY.pojo,
      blur: this.blur.pojo,
      borderOnly: this.borderOnly,
      enabled: this.enabled
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.enabled = json.enabled
    this.borderOnly = typeof json.borderOnly == "boolean"? json.borderOnly : true
    json.color && this.color.fromJSON(json.color)
    json.positionX && this.positionX.fromJSON(json.positionX)
    json.positionY && this.positionY.fromJSON(json.positionY)
    json.blur && this.blur.fromJSON(json.blur)
  }

  public toCssFilter(): string {
    if (!this.enabled || this.borderOnly) return ''
    //
    let result = [
      this.positionX.toCss(),
      this.positionY.toCss(),
      this.blur.toCss(),
      this.color.toCss()
    ].filter(i => i).join(' ')

    return `drop-shadow(${result})`
  }

  public get cssStyle(): string {
    if (!this.enabled || !this.borderOnly) return ''

    return `box-shadow: ${this.positionX.toCss()} ${this.positionY.toCss()} ${this.blur.toCss()} ${this.color.toCss()}`
  }
}

export class Sticky {
  public sticky: boolean = false
  public top: CssSizeValue = new CssSizeValue({ auto: false, value: 0 })
  public right: CssSizeValue = new CssSizeValue({ auto: true })
  public bottom: CssSizeValue = new CssSizeValue({ auto: true })
  public left: CssSizeValue = new CssSizeValue({ auto: true })

  public get cssStyle(): string {
    const result: string[] = []
    if (this.sticky) {
      result.push(`position: sticky`)
      result.push(`will-change: transform`)
      result.push(`z-index: 1023`) // 1024 là z-index của live-chat buttons dialog
      //
      if (!this.top.auto) {
        result.push(`top: ${this.top.value}${this.top.unit}`)
      }
      //
      if (!this.right.auto) {
        result.push(`right: ${this.right.value}${this.right.unit}`)
      }
      //
      if (!this.bottom.auto) {
        result.push(`bottom: ${this.bottom.value}${this.bottom.unit}`)
      }
      //
      if (!this.left.auto) {
        result.push(`left: ${this.left.value}${this.left.unit}`)
      }
    }
    //
    return result.join(' ;')
  }

  public get pojo() {
    return {
      sticky: this.sticky,
      top: this.top.pojo,
      right: this.right.pojo,
      bottom: this.bottom.pojo,
      left: this.left.pojo
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.sticky = json.sticky
    json.top && this.top.fromJSON(json.top)
    json.right && this.right.fromJSON(json.right)
    json.bottom && this.bottom.fromJSON(json.bottom)
    json.left && this.left.fromJSON(json.left)
  }
}

export enum VoiceType {
  Male1 = 'male-1',
  Male2 = 'male-2',
  Female1 = 'female-1',
  Female2 = 'female-2'
}

export enum VoiceLanguage {
  EN_US = 'en-us',
  EN_GB = 'en-gb'
}

export class TextToSpeech {
  public enabled: boolean = true
  public language: VoiceLanguage = VoiceLanguage.EN_US
  public voiceType: VoiceType = VoiceType.Male1
  public rate: number = 1
  public pitch: number = 1

  public get pojo() {
    return {
      enabled: this.enabled,
      language: this.language,
      voiceType: this.voiceType,
      rate: this.rate,
      pitch: this.pitch
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.enabled = json.enabled
    this.language = json.language,
    this.voiceType = json.voiceType
    this.rate = json.rate
    this.pitch = json.pitch
  }
}