//@ts-nocheck

declare var tinymce: any

// Prevent Bootstrap dialog from blocking focusin
document.addEventListener('focusin', (e) => {
  if (e.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
    e.stopImmediatePropagation();
  }
});

const tokens = [
  { text: "Today (long). Ex: Monday, July 22 2024", value: "today.long" },
  { text: "Today (short). Ex: July 22", value: "today.short" },
  { text: "Visitor state. Ex: New York", value: "visitor.state" },
  { text: "Countdown 1 minute", value: "countdown.1min" },
  { text: "Countdown 2 minute", value: "countdown.2min" },
  { text: "Countdown 3 minute", value: "countdown.3min" },
  { text: "Countdown 4 minute", value: "countdown.4min" },
  { text: "Countdown 5 minute", value: "countdown.5min" },
  { text: "Countdown 10 minute", value: "countdown.10min" },
  { text: "Countdown 15 minute", value: "countdown.15min" },
];

// Our custom function for setting up the inline token web component.
// Read more about web components here:
// https://developer.mozilla.org/en-US/docs/Web/Web_Components
const setup = (window: Window, document: Document) => {
  // the shadow root gets it's HTML content from the template element.
  // We do not need to inject the template element into the content,
  // we can simply create it in memory and attach it to the shadow root
  const template = document.createElement("template");

  template.innerHTML = `
      <style>
          /* The host selector targets the shadow DOM host element
            * https://developer.mozilla.org/en-US/docs/Web/CSS/:host()
            */
          :host {
            display: inline-block; /* Required to prevent some issues with added spaces when the cursor is just before or after the custom element */
            background-color: #D6F0FF;
            padding: 0 3px;
            color: #44719B;
            border-radius: 2px;
            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
            font-size: 0.9375em;
          }

          :host(:hover) {
            cursor: default;
          }

          /* Here we create a new selection outline using box-shadow
            * that follows the border radius (in contrast to outline that
            * creates a rectangular box)
            */
          :host([contentEditable=false][data-mce-selected]) {
            outline: none !important;
            box-shadow: 0 0 0 3px #b4d7ff;
          }
      </style>

      <!--
          The web components HTML. The content between slot will be
          replaced by the content wrapped in the <inline-token> element.
          The span isn't really needed.
          https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_templates_and_slots
      -->
      <span>
          <slot>token-not-defined</slot>
      </span>
  `;

  // Create the inline token web component.
  // Familiarize yourself with web components here:
  // https://developer.mozilla.org/en-US/docs/Web/Web_Components
  class InlineToken extends window.HTMLElement {
    constructor() {
      super();

      // During the creation of the web component we set contenteditable false
      // on the web component to make it behave like a noneditable but selectable
      // element inside TinyMCE.
      this.setAttribute("contenteditable", 'false')

      // Attach the shadow DOM to the element
      // https://developer.mozilla.org/en-US/docs/Web/API/Element/attachShadow
      const shadow = this.attachShadow({ mode: "open" })

      // Attach the Template to the web components shadow DOM
      this.shadowRoot.appendChild(template.content.cloneNode(true))
    }
  }
  // Register our web component to the tag we want to use it as
  // https://developer.mozilla.org/en-US/docs/Web/API/CustomElementRegistry/define
  window.customElements.define("inline-token", InlineToken)
}

document.addEventListener('alpine:init', () => {
  Alpine.directive('tinymce', (el: HTMLElement, { expression }, { evaluate, evaluateLater }) => {
    //@ts-ignore
    if (el.initialized) return

    let [target, field] = expression.split('.')
    let obj = evaluate(target)

    tinymce.init({
      target: el,
      height: 200,
      menubar: false,
      statusbar: false,
      skin: 'tinymce-5',
      plugins: 'image lists link anchor charmap',
      toolbar: [
        'bold italic forecolor backcolor tokens link image alignleft aligncenter alignright'
      ],
      toolbar_mode: 'scrolling',
      powerpaste_word_import: 'clean',
      powerpaste_html_import: 'clean',
      // Tell TinyMCE that inline token is a valid element. The ~ indicates the tag
      // should be treated as a span.
      // https://www.tiny.cloud/docs/configure/content-filtering/#custom_elements
      custom_elements: "~inline-token",
      setup: function (editor: any) {
        editor.on('init', function () {
          // Get the iframe window object and the iframes document object
          // and call our setup function that creates the web component
          // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getwin
          // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#getdoc
          const win = editor.getWin()
          const doc = editor.getDoc()
          setup(win, doc)
          //
          editor.setContent(obj[field])
        })

        // The preinit event is fired after the editor is loaded but before
        // the content is loaded
        // https://www.tiny.cloud/docs/advanced/events/#editorcoreevents
        editor.on("preinit", () => {
          // During the creation of the web component we set contenteditable false
          // on the web component to make it behave like a noneditable but selectable
          // element inside TinyMCE. But we don't want the contenteditable attribute
          // to be saved with the content. We therefore need to filter out the attribute
          // upon serlialization (which happens on "save", view sourcecode and preview
          // among others).
          // https://www.tiny.cloud/docs/api/tinymce.dom/tinymce.dom.serializer/#addnodefilter
          editor.serializer.addNodeFilter("inline-token", (nodes) => {
            // Iterate through all filtered nodes and remove the contenteditable attribute
            nodes.forEach((node) => {
              if (!!node.attr("contenteditable")) {
                node.attr("contenteditable", null);
              }
            })
          })
        })

        // Register a custom toolbar menu button to insert tokens
        // https://www.tiny.cloud/docs/ui-components/typesoftoolbarbuttons/#menubutton
        editor.ui.registry.addMenuButton("tokens", {
          text: "Token",
          tooltip: "Insert token",
          fetch: (callback) => {
            var items = tokens.map((token) => {
              return {
                type: "menuitem",
                text: token.text,
                onAction: () => {
                  // Insert content at the location of the cursor.
                  // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/#insertcontent
                  editor.insertContent(`<inline-token>${token.value}</inline-token>`)
                }
              }
            })
            callback(items)
          }
        })

        editor.on('NodeChange', function () {
          obj[field] = editor.getContent()
        })
      },
      // The following css will be injected into the editor, extending
      // the default styles.
      // In a real world scenario, with much more custom styles for headings
      // links, tables, images etc, it's recommended to use the content_css
      // option to load a separate CSS file. Makes editing easier too.
      // https://www.tiny.cloud/docs/configure/content-appearance/#content_style
      // https://www.tiny.cloud/docs/configure/content-appearance/#content_css
      content_style: `
        body {
          margin: 0.5em;
        }
        /* We remove the default selected outline because it doesn't follow the
        * border radius we have on the inline token element and move it to the
        * inline token element
        * /
        .mce-content-body inline-token[contentEditable=false][data-mce-selected] {
          outline: none;
          cursor: default;
        }
        `,
    })

    //@ts-ignore
    el.initialized = true
  })
})