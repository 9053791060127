import api from "../../api"
import { Icon, showDialog } from "../../dialogs"
import { hideLoadingIndicator, showLoadingIndicator } from "../../loading-indicator"

declare var _chart: any

export class CampaignOverview {
  public period: number = 4
  //
  public requests: number = 0
  public visitors: number = 0
  public conversions: number = 0
  public blockedTotal: number = 0
  public blocked: Record<number, number> = {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0
  }
  public blocked_1: number = 0
  public blocked_2: number = 0
  public blocked_3: number = 0
  public blocked_4: number = 0
  public blocked_5: number = 0
  public blocked_6: number = 0
  public blocked_7: number = 0
  public blocked_8: number = 0
  public blocked_9: number = 0
  public blocked_10: number = 0
  public blocked_11: number = 0
  public blocked_12: number = 0
  public blocked_13: number = 0
  public blocked_14: number = 0
  public blocked_15: number = 0

  async refresh(showLoading: boolean = true) {
    // Trường hợp không có page => _chart sẽ không tồn tại => không cần phải refresh
    if (typeof _chart == 'undefined') return
    try {
      showLoading && showLoadingIndicator()
      const res = await fetch(api.CAMPAIGN_STATISTICS_URI + `?type=${this.period}`, {})
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      //
      const json = await res.json()
      Object.assign(this, json)
      //
      for (let i = 1; i <= 15; i++) {
        this[`blocked_${i}`] = this.blocked[i]
      }
      //
      this.updateChart()
    } catch (e){
      showDialog(e.message, {
        icon: Icon.Alert
      })   
    } finally {
      showLoading && hideLoadingIndicator()
    }
  }

  updateChart() {
    _chart.updateOptions({
      series: [
        {
          name: "Requests",
          data: (this as any).breakdown.requests,
        },
        {
          name: "Unique Visitors",
          data: (this as any).breakdown.visitors,
        },
        {
          name: "Conversions",
          data: (this as any).breakdown.conversions,
        },
        {
          name: "Blocked",
          data: (this as any).breakdown.blocked,
        },
      ],
      xaxis: {
        categories: (this as any).breakdown.labels
      }
    })
  }
}