import { Datatable } from "../datatable";
export class PaymentMethodInfo {
    id;
    uuid;
    cardBrand;
    cardCountry;
    cardExpMonth;
    cardExpYear;
    cardLast4;
    type;
    default;
    constructor(i) {
        Object.assign(this, i);
    }
}
export class PaymentMethods extends Datatable {
    static create(items) {
        let paymentMethods = [];
        for (let i of items) {
            const p = new PaymentMethodInfo(i);
            paymentMethods.push(p);
        }
        return new PaymentMethods(paymentMethods);
    }
    constructor(items) {
        super(items, {
            archived: false
        });
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
}
