import { InPlaintextState } from './states';
const DefaultStateMachineOptions = {
    tagReplacementText: "",
    encodePlaintextTagDelimiters: true,
};
class StateMachine {
    state;
    transitionFunction;
    constructor(partialOptions = {}) {
        this.state = new InPlaintextState({
            ...DefaultStateMachineOptions,
            ...partialOptions,
        });
        this.transitionFunction = ((next) => {
            this.state = next;
        }).bind(this);
    }
    consume(text) {
        let outputBuffer = "";
        for (const character of text) {
            outputBuffer += this.state.consume(character, this.transitionFunction);
        }
        return outputBuffer;
    }
}
function _striptags(text, options = {}) {
    return new StateMachine(options).consume(text);
}
export function stripTags(str, options = {}) {
    const temp = (options.allowedTags || '').split(' ');
    return _striptags(str, { allowedTags: new Set(temp) });
}
export default stripTags;
