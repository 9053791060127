const domContentLoadedCallbacks = [];
export const onDOMContentLoaded = (callback) => {
    if (document.readyState === 'loading') {
        if (!domContentLoadedCallbacks.length) {
            document.addEventListener('DOMContentLoaded', () => {
                for (const callback of domContentLoadedCallbacks) {
                    callback();
                }
            });
        }
        domContentLoadedCallbacks.push(callback);
    }
    else {
        callback();
    }
};
