import { StateMachineOptions, State, StateTransitionFunction, InPlaintextState } from './states'

export type { StateMachineOptions } from './states'

const DefaultStateMachineOptions: StateMachineOptions = {
  tagReplacementText: "",
  encodePlaintextTagDelimiters: true,
};

class StateMachine {
  private state: State;

  private transitionFunction: StateTransitionFunction;

  constructor(partialOptions: Partial<StateMachineOptions> = {}) {
    this.state = new InPlaintextState({
      ...DefaultStateMachineOptions,
      ...partialOptions,
    });

    this.transitionFunction = ((next: State): void => {
      this.state = next;
    }).bind(this);
  }

  public consume(text: string): string {
    let outputBuffer = "";

    for (const character of text) {
      outputBuffer += this.state.consume(character, this.transitionFunction);
    }

    return outputBuffer;
  }
}

function _striptags(text: string, options: Partial<StateMachineOptions> = {}): string {
  return new StateMachine(options).consume(text);
}

export function stripTags(str: string, options: { allowedTags?: string } = {}) {
  const temp = (options.allowedTags || '').split(' ')
  return _striptags(str, { allowedTags: new Set(temp) })
}

export default stripTags;
