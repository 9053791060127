const synth = window.speechSynthesis

let voices: SpeechSynthesisVoice[]

function loadVoices() {
  voices = synth.getVoices()
  for (let i = 0; i < voices.length; i++) {

  }
}

// in Google Chrome the voices are not ready on page load
if ('onvoiceschanged' in synth) {
  synth.onvoiceschanged = loadVoices
} else {
  loadVoices()
}

export function speakIt(text: string) {
  const utterThis = new SpeechSynthesisUtterance(text)
  utterThis.voice = voices[0]
  synth.speak(utterThis)
}