import api from "../../api"
import { csrfToken } from "../../csrf"
import { ButtonKind, Icon, showDialog, showSuccess } from "../../dialogs"
import { blockForm, hasInputError } from "../../utils"
import { Member } from "./member"
import { TotalProtectionOptions } from "./types/total_protection_options"

declare var campaignForm: HTMLFormElement

export class CampaignEdit {
  private _modal: Function
  //@ts-ignore
  private loading: boolean = false
  /**
   * 
   */
  public working: boolean = false
  //
  public totalProtectionOptions: TotalProtectionOptions = new TotalProtectionOptions()

  constructor(
    public id: number = 0,
    public name: string = '',
    public limitTraffic: boolean = false,
    public quota: number|'' = '',
    public totalProtectionEnabled: boolean = true,
    public members: Member[] = [],
    public currentMember: Member = null,
  ) {
    //
    this.reset()
  }

  public reset() {
    this.currentMember = null
  }

  public editMember(member: Member, $dispatch: Function): void {
    this.currentMember = member
    $dispatch('editMember', {
      member: this.currentMember.clone(),
      edit: true
    })
  }

  public addNewMember($dispatch): void {
    $dispatch('editMember', {
      member: new Member(),
      edit: false
    })
  }

  public getCurrentMember(): Member {
    return this.currentMember
  }

  public save(member: Member) {
    if (this.currentMember) {
      let memberIndex = -1
      for (let i in this.members) {
        if (this.members[i] != this.currentMember) {
          if (this.members[i].email.toLowerCase() == member.email.toLowerCase()) {
            return showDialog('Email is already in the list', { icon: Icon.Alert } )
          }
        } else {
          memberIndex = parseInt(i)
        }
      }
      //
      if (memberIndex >= 0) {
        this.members[memberIndex] = member.clone()
      }
    } else {
      for (let i in this.members) {
        if (this.members[i].email.toLowerCase() == member.email.toLowerCase()) {
          return showDialog('Email is already in the list', { icon: Icon.Alert } )
        }
      }      
      this.members.push(member.clone())
    }
    //
    this.reset()
  }

  public removeMember(member: Member): void {
    this.members = this.members.filter(i => i != member)
  }

  public get count(): number {
    return this.members.length
  }

  public json(): string {
    const { $event, working, currentMember, loading, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit($dispatch: Function) {
    if (!hasInputError(campaignForm)) {
      try {
        this.working = true
        //
        blockForm(campaignForm, true)
        //
        const res = await fetch(api.setParams({ campaign_id: this.id }).CAMPAIGN_EDIT_URI, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken()
          },        
          body: this.json()
        })
        //
        const json = await res.json()
        //
        if (json.success) {
          showSuccess('The Campaign has been changed', {
            buttons: [
              {
                kind: ButtonKind.OK,
                dismiss: true,
                onclick: () => location.reload()
              }
            ]
          })
          //
          this.closeModal()
          //
          $dispatch('editCampaignSuccess', JSON.stringify(json))
        } else {
          showDialog(json.message, {
            icon: Icon.Alert
          })
        }
      } finally {
        this.working = false
        blockForm(campaignForm, false)
      }
    }
  }

  async refresh() {
    try {
      const res = await fetch(api.setParams({ campaign_id: this.id }).CAMPAIGN_JSON_URI, {})
      if (!res.ok) {
        throw new Error(res.statusText)
      }
      //
      const json = await res.json() as CampaignEdit
      this.name = json.name
      this.limitTraffic = json.limitTraffic
      this.quota = json.quota
      this.totalProtectionEnabled = json.totalProtectionEnabled
      this.totalProtectionOptions = json.totalProtectionOptions
      // Không được dùng Object.assign tại đây vì json.members là object thông thường, còn các phần tử của members kiểu Member
      const members: Member[] = []
      for (let i of json.members) {
        members.push(new Member(i))
      }
      this.members = members
    } catch (e){
      this.closeModal()
      //
      showDialog(e.message, {
        icon: Icon.Alert
      })   
    } finally {
      this.loading = false
    }
  }

  showModal(jsonStr: string) {
    this.id = jsonStr? JSON.parse(jsonStr).id: requestParams.campaign_id
    //
    this.loading = false
    //
    this.refresh()
    //
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }

}