import { nextTick } from "#page-builder/nextTick";
import { RIGHT_AFTER, RIGHT_BEFORE, THE_BEGINNING_OF, THE_END_OF, isPbBlock, isPbElement, isPbSection, BaseElement, Block, BlockClass, ButtonGroupElementClass, ImageElementClass, LiveChatElementClass, OnlineIndicatorClass, OnlineIndicator2Class, HtmlFormElementClass, PageBuilder, Section, SectionClass, setBlockClass, setSectionClass, SurveyElementClass, TickerElementClass, WysiwygElementClass, SpinWheelElementClass, } from "#page-builder/page-builder";
import { BgStyle, BorderStyle, DeviceTypes, HorizontalAlignment, ItemRelative, ItemRelativeGroup, Overflow, VerticalAlignment } from "#page-builder/types/common";
import { image } from "#page-builder/utils";
import { _fetchJson2 } from "../../_fetch";
import { ButtonKind, showConfirm, showSuccess } from "../../dialogs";
import apiEndpoints from "../../api";
import toasts from "../../toasts";
import { fireEvent } from "../../../autoload/utils";
export { LiveChat } from "#page-builder/live-chat";
export class _Block extends Block {
    moveUp(elm) {
        if (!super.moveUp(elm)) {
            toasts.addWarning(`Can't move this element because it is at the beginning of the block. Please try to move the block instead!`);
            return false;
        }
        return true;
    }
    moveDown(elm) {
        if (!super.moveDown(elm)) {
            toasts.addWarning(`Can't move this element because it is at the end of the block. Please try to move the block instead!`);
            return false;
        }
        return true;
    }
}
export class _Section extends Section {
    BlockClass = _Block;
    deleteBlock(block) {
        const self = this;
        const deleteBlock = super.deleteBlock;
        showConfirm('Are you sure you want to delete this block?', {
            buttons: [
                {
                    kind: ButtonKind.Cancel,
                    dismiss: true
                },
                {
                    kind: ButtonKind.OK,
                    dismiss: true,
                    onclick: function () {
                        deleteBlock.apply(self, [block]);
                    }
                },
            ]
        });
    }
    moveUp(block) {
        if (!super.moveUp(block)) {
            toasts.addWarning(`Can't move this block because it is at the beginning of the section. Please try to move the sectopm instead!`);
            return false;
        }
        return true;
    }
    moveDown(block) {
        if (!super.moveDown(block)) {
            toasts.addWarning(`Can't move this block because it is at the end of the section. Please try to move the section instead!`);
            return false;
        }
        return true;
    }
}
export class _PageBuilder extends PageBuilder {
    isDesigner = true;
    $$$ = null;
    opening = true;
    openingError = false;
    changed = false;
    latestRevisionIsPublished = true;
    _showDashedLines = false;
    _dataFields = [
        '_typography',
        'title', 'disableRightClickMenu', 'disableViewsourceShortcutKey', 'expandedSection',
        'background', 'dimension', 'borders', 'dropShadow', 'margin', 'padding', 'sections'
    ];
    constructor() {
        super();
        this.dimension.overflow = Overflow.Visible;
    }
    is$$$(item, strict = false) {
        let result = this.$$$?.uuid == item?.uuid;
        if (!strict && !result && typeof item.isBlock != 'undefined' && item.isBlock) {
            for (let i of item.elements) {
                if (i.uuid == this.$$$?.uuid) {
                    return true;
                }
            }
        }
        return result;
    }
    async submit() {
        const res = await _fetchJson2(apiEndpoints.PAGE_BUILDER_URI, {
            method: 'POST',
            body: this.saveToStr()
        });
        if (res) {
            this.changed = false;
            this.latestRevisionIsPublished = false;
            showSuccess('The page has been saved');
        }
    }
    isReadAloudEnabled() {
        for (let s of this.sections) {
            for (let b of s.blocks) {
                for (let e of b.elements) {
                    if (e.isLiveChat || e.isSurvey || e.isHtmlForm) {
                        if (e.textToSpeech?.enabled) {
                            return true;
                        }
                    }
                }
            }
        }
    }
    async publish() {
        const res = await _fetchJson2(apiEndpoints.PAGE_PUBLISH_URI, {
            method: 'POST',
        });
        if (res) {
            this.latestRevisionIsPublished = true;
            return window.dispatchEvent(new CustomEvent('showCopyPublishedPageUrlModal', {
                detail: {
                    id: requestParams.page_id,
                    showPublishedMessage: true,
                    readAloudIsEnabled: this.isReadAloudEnabled()
                }
            }));
        }
    }
    async refresh() {
        this.opening = true;
        this.openingError = false;
        const res = await _fetchJson2(apiEndpoints.setQueryParams({ json: true }).PAGE_BUILDER_URI, {
            method: 'GET'
        });
        this.opening = false;
        if (res) {
            if (res.body) {
                this.fromJSON(res.body);
            }
            this.latestRevisionIsPublished = res.latestRevisionIsPublished;
            this.openingError = false;
        }
        else {
            this.openingError = true;
        }
    }
    setSelection(uuid) {
        const found = this.find(uuid);
        if (found) {
            this.$$$ = found;
        }
    }
    getInsertPlacement(item, relative, inserted) {
        if (relative == ItemRelativeGroup.Page || isPbSection(inserted))
            return this;
        if (isPbElement(item)) {
            for (let section of this.sections) {
                for (let block of section.blocks) {
                    if (block.contains(item.uuid)) {
                        return relative == ItemRelativeGroup.Section || isPbBlock(inserted) ? section : block;
                    }
                }
            }
        }
        if (isPbBlock(item)) {
            if ((relative <= ItemRelativeGroup.Block) && (isPbElement(inserted))) {
                return item;
            }
            for (let i of this.sections) {
                if (i.contains(item.uuid)) {
                    return i;
                }
            }
        }
        if (isPbSection(item) && relative <= ItemRelativeGroup.Section) {
            return item;
        }
        if (relative != ItemRelativeGroup.None)
            return this;
    }
    deleteSection(section) {
        const self = this;
        showConfirm('Are you sure you want to delete this section?', {
            buttons: [
                {
                    kind: ButtonKind.Cancel,
                    dismiss: true
                },
                {
                    kind: ButtonKind.OK,
                    dismiss: true,
                    onclick: function () {
                        const sections = self.sections.filter(i => i.uuid != section.uuid);
                        if (section.uuid == self.expandedSection) {
                            self.expandedSection = '';
                        }
                        self.sections = sections.length ? sections : [new Section()];
                    }
                },
            ]
        });
    }
    moveUp(section) {
        if (!super.moveUp(section)) {
            toasts.addWarning(`Can't move this section because it is at the beginning of the page.`);
            return false;
        }
        return true;
    }
    moveDown(section) {
        if (!super.moveDown(section)) {
            toasts.addWarning(`Can't move this section because it is at the end of the page.`);
            return false;
        }
        return true;
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.dimension.overflow = Overflow.Visible;
        const self = this;
        queueMicrotask(function () {
            nextTick(function () {
                self.changed = false;
            });
        });
        return this;
    }
    newSection(index = 0) {
        const result = super.newSection(index);
        const self = this;
        nextTick(function () {
            self.$$$ = result;
        });
        return result;
    }
    get isEmpty() {
        const result = this.sections.length == 1 && this.sections[0].blocks.length == 1 && !this.sections[0].blocks[0].elements.length;
        return result;
    }
    get showDashedLines() {
        return this._showDashedLines || this.isEmpty;
    }
    set showDashedLines(value) {
        this._showDashedLines = value;
        updatePageBuilderScrollbars();
    }
    get deviceType() {
        return this._deviceType;
    }
    set deviceType(value) {
        this._deviceType = value;
        updatePageBuilderScrollbars();
    }
    get getViewportVariables() {
        switch (this.deviceType) {
            case DeviceTypes.Mobile: return `--vw: ${(375 / 100).toFixed(4)}px; --vh: ${(667 / 100).toFixed(4)}px`;
            case DeviceTypes.Tablet: return `--vw: ${(834 / 100).toFixed(4)}px; --vh: ${(1194 / 100).toFixed(4)}px`;
            case DeviceTypes.Desktop: return `--vw: ${(1280 / 100).toFixed(4)}px; --vh: ${(720 / 100).toFixed(4)}px`;
        }
    }
    get screenResolution() {
        switch (this.deviceType) {
            case DeviceTypes.Mobile: return { width: '375px', height: '667px' };
            case DeviceTypes.Tablet: return { width: '834px', height: '1194px' };
            case DeviceTypes.Desktop: return { width: '1280px', height: '720px' };
        }
    }
    get typography() {
        switch (this.deviceType) {
            case DeviceTypes.Mobile: return this._typography.mobile;
            case DeviceTypes.Tablet: return this._typography.tablet.override ? this._typography.tablet : this._typography.mobile;
            case DeviceTypes.Desktop: return this._typography.desktop.override ? this._typography.desktop : this._typography.mobile;
        }
    }
    addElement({ template, placement }) {
        let item = null;
        switch (template) {
            case 'wysiwyg':
                item = new WysiwygElementClass({
                    placeOnSingleRow: true
                });
                break;
            case 'image':
                item = new ImageElementClass({
                    placeOnSingleRow: true,
                    image: image('template-thumbnails/image.png')
                });
                break;
            case 'ticker':
                item = new TickerElementClass({
                    placeOnSingleRow: true
                });
                break;
            case 'online-indicator':
                item = new OnlineIndicatorClass({
                    placeOnSingleRow: true
                });
                break;
            case 'online-indicator2':
                item = new OnlineIndicator2Class({
                    placeOnSingleRow: true
                });
                break;
            case 'html-form':
                item = new HtmlFormElementClass({
                    placeOnSingleRow: true
                });
                break;
            case 'spin-wheel':
                item = new SpinWheelElementClass({
                    placeOnSingleRow: true
                });
                break;
            case 'button-group':
                item = new ButtonGroupElementClass({
                    placeOnSingleRow: true,
                    buttons: [
                        { text: 'Button 1' },
                        { text: 'Button 2' },
                    ],
                    btnWidth: {
                        auto: false,
                        value: 200,
                        unit: 'px'
                    }
                });
                break;
            case 'survey':
                item = new SurveyElementClass({
                    placeOnSingleRow: true
                });
                break;
            case 'live-chat':
                item = new LiveChatElementClass({
                    placeOnSingleRow: true
                });
                break;
            case 'block-empty':
                item = new BlockClass();
                break;
            case 'text-with-image-1':
                item = new BlockClass();
                item.newWysiwyg({ placeOnSingleRow: true });
                item.newImage({ placeOnSingleRow: true, image: image('template-thumbnails/image.png') });
                break;
            case 'text-with-image-2':
                item = new BlockClass();
                item.newImage({ placeOnSingleRow: true, image: image('template-thumbnails/image.png') });
                item.newWysiwyg({ placeOnSingleRow: true });
                break;
            case 'button-group-with-text':
                item = new BlockClass();
                item.newWysiwyg({
                    placeOnSingleRow: true
                });
                item.newButtonGroup({
                    placeOnSingleRow: true,
                    buttons: [
                        { text: 'Button 1' },
                        { text: 'Button 2' },
                    ],
                    btnWidth: {
                        auto: false,
                        value: 200,
                        unit: 'px'
                    }
                });
                break;
            case 'basic-section':
                item = new SectionClass();
                break;
            case 'simple-footer':
                placement = ItemRelative.AtTheEndOfThePage;
                item = new SectionClass({
                    borders: {
                        top: {
                            color: '#CCCCCC',
                            style: BorderStyle.Solid,
                            width: {
                                value: 1,
                                unit: 'px'
                            }
                        }
                    },
                    padding: {
                        top: {
                            value: 0.5,
                            unit: 'em'
                        },
                        bottom: {
                            value: 0.5,
                            unit: 'em'
                        }
                    },
                    margin: {
                        top: {
                            value: 0.5,
                            unit: 'em'
                        }
                    }
                });
                item.firstBlock.newWysiwyg({
                    placeOnSingleRow: true,
                    html: `<p style="text-align:center"><a href="/privacy">Privacy</a> | <a href="/terms">Terms</a> | <a href="/contact">Contact</a></p>`
                });
                break;
            case 'simple-header':
                placement = ItemRelative.AtTheBeginningOfThePage;
                item = new SectionClass({
                    background: {
                        style: BgStyle.SolidColor,
                        color: {
                            color: '#1163CE',
                            opacity: 100
                        }
                    },
                    padding: {
                        top: {
                            value: 0.25,
                            unit: 'em'
                        },
                        bottom: {
                            value: 0.25,
                            unit: 'em'
                        },
                        right: {
                            value: 0.25,
                            unit: 'em'
                        },
                        left: {
                            value: 0.25,
                            unit: 'em'
                        }
                    },
                    margin: {
                        bottom: {
                            value: 0.25,
                            unit: 'em'
                        }
                    },
                    dropShadow: {
                        enabled: true,
                        borderOnly: true,
                        positionX: {
                            value: 1,
                        },
                        positionY: {
                            value: 2
                        },
                        color: {
                            color: '#9e9e9f',
                            opacity: 100
                        }
                    }
                });
                item.firstBlock.newImage({
                    placeOnSingleRow: false,
                    rounded: 5,
                    scale: 0.5,
                    horizontalAlignment: HorizontalAlignment.Start,
                    image: image('template-thumbnails/logo-sample.jpg'),
                });
                item.firstBlock.newWysiwyg({
                    placeOnSingleRow: false,
                    horizontalAlignment: HorizontalAlignment.Start,
                    verticalAlignment: VerticalAlignment.Center,
                    html: 'Lorem ipsum.',
                    margin: {
                        left: {
                            auto: false,
                            value: 0.25,
                            unit: 'em'
                        }
                    }
                });
                break;
            case 'header-with-ticker':
                placement = ItemRelative.AtTheBeginningOfThePage;
                item = new SectionClass({
                    background: {
                        style: BgStyle.SolidColor,
                        color: {
                            color: '#000000',
                            opacity: 100
                        }
                    },
                    margin: {
                        bottom: {
                            value: 0.25,
                            unit: 'em'
                        }
                    },
                    padding: {
                        left: {
                            auto: false,
                            unit: 'em',
                            value: 0,
                        },
                        right: {
                            auto: false,
                            unit: 'em',
                            value: 0,
                        }
                    },
                    dropShadow: {
                        enabled: true,
                        borderOnly: true,
                        positionX: {
                            value: 1,
                        },
                        positionY: {
                            value: 2
                        },
                        color: {
                            color: '#9e9e9f',
                            opacity: 100
                        }
                    }
                });
                item.firstBlock.dimension.fromJSON({
                    width: {
                        auto: false,
                        value: 100,
                        unit: '%'
                    }
                });
                item.firstBlock.newTicker({
                    text: 'This is a ticker',
                    horizontalAlignment: HorizontalAlignment.Start,
                    verticalAlignment: VerticalAlignment.Center,
                    dimension: {
                        width: {
                            auto: false,
                            value: 100,
                            unit: '%'
                        }
                    }
                });
                break;
        }
        if (item) {
            let position = null;
            let relativeGroup = placement % 10;
            const selectedUUID = item.uuid;
            if (item instanceof BaseElement) {
                if (relativeGroup == ItemRelativeGroup.Section || relativeGroup == ItemRelativeGroup.Page || !this.$$$ || isPbSection(this.$$$)) {
                    let block = new BlockClass();
                    block.elements.push(item);
                    item = block;
                }
            }
            if (item instanceof Block) {
                if (relativeGroup == ItemRelativeGroup.Page || !this.$$$) {
                    let section = new SectionClass();
                    section.blocks[0] = item;
                    item = section;
                }
            }
            const parent = this.$$$ ? this.getInsertPlacement(this.$$$, relativeGroup, item) : this;
            switch (parseInt(placement.toString())) {
                case ItemRelative.AfterCurrentElement:
                    position = RIGHT_AFTER;
                    break;
                case ItemRelative.BeforeCurrentElement:
                    position = RIGHT_BEFORE;
                    break;
                case ItemRelative.AtTheBeginningOfCurrentBlock:
                    position = THE_BEGINNING_OF;
                    break;
                case ItemRelative.AtTheEndOfCurrentBlock:
                    position = THE_END_OF;
                    break;
                case ItemRelative.AtTheBeginningOfCurrentSection:
                    position = THE_BEGINNING_OF;
                    break;
                case ItemRelative.AtTheEndOfCurrentSection:
                    position = THE_END_OF;
                    break;
                case ItemRelative.AtTheBeginningOfThePage:
                    position = THE_BEGINNING_OF;
                    break;
                case ItemRelative.AtTheEndOfThePage:
                    position = THE_END_OF;
                    break;
                default:
                    position = THE_END_OF;
            }
            parent.insert(item, {
                reference: this.$$$,
                pageBuilder: this,
                position
            });
            this.setSelection(selectedUUID);
            hidePageBuilderContextMenu();
            scrollPageBuilderSelectionIntoView();
        }
    }
}
export function updatePageBuilderScrollbars() {
    nextTick(function () {
        window.dispatchEvent(new CustomEvent('pbUpdateScrollbar'));
    });
}
export function hidePageBuilderContextMenu() {
    nextTick(function () {
        window.dispatchEvent(new CustomEvent('hidePbContextMenu'));
    });
}
export function scrollPageBuilderSelectionIntoView() {
    setTimeout(function () {
        window.dispatchEvent(new CustomEvent('pbScrollSelectionIntoView'));
    }, 100);
}
export function showButtonStyleModal(detail) {
    fireEvent('showButtonStyleModal', detail);
}
setBlockClass(_Block);
setSectionClass(_Section);
