import { Cropt, CroptOptions } from "../../cropt"
import { onDOMContentLoaded } from "../../util"

declare var croptAvatarElm: HTMLElement
declare var uploadAvatarModalElm: HTMLElement
declare var userAvatarElm: HTMLImageElement
declare var avatarInput: HTMLInputElement
declare var uploadAvatarInput: HTMLInputElement
declare var uploadAvatarModalOKBtn: HTMLElement
declare var avatarRemoveBtn: HTMLElement

export const croptAvatar = function () {

  let options: CroptOptions = {
    viewport: {
      width: 220,
      height: 220,
      type: "square",
    },
    mouseWheelZoom: "off",
    zoomerInputClass: "form-range",
  }

  onDOMContentLoaded(() => {
    let image: string;
    //
    const cropt = new Cropt(croptAvatarElm, options);
    //
    uploadAvatarModalElm.addEventListener('shown.bs.modal', function() {
      cropt.bind(image).then(() => {
                
      });
    });
    //@ts-ignore
    let croptModal = new bootstrap.Modal(uploadAvatarModalElm, {});
    //
    uploadAvatarInput.value = "";
    uploadAvatarInput.onchange = function () {
      if (uploadAvatarInput.files && uploadAvatarInput.files[0]) {
        const file = uploadAvatarInput.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
          if (typeof e.target?.result === "string") {
            image = e.target.result;
            //
            croptModal.show();
          }
        }

        reader.readAsDataURL(file);
      }
    };
    //
    uploadAvatarModalOKBtn.addEventListener('click', function() {
      cropt.toCanvas(320).then((canvas) => {
        let dataURL = canvas.toDataURL();
        userAvatarElm.src = dataURL;
        //
        let regExMatches = dataURL.match('data:(image/.*);base64,(.*)');
        avatarInput.value= regExMatches[2];
        //
        croptModal.hide();
      });
    });
    //
    avatarRemoveBtn.addEventListener('click', function() {
      avatarInput.value = '';
      userAvatarElm.src = '/images/default-avatar.jpg';
    })
  })

}