export enum DomainStatus {
  Pending = 0,
  /**
   * Đã tạo CName record ở phía domain của app
   */
  AppDnsRecordCreated = 1,
  /**
   * Đã kết nối và cài SSL
   */
  Connected = 2,
  /**
   * DNS Record của người dùng ko point đến server của mình
   */
  DnsDoesNotPointToServer = 10,
  /**
   * Không thể cài SSL
   */
  SslError = 20,
  /**
   * Người dùng chọn disconnect
   */
  Disconnected = 100,  
}

export default DomainStatus