import api from "../../api"
import { _fetchJson } from "../../_fetch"
import { csrfToken } from "../../csrf"
import { showError, showSuccess } from "../../dialogs"
import { hasInputError } from "../../utils"


declare var quotaModal: HTMLElement

export class QuotaModel {
  private _modal: Function
  //
  public working: boolean
  //
  public loading: boolean = false
  //
  public userId: number = 0
  //
  public quota: number = 0
  //
  public email: string

  public json(): string {
    const { $event, loading, working, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit($dispatch: Function) {
    if (!hasInputError(quotaModal)) {
      try {
        this.working = true
        //
        const json = await _fetchJson(api.setParams({ user_id: this.userId }).QUOTA_CREATE_OR_UPDATE, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken()
          },
          body: this.json()
        })
        //
        if (json) {
          showSuccess('The user has been saved.')
          //
          this.closeModal()
        }
      } finally {
        this.working = false
      }
    }
  }

  async refresh() {
    this.quota = 0

    if (!this.userId) return

    try {
      const json = await _fetchJson(api.setParams({ user_id: this.userId }).QUOTA_CREATE_OR_UPDATE) as QuotaModel
      if (json) {
        this.quota = json.quota || 0
      } else {
        this.closeModal()
      }
    } finally {
      this.loading = false
    }
  }

  showModal(jsonStr: string) {
    const json = jsonStr? JSON.parse(jsonStr): null
    if (json) {
      this.userId = json.id
      this.email = json.email
      //
      this.loading = false
      //
      this.refresh()
      //
      bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
    } else {
      showError('Error kNI9neQpvW')
    }
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }
}