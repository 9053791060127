import { Background, BgStyle, BoxShadow, D3Style, d3StyleToCss, ShadowStyle, shadowStyleToCss, } from "#page-builder/types/common";
export class DialogStyle {
    color = '#072852';
    fontSize = 1.25;
    borderColor = '#1163ce';
    background = new Background({
        style: BgStyle.SolidColor,
        color: {
            color: '#cfe0f5',
            opacity: 100
        }
    });
    boxShadow = new BoxShadow();
    paddingX = 0.75;
    paddingY = 0.75;
    marginX = 0.75;
    marginY = 0.75;
    borderRadius = 0.25;
    borderSize = 0;
    shadow = ShadowStyle.Style1;
    d3style = D3Style.None;
    position = 'bottom';
    backdrop = true;
    constructor(options = {}) {
        const self = this;
        queueMicrotask(function () {
            self.fromJSON(options);
        });
    }
    get pojo() {
        return {
            color: this.color,
            fontSize: this.fontSize,
            borderColor: this.borderColor,
            background: this.background.pojo,
            boxShadow: this.boxShadow.pojo,
            paddingX: this.paddingX,
            paddingY: this.paddingY,
            marginX: this.marginX,
            marginY: this.marginY,
            borderRadius: this.borderRadius,
            borderSize: this.borderSize,
            shadow: this.shadow,
            d3style: this.d3style,
            position: this.position,
            backdrop: this.backdrop
        };
    }
    fromJSON(json) {
        this.color = json.color || '#072852';
        this.fontSize = json.fontSize || 1.25;
        this.shadow = json.shadow || ShadowStyle.Style1;
        this.d3style = json.d3style || D3Style.None;
        this.paddingX = json.paddingX || 0.75;
        this.paddingY = json.paddingY || 0.75;
        this.marginX = json.marginX || 0.75;
        this.marginY = json.marginY || 0.75;
        this.borderRadius = json.borderRadius || 0.25;
        this.borderSize = json.borderSize || 0;
        this.position = json.position || 'bottom';
        this.borderColor = json.borderColor || '#1163ce';
        this.backdrop = typeof json.backdrop != 'undefined' ? json.backdrop : true;
        json.background && this.background.fromJSON(json.background);
        json.boxShadow && this.boxShadow.fromJSON(json.boxShadow);
        return this;
    }
    get cssStyle() {
        let result = [];
        if (this.paddingX) {
            result.push(`padding-left: ${this.paddingX.toFixed(2)}em`);
            result.push(`padding-right: ${this.paddingX.toFixed(2)}em`);
        }
        if (this.paddingY) {
            result.push(`padding-top: ${this.paddingY.toFixed(2)}em`);
            result.push(`padding-bottom: ${this.paddingY.toFixed(2)}em`);
        }
        if (this.marginX) {
            result.push(`width: calc(100% - 2 * ${this.marginX.toFixed(2)}em)`);
        }
        else {
            result.push(`width: 100%`);
        }
        if (this.marginY) {
            result.push(`margin-top: ${this.marginY.toFixed(2)}em`);
            result.push(`margin-bottom: ${this.marginY.toFixed(2)}em`);
        }
        result.push(`color: ${this.color}`);
        result.push(`background-color: ${this.background.color.color}`);
        result.push(`border-radius: ${this.borderRadius.toFixed(2)}em`);
        result.push(this.borderColor ? `border: ${this.borderSize}px solid ${this.borderColor}` : '');
        const shadow = [];
        if (this.shadow != ShadowStyle.None) {
            shadow.push(shadowStyleToCss(this.shadow));
        }
        if (this.d3style != D3Style.None) {
            shadow.push(d3StyleToCss(this.d3style));
        }
        if (shadow.length) {
            result.push(`box-shadow: ${shadow.join(',')}`);
        }
        if (this.position == 'bottom') {
            result.push(`position: fixed; left: 50%; bottom: -100%; transform: translateX(-50%);`);
        }
        else {
            result.push(`position: fixed; left: 50%; top: -100%; transform: translate(-50%, -50%);`);
        }
        return result.filter(i => i).join(';');
    }
}
