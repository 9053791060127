export function withImageCropper(imageField, __superclass) {
    return (superclass) => {
        class InternalImageCropper extends superclass {
            modal;
            cropper;
            cropperImage;
            cropperModal;
            fileInput;
            afterInject(field) {
                if (field == 'modal') {
                    const self = this;
                    this.modal().addEventListener('hidden.bs.modal', function () {
                        queueMicrotask(function () {
                            if (typeof self.cropperModal == 'function') {
                                self.cropperModal().dispose();
                                delete self.cropperModal;
                            }
                            self.cropper().destroy();
                            delete self.cropper;
                        });
                    });
                    this.modal().addEventListener('shown.bs.modal', function () {
                        const cropper = new Cropper(self.cropperImage(), {
                            viewMode: 1,
                            responsive: false,
                            autoCropArea: 1
                        });
                        self.cropper = function () {
                            return cropper;
                        };
                    });
                }
                else if (field == 'cropperImage') {
                }
            }
            uploadOK() {
                this[imageField] = this.cropper().getCroppedCanvas({
                    maxWidth: 512,
                    maxHeight: 512,
                }).toDataURL();
                this.cropperModal().hide();
            }
            showCropModal() {
                const self = this;
                self.cropperModal = function () {
                    return bootstrap.Modal.getOrCreateInstance(self.modal(), {});
                };
                self.cropperModal().show();
            }
            handleFile($event) {
                let file;
                if ($event.type == 'drop') {
                    let dt = $event.dataTransfer;
                    let files = dt.files;
                    if (files && files[0]) {
                        file = files[0];
                    }
                }
                else if ($event.type == 'change') {
                    if (this.fileInput().files && this.fileInput().files[0]) {
                        file = this.fileInput().files[0];
                    }
                }
                const reader = new FileReader();
                const self = this;
                reader.onload = async function (e) {
                    if (typeof e.target?.result === "string") {
                        self.cropperImage().src = e.target.result;
                        self.showCropModal();
                    }
                };
                reader.readAsDataURL(file);
            }
            removeImage() {
                this[imageField] = '';
            }
        }
        return InternalImageCropper;
    };
}
