import { nextTick } from "../utils"
import { Datatable } from "./datatable"

class WatchdogEntry {
  public id: number
  public channel: string
  public message: string
  public context: string
  public createdAt: string

  constructor(i: any) {
    Object.assign(this, i)
  }

}

export class WatchdogIndex extends Datatable {
  constructor(items: any[], selectAllCheckbox: HTMLInputElement) {
    super(items, {
      channelOrMessage: '',
    })
    //
    this._selectAllCheckbox = selectAllCheckbox
  }

  static create(items: any[], selectAllCheckbox: HTMLInputElement): WatchdogIndex {
    let entries = []
    for (let i of items) {
      const p = new WatchdogEntry(i)
      //
      entries.push(p)
    }
    return new WatchdogIndex(entries, selectAllCheckbox)
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      channelOrMessage: '',
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: WatchdogEntry): boolean {
    return (
        (item.channel as string).toLowerCase().includes(this.search.channelOrMessage.toLowerCase())
        || (item.message as string).toLowerCase().includes(this.search.channelOrMessage.toLowerCase())
      )
  }

  public closeToolsDropdown(el: HTMLElement) {
    nextTick(function() {
      const btn = el.querySelector('[data-bs-toggle="dropdown"]')
      const dropdown = bootstrap.Dropdown.getInstance(btn)
      if (dropdown) {
        dropdown.hide()
      }
    })
  }
}