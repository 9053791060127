import api from "../../api"
import { _fetchJson } from "../../_fetch"
import { csrfToken } from "../../csrf"
import { showSuccess } from "../../dialogs"
import { makeid } from "#page-builder/utils"
import { Datatable } from "../datatable"
import type { DomainInfo } from "./domains"
import type { PageInfo } from "./pages"
import Placement from "./types/placement"
import ResourceType from "./types/resource_type"

declare var codemirror: {
  createEditor(el: HTMLElement): void
  getContent(): string
  setContent(content: string): void
}

/**
 * Dùng để connect/edit resource ở modal
 */
export class ResourceModel {
  private _modal: Function
  //
  public working: boolean
  //
  public loading: boolean = false
  //
  public name: string = 'Javascript-' + makeid(4)
  //
  public placement: Placement = Placement.BeforeBodyEndTag
  //
  public body: string = ''
  //
  public enabled: boolean = true
  //
  public archived: boolean = false
  //
  public formatedUpdatedAt: string = ''
  //
  public activeDomains: any[] = []
  //
  public usedOnDomains: number[] = []
  //
  public activePages: any[] = []
  //
  public includedOnPages: number[] = []

  constructor(
    public id: number = 0
  ) {

  }

  public json(): string {
    const { $event, loading, working, body, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify({ ...result, body: codemirror.getContent() })
  }

  public async submit($dispatch: Function) {
    try {
      this.working = true
      //
      const json = await _fetchJson(api.RESOURCE_CREATE_OR_UPDATE, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrf-token': csrfToken()
        },
        body: this.json()
      })
      //
      if (json) {
        showSuccess('The Javascript has been saved.')
        //
        this.closeModal()
        //@ts-ignore
        $dispatch(!parseInt(this.id)? 'addResourceSuccess': 'editResourceSuccess', JSON.stringify(json))
      }
    } finally {
      this.working = false
    }
  }

  async archive($dispatch: Function) {
    try {
      this.working = true
      //
      const json = await _fetchJson(api.setParams({ resource_id: this.id }).RESOURCE_ARCHIVE, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrf-token': csrfToken()
        },
        body: JSON.stringify({ archived: !this.archived })
      })
      //
      if (json) {
        showSuccess(`The Javascript has been ${ this.archived? 'unarchived': 'archived'}.`)
        //
        this.archived = json.archived
        //
        this.closeModal()
        //@ts-ignore
        $dispatch('editResourceSuccess', JSON.stringify(json))
      }
    } finally {
      this.working = false
    }
  }

  async refresh() {
    try {
      if (!this.id) {
        const json = await _fetchJson(api.CAMPAIGN_ACTIVE_DOMAINS_AND_PAGES_URI)
        //
        this.name = 'Javascript-' + makeid(4)
        this.body = ''
        this.placement = Placement.BeforeBodyEndTag
        this.enabled = true
        this.archived = false
        this.usedOnDomains = []
        this.activeDomains = json.activeDomains
        this.includedOnPages = []
        this.activePages = json.activePages
        return
      } else {
        const json = await _fetchJson(api.setParams({ resource_id: this.id }).RESOURCE_JSON, {}) as ResourceModel
        if (json) {
          this.id = json.id
          this.name = json.name
          // this.body = json.body
          this.enabled = json.enabled
          this.archived = json.archived
          this.formatedUpdatedAt = json.formatedUpdatedAt
          this.usedOnDomains = json.usedOnDomains || []
          this.activeDomains = json.activeDomains
          this.includedOnPages = json.includedOnPages || []
          this.activePages = json.activePages
          //
          codemirror.setContent(json.body)
        } else {
          this.closeModal()
        }
      }
    } finally {
      this.loading = false
    }
  }

  public isUsedOnDomain(domain: DomainInfo) {
    const result = this.usedOnDomains.includes(domain.id)
    return result
  }

  public setUsedOnDomain(domain: DomainInfo, used: boolean) {
    if (!!used) {
      if (!this.usedOnDomains.includes(domain.id))
        this.usedOnDomains.push(domain.id)
    } else {
      this.usedOnDomains = this.usedOnDomains.filter(i => i != domain.id)
    }
  }

  public isIncludedOnPage(page: PageInfo) {
    const result = this.includedOnPages.includes(page.id)
    return result
  }

  public setIncludedOnPage(page: PageInfo, used: boolean) {
    if (!!used) {
      if (!this.includedOnPages.includes(page.id))
        this.includedOnPages.push(page.id)
    } else {
      this.includedOnPages = this.includedOnPages.filter(i => i != page.id)
    }
  }

  showModal(jsonStr: string) {
    this.id = jsonStr? JSON.parse(jsonStr).id: 0
    //
    this.loading = false
    //
    this.refresh()
    //
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }
}

/**
 * Dùng để hiển thị thông tin Resource lên listview
 */
class ResourceInfo {
  public id: number
  public name: string
  public updated_at: string
  public enabled: boolean
  public archived: boolean
  public formatedUpdatedAt: string
  public type: ResourceType = ResourceType.Javascript

  constructor(i: any) {
    Object.assign(this, i)
  }
  //
  public get resourceTypeClass(): string {
    switch (this.type) {
      case ResourceType.Javascript: return 'danger'
      default: return 'primary'
    }
  }
  //
  public get resourceTypeIcon(): string {
    switch (this.type) {
      case ResourceType.Javascript: return 'bi-filetype-js'
      default: return 'bi-filetype-css'
    }
  }

  public get resourceTypeText(): string {
    switch (this.type) {
      case ResourceType.Javascript: return 'Javascript'
      default: return 'Stylesheet'
    }
  }
  //
  public toJSONStr() {
    return JSON.stringify(this)
  }
}

export class CampaignResources extends Datatable {
  //
  static create(items: any[]) {
    let resource = []
    for (let i of items) {
      const p = new ResourceInfo(i)
      resource.push(p)
    }
    return new CampaignResources(resource)
  }

  constructor(items: any[]) {
    super(items, {
      name: '',
      archived: false
    })
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      name: '',
      archived: false
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    const result = ((item.name as string).toLowerCase().includes(this.search.name.toLowerCase()) || !this.search.name) && (!!item.archived == this.search.archived)
    return result
  }

  public addResource(info: string) {
    let json = JSON.parse(info)
    this.add(new ResourceInfo(json))
  }

  public editResource(info: string) {
    let json = JSON.parse(info)
    for (let i of this._items) {
      if (i.id == json.id) {
        i.name = json.name
        i.enabled = json.enabled
        i.archived = !!json.archived
        break
      }
    }
    this.doSearch()
  }

}