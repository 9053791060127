import api from "../../api"
import { csrfToken } from "../../csrf"
import { ButtonKind, Icon, showDialog } from "../../dialogs"
import { hideLoadingIndicator, showLoadingIndicator } from "../../loading-indicator"
import { nextTick } from "#page-builder/nextTick"
import { Datatable } from "../datatable"

declare var ApexCharts: any
declare var chartsContainer: HTMLElement // Element ẩn - dùng để tạm append chart vào để tính kích thước

export class CampaignInfo {
  public id: number
  public name: string
  public owner: string
  public requests: number
  public visitors: number
  public conversions: number
  public blocked: any
  public updatedAt: string
  public archived: boolean
  public breakdown: any
  public statistics6w: any
  public statistics6m: any

  constructor(i: any) {
    Object.assign(this, i)
  }

  public toJSONStr() {
    return JSON.stringify(this)
  }

  public reCalcStats(period: number) {
    const data = period == 1? this.statistics6w: this.statistics6m
    this.requests = data.requests
    this.visitors = data.visitors
    this.conversions = data.conversions
    this.blocked = data.blocked
    this.breakdown = data.breakdown
  }

  public get blockedTotal(): number {
    let result = 0
    for (let i in this.blocked) {
      result += parseInt(this.blocked[i])
    }
    return result
  }
}

export class CampaignIndex extends Datatable {
  protected _period: number = 1
  protected _charts: Record<number, HTMLElement> = {}

  constructor(items: any[], selectAllCheckbox: HTMLInputElement) {
    super(items, {
      name: '',
      owner: '',
      archived: false,
    })
    //
    this._selectAllCheckbox = selectAllCheckbox
  }

  static create(items: any[], selectAllCheckbox: HTMLInputElement): CampaignIndex {
    let campaigns = []
    for (let i of items) {
      const p = new CampaignInfo(i)
      //
      p.reCalcStats(1)
      //
      campaigns.push(p)
    }
    return new CampaignIndex(campaigns, selectAllCheckbox)
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      name: '',
      owner: '',
      archived: false,
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    return (item.name as string).toLowerCase().includes(this.search.name.toLowerCase())
      && (!this.search.owner || item.owner.id == this.search.owner)
      && (this.search.archived == !!item.archived)
  }

  protected createChart(item: any): HTMLElement {
    const id = parseInt(item.id)
    const result = document.createElement('div')
    //
    result.id = `chart-${id}-${this.period}`
    //
    this._charts[`${id}-${this.period}`] = result
    //
    chartsContainer.appendChild(result)
    //
    createCampaignStatasticsChart(result, item)
    //
    return result
  }

  public getChart(item: any, $el: HTMLElement) {
    const id = parseInt(item.id)
    if (!Number.isNaN(id)) {
      const result = this._charts[`${id}-${this.period}`]? this._charts[`${id}-${this.period}`]: this.createChart(item)
      if (result.parentElement) {
        result.parentElement.removeChild(result)
      }
      //
      nextTick(function() { $el.appendChild(result) })
    }
    //
    return ''
  }

  public getOwnerOptions() {
    let result = [`<option value="" selected>-- Select --</option>`]

    for (let i of this._items) {
      result.push(`<option value="${i.owner.id}">${i.owner.fullName} [${i.owner.email}]</option>`)
    }

    return result.join('\n')
  }

  public showStatisticsPopover(item: any, el: HTMLElement) {
    nextTick(function(){
      const popover = bootstrap.Popover.getOrCreateInstance(el, {
        html: true,

        content: `
          <span class="badge rounded-pill text-bg-primary">Requests</span>
          <span class="badge rounded-pill text-bg-secondary">Unique Visitors</span>
          <span class="badge rounded-pill text-bg-success">Conversions</span>
          <span class="badge rounded-pill text-bg-danger">Blocked</span>
        `,
        customClass: 'campaignsIndexPopover',
        placement: 'left',
        sanitize: false,

      })

      popover.toggle()
    })
  }

  public hideStatisticsPopover(_item: any, el: HTMLElement) {
    nextTick(function(){
      const popover = bootstrap.Popover.getInstance(el)
      if (popover) {
        popover.hide()
      }
    })
  }

  public closeToolsDropdown(el: HTMLElement) {
    nextTick(function() {
      const btn = el.querySelector('[data-bs-toggle="dropdown"]')
      const dropdown = bootstrap.Dropdown.getInstance(btn)
      if (dropdown) {
        dropdown.hide()
      }
    })
  }

  public get period(): number {
    return this._period
  }

  public set period(value: number) {
    this._period = value
    for (let i of this._items) {
      (i as CampaignInfo).reCalcStats(value)
    }
  }

  public editCampaignSuccess(jsonStr: string) {
    const json = JSON.parse(jsonStr)
    // Có thể phần name thay đổi => cập nhật lại listview
    for (let i of this._items) {
      if (i.id == json.id) {
        i.name = json.name
        i.formatedUpdatedAt = json.formatedUpdatedAt
      }
    }
  }

  public archive(campaign: CampaignInfo) {
    const self = this
    showDialog(!campaign.archived? 'Are you sure you would like to archive this campaign?': 'Are you sure you would like to unarchive this campaign?', {
      icon: Icon.Question,
      buttons: [
        {
          kind: ButtonKind.Cancel,
          dismiss: true,
        },
        {
          kind: ButtonKind.OK,
          dismiss: true,
          onclick: async function() {
            showLoadingIndicator()
            try {
              const res = await fetch(api.setParams({ campaign_id: campaign.id }).CAMPAIGN_ARCHIVE_URI, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'x-csrf-token': csrfToken()
                },
                body: JSON.stringify({ value: !campaign.archived })
              })
              if (!res.ok) {
                throw new Error(res.statusText)
              }
              //
              campaign.archived = !campaign.archived
              // Cập nhật lại danh sách
              self.doSearch(true)
            } catch (e){
              showDialog(e.message, {
                icon: Icon.Alert
              })
            } finally {
              hideLoadingIndicator()
            }
          },
        }
      ]
    })
  }
}

export function createCampaignStatasticsChart(elm: HTMLElement, item: any) {
  const chart_options = {
    series: [
      {
        name: "Requests",
        data: item.breakdown.requests,
      },
      {
        name: "Visitors",
        data: item.breakdown.visitors,
      },
      {
        name: "Conversions",
        data: item.breakdown.conversions,
      },
      {
        name: "Blocked",
        data: item.breakdown.blocked,
      },
    ],
    chart: {
      height: 80,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: false,
    },
    colors: ['#1163ce', "#6c757d", "#198754", '#dc3545',],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 1,
    },
    xaxis: {
      categories: item.breakdown.labels,
      show: false,
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
        height: 0,
      }
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    tooltip: {
    },
  };

  const chart = new ApexCharts(elm, chart_options);
  chart.render();
}