export class TotalProtectionOptions {
  constructor(
    public blockTabletVisitors: boolean = false,
    public blockDesktopVisitors: boolean = true,
    public blockVPNProxies: boolean = true,
    public blockSearchEngines: boolean = true,
    public allowFacebookBotTraffic: boolean = true,
    public allowGoogleBotTraffic: boolean = true,
    public smartBlocking: boolean = true,
    public allowedReferrers: string[] = [],
  ) {

  }
}