/*
 * @poppinss/utils
 *
 * (c) Poppinss
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

type PickKeysByValue<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T]
export type OmitProperties<T, P> = Omit<T, PickKeysByValue<T, P>>

type ScanFsBaseOptions = {
  ignoreMissingRoot?: boolean
  filter?: (filePath: string, index: number) => boolean
  sort?: (current: string, next: string) => number
}
export type ImportAllFilesOptions = ScanFsBaseOptions & {
  /**
   * A custom method to transform collection keys
   */
  transformKeys?: (keys: string[]) => string[]
}

export type ReadAllFilesOptions = ScanFsBaseOptions & {
  pathType?: 'relative' | 'unixRelative' | 'absolute' | 'unixAbsolute' | 'url'
}

export type JSONReplacer = (this: any, key: string, value: any) => any
export type JSONReviver = (this: any, key: string, value: any) => any

export type Constructor = new (...args: any[]) => any

/**
 * Normalizes constructor to work with mixins. There is an open bug for mixins
 * to allow constructors other than `...args: any[]`
 *
 * https://github.com/microsoft/TypeScript/issues/37142
 */
export type NormalizeConstructor<T extends Constructor> = {
  new (...args: any[]): InstanceType<T>
} & Omit<T, 'constructor'>

declare const opaqueProp: unique symbol
export type Opaque<T, K> = T & { [opaqueProp]: K }

interface UnaryFunction<T, R> {
  (source: T): R
}

/**
 * Compose a class by applying mixins to it.
 * The code is inspired by https://justinfagnani.com/2015/12/21/real-mixins-with-javascript-classes/, its
 * just that I have added the support for static types too.
 */
export function compose<T extends Constructor, A>(superclass: T, mixin: UnaryFunction<T, A>): A
export function compose<T extends Constructor, A, B>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>
): B
export function compose<T extends Constructor, A, B, C>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>
): C
export function compose<T extends Constructor, A, B, C, D>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>,
  mixinD: UnaryFunction<C, D>
): D
export function compose<T extends Constructor, A, B, C, D, E>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>,
  mixinD: UnaryFunction<C, D>,
  mixinE: UnaryFunction<D, E>
): E
export function compose<T extends Constructor, A, B, C, D, E, F>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>,
  mixinD: UnaryFunction<C, D>,
  mixinF: UnaryFunction<E, F>
): F
export function compose<T extends Constructor, A, B, C, D, E, F, G>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>,
  mixinD: UnaryFunction<C, D>,
  mixinF: UnaryFunction<E, F>,
  mixinG: UnaryFunction<F, G>
): G
export function compose<T extends Constructor, A, B, C, D, E, F, G, H>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>,
  mixinD: UnaryFunction<C, D>,
  mixinF: UnaryFunction<E, F>,
  mixinG: UnaryFunction<F, G>,
  mixinH: UnaryFunction<G, H>
): H
export function compose<T extends Constructor, A, B, C, D, E, F, G, H, I>(
  superclass: T,
  mixin: UnaryFunction<T, A>,
  mixinB: UnaryFunction<A, B>,
  mixinC: UnaryFunction<B, C>,
  mixinD: UnaryFunction<C, D>,
  mixinF: UnaryFunction<E, F>,
  mixinG: UnaryFunction<F, G>,
  mixinH: UnaryFunction<G, H>,
  mixinI: UnaryFunction<H, I>
): I
export function compose<T extends Constructor, Mixins extends UnaryFunction<T, T>>(
  superclass: T,
  ...mixins: Mixins[]
) {
  return mixins.reduce((c, mixin) => mixin(c), superclass)
}
