let loadingIndicator: HTMLElement = null

function createLoadingIndicator() {
  if (!loadingIndicator) {
    loadingIndicator = document.createElement('div')
    loadingIndicator.setAttribute('id', 'loadingIndicator')
    loadingIndicator.innerHTML = /* html */ `
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border text-light" style="width: 4rem; height: 4rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    `
    document.body.appendChild(loadingIndicator)
  }
}

export function showLoadingIndicator() {
  //
  if (!loadingIndicator) {
    createLoadingIndicator()
  }
  //
  loadingIndicator.setAttribute('style', 'display: block')
}

export function hideLoadingIndicator() {
  if (loadingIndicator) {
    loadingIndicator.removeAttribute('style')
  }
}