import { csrfToken } from "./csrf"
import { showError, showValidationError } from "./dialogs"

export async function _fetchJson(input: RequestInfo | URL, init?: RequestInit): Promise<any|void> {
  try {
    const headers: HeadersInit = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-csrf-token': csrfToken(),
    }

    const res = await fetch(input, { ...init, headers })

    if (!res.ok) {
      return res.status == 422? showValidationError((await res.json()).details): showError(`${res.statusText}: ${await res.text()}`)
    }
    //
    const json = await res.json()
    if (!json.success) {
      return showError(json.message)
    }
    //
    return json
  } catch (e) {
    showError(e.message)
  }
}