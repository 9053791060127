const messageName = '________nextTick________';
const nextTickCallbacks = [];
if (typeof window != 'undefined') {
    window.addEventListener('message', function (event) {
        if (event != null && event.source === window && event.data === messageName) {
            event.stopPropagation();
            if (nextTickCallbacks.length > 0) {
                const fn = nextTickCallbacks.shift();
                fn();
            }
        }
    }, true);
}
export async function nextTick(callback) {
    nextTickCallbacks.push(callback);
    if (typeof window != 'undefined') {
        window.postMessage(messageName, '*');
    }
}
