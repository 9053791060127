export enum MessageBubbleStyle {
  Style1 = 'style-1', // Facebook
  Style2 = 'style-2', // Whatsapp
  Style3 = 'style-3', // Telegram
  Style4 = 'style-4', //
  Style5 = 'style-5',
}

export function getMessageBubbleStyle(this: any, scope: string): string {
  const agentStartGroup = [`.agent-msg:first-of-type`, `.visitor-msg + .agent-msg`]
  const agentStartGroupSelector = agentStartGroup.map(i => `${scope} ${i}`).join(',')
  const agentStartGroupAfterSelector = agentStartGroup.map(i => `${scope} ${i}::after`).join(',')

  const agentEndGroup   = [`.agent-msg:has(+ .visitor-msg)`, `.agent-msg:has(+ .end-msg)`]
  const agentEndGroupSelector = agentEndGroup.map(i => `${scope} ${i}`).join(',')
  const agentEndGroupAfterSelector = agentEndGroup.map(i => `${scope} ${i}::after`).join(',')

  const visitorStartGroup = [`.visitor-msg:first-of-type`, `.agent-msg + .visitor-msg`]
  const visitorStartGroupSelector = visitorStartGroup.map(i => `${scope} ${i}`).join(',')
  const visitorStartGroupAfterSelector = visitorStartGroup.map(i => `${scope} ${i}::after`).join(',')

  const visitorEndGroup   = [`.visitor-msg:has(+ .agent-msg)`, `.visitor-msg:has(+ .end-msg)`]
  const visitorEndGroupSelector = visitorEndGroup.map(i => `${scope} ${i}`).join(',')
  const visitorEndGroupAfterSelector = visitorEndGroup.map(i => `${scope} ${i}::after`).join(',')

  if (this.messageBubbleStyle == MessageBubbleStyle.Style1) {
    return /* html */`
      <style type="text/css">
        ${scope} .msg {
          padding: 0.5em 1em;
          border-radius: 1em;
          margin-bottom: 0.1em;
          max-width: 80%;
          clear: both;
        }

        ${scope} .buttons-dialog {
          ${this.agentStyle.toCss()}
        }

        ${scope} .agent-msg {
          float: left;
          ${this.agentStyle.toCss()}
        }

        ${scope} .visitor-msg + .agent-msg:has(+ .agent-msg),
        ${scope} .agent-msg:first-of-type:has(+ .agent-msg) {
          border-bottom-left-radius: 0.25em;
        }

        ${scope} .agent-msg + .agent-msg {
          border-top-left-radius: 0.25em;
          border-bottom-left-radius: 0.25em;
        }

        ${scope} .agent-msg:has(+ .visitor-msg),
        ${scope} .agent-msg:has(+ .end-msg) {
          border-bottom-left-radius: 1em;
        }

        ${scope} .visitor-msg {
          float: right;
          ${this.visitorStyle.toCss()}
        }

        ${scope} .agent-msg + .visitor-msg:has(+ .visitor-msg),
        ${scope} .visitor-msg:first-of-type:has(+ .visitor-msg) {
          border-bottom-right-radius: 0.25em;
        }

        ${scope} .visitor-msg + .visitor-msg {
          border-top-right-radius: 0.25em;
          border-bottom-right-radius: 0.25em;
        }

        ${scope} .visitor-msg:has(+ .agent-msg),
        ${scope} .visitor-msg:has(+ .end-msg) {
          border-bottom-right-radius: 1em;
        }

        ${scope} .visitor-msg + .agent-msg,
        ${scope} .agent-msg + .visitor-msg {
          margin-top: 0.5em;
        }

      </style>
    `
  }

  if (this.messageBubbleStyle == MessageBubbleStyle.Style2) {
    return /* html */`
      <style type="text/css">
        ${scope} .msg {
          padding: 0.5em;
          border-radius: 0.25em;
          margin-bottom: 0.1em;
          max-width: 80%;
          clear: both;
          position: relative;
        }

        ${scope} .buttons-dialog {
          ${this.agentStyle.toCss()}
        }

        ${scope} .agent-msg {
          float: left;
          ${this.agentStyle.toCss()}
        }

        ${scope} .visitor-msg {
          float: right;
          ${this.visitorStyle.toCss()}
        }

        ${scope} .visitor-msg + .agent-msg,
        ${scope} .agent-msg + .visitor-msg {
          margin-top: 0.5em;
        }

        ${agentEndGroupSelector} {
          border-bottom-left-radius: 0;
        }

        ${agentEndGroupAfterSelector} {
          border-width: 0 0.5em 0.5em;
          border-color: transparent transparent ${this.agentStyle.messageBg} transparent;
          bottom: 0;
          left: calc(-0.5em + 1px);

          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
        }

        ${visitorEndGroupSelector} {
          border-bottom-right-radius: 0;
        }

        ${visitorEndGroupAfterSelector} {
          border-width: 0 0.5em 0.5em;
          border-color: transparent transparent ${this.visitorStyle.messageBg} transparent;
          bottom: 0;
          right: calc(-0.5em + 1px);

          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
        }

        ${scope} .messages {
          padding: 0 0.5em;
        }

      </style>
    `
  }

  if (this.messageBubbleStyle == MessageBubbleStyle.Style3) {
    return /* html */`
      <style type="text/css">
        ${scope} .msg {
          padding: 0.5em;
          border-radius: 0.25em;
          margin-bottom: 0.1em;
          max-width: 80%;
          clear: both;
          position: relative;
        }

        ${scope} .buttons-dialog {
          ${this.agentStyle.toCss()}
        }

        ${scope} .agent-msg {
          float: left;
          ${this.agentStyle.toCss()}
        }

        ${scope} .visitor-msg {
          float: right;
          ${this.visitorStyle.toCss()}
        }

        ${scope} .visitor-msg + .agent-msg,
        ${scope} .agent-msg + .visitor-msg {
          margin-top: 0.5em;
        }

        ${agentStartGroupSelector} {
          border-top-left-radius: 0;
        }

        ${agentStartGroupAfterSelector} {
          border-width: 0 0.5em 0.5em 0;
          border-color: transparent ${this.agentStyle.messageBg} transparent transparent;
          top: 0;
          left: calc(-0.5em + 1px);

          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
        }

        ${visitorStartGroupSelector} {
          border-top-right-radius: 0;
        }

        ${visitorStartGroupAfterSelector} {
          border-width: 0 0 0.5em 0.5em;
          border-color: transparent transparent transparent ${this.visitorStyle.messageBg};
          top: 0;
          right: calc(-0.5em + 1px);

          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
        }

        ${scope} .messages {
          padding: 0 0.5em;
        }

      </style>
    `
  }
  return ``
}