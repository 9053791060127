import apiEndpoints from "../../api";
import { _fetchJson, _fetchJson2 } from "../../_fetch";
import { csrfToken } from "../../csrf";
import { showSuccess } from "../../dialogs";
import { blockForm, fireEvent } from "../../utils";
export class EditPageUrl {
    id;
    _modal;
    loading = true;
    working = false;
    campaignId = 0;
    activeDomains = [];
    domainId = '';
    path = '';
    constructor(id = 0) {
        this.id = id;
        this.reset();
    }
    getDomainById(id) {
        for (let i of this.activeDomains) {
            if (i.id == id) {
                return i.domain;
            }
        }
        return '<unknown domain>';
    }
    get urlPreview() {
        if (this.domainId)
            return 'https://' + this.getDomainById(this.domainId) + '/' + trim(this.path, '/');
        else
            return '<Please select a domain>';
    }
    reset() {
    }
    async refresh() {
        try {
            this.loading = true;
            const json = await _fetchJson(apiEndpoints.setParams({ page_id: this.id }).PAGE_JSON_URI);
            if (json) {
                Object.assign(this, json);
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(jsonStr) {
        this.loading = true;
        Object.assign(this, JSON.parse(jsonStr));
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
    json() {
        const { $event, working, campaign, activeDomains, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    showCopyPublishedPageUrlModal() {
        window.dispatchEvent(new CustomEvent('showCopyPublishedPageUrlModal', { detail: { id: this.id, message: 'The page URL has been changed' } }));
    }
    async submit(copy = false) {
        try {
            this.working = true;
            blockForm(pageForm, true);
            const res = await _fetchJson2(apiEndpoints.PAGE_EDIT_URL_URI, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: this.json()
            });
            if (res) {
                fireEvent('updatePageUrl', { url: this.urlPreview });
                copy ? this.showCopyPublishedPageUrlModal() : showSuccess('The page URL has been changed');
            }
        }
        finally {
            this.working = false;
            blockForm(pageForm, false);
            this.closeModal();
        }
    }
}
