export var SurveyStyle;
(function (SurveyStyle) {
    SurveyStyle["Style1"] = "style-1";
    SurveyStyle["Style2"] = "style-2";
    SurveyStyle["Style3"] = "style-3";
})(SurveyStyle || (SurveyStyle = {}));
export function getSurveyStyle(scope, btnWidth, typography) {
    return `
    <style type="text/css">
      ${scope} .choices {
        display: inline-grid;
        margin: 1em auto 0;
        justify-content: center;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.25em;
        width: ${btnWidth.toCss()};
      }

      ${scope} .question {
        ${typography.cssStyle}
      }
    </style>
  `;
}
