import { Constructor, NormalizeConstructor } from "#page-builder/compose"

declare var bootstrap: any
declare var Cropper: any

export function withImageCropper(imageField: string, __superclass: Constructor) {

  return <Model extends NormalizeConstructor<typeof __superclass>>(superclass: Model) => {

    class InternalImageCropper extends superclass {
      // Do $watch dùng JSON.stringify nên sẽ bị lỗi "cyclic object value" => chuyển sang function
      // để tránh JSON.stringify xử lý những field này
      //@ts-ignore
      private modal: Function
      //@ts-ignore
      private cropper: Function
      //@ts-ignore
      private cropperImage: Function
      //@ts-ignore
      private cropperModal: Function // bootstrap.Modal object
      //@ts-ignore
      private fileInput: Function

      public afterInject(field: string) {
        if (field == 'modal') {
          const self = this
          //
          this.modal().addEventListener('hidden.bs.modal', function() {
            queueMicrotask(function() {
              if (typeof self.cropperModal == 'function') {
                self.cropperModal().dispose()
                //@ts-ignore
                delete self.cropperModal
              }
              //
              self.cropper().destroy()
              //@ts-ignore
              delete self.cropper
            })
          })
          //
          this.modal().addEventListener('shown.bs.modal', function() {
            const cropper = new Cropper(self.cropperImage(), {
              viewMode: 1,
              responsive: false,
              autoCropArea: 1
            })

            self.cropper = function() {
              return cropper
            }
          })

        } else if (field == 'cropperImage') {

        }
      }

      public uploadOK() {
        this[imageField] = this.cropper().getCroppedCanvas({
          maxWidth: 512,
          maxHeight: 512,
        }).toDataURL()
        //
        this.cropperModal().hide()
      }

      public showCropModal() {
        const self = this
        self.cropperModal = function() {
          return bootstrap.Modal.getOrCreateInstance(self.modal(), {})
        }
        self.cropperModal().show()
      }
      //@ts-ignore
      public handleFile($event: Event|DragEvent) {
        let file: File
        if ($event.type == 'drop') {
          //@ts-ignore
          let dt = $event.dataTransfer
          let files = dt.files
          if (files && files[0]) {
            file = files[0]
          }
        } else if ($event.type == 'change') {
          if (this.fileInput().files && this.fileInput().files[0]) {
            file =this.fileInput().files[0]
          }
        }
        //@ts-ignore
        const reader = new FileReader()
        //
        const self = this
        //
        reader.onload = async function(e: any) {
          if (typeof e.target?.result === "string") {
            self.cropperImage().src = e.target.result
            self.showCropModal()
          }
        }

        reader.readAsDataURL(file!)
      }

      public removeImage() {
        this[imageField] = ''
      }
    }

    return InternalImageCropper
  }
}