import validator from 'validator';
class Rule {
    options;
    constructor(options = null) {
        this.options = options;
    }
    execute(input) {
    }
    get errorMessage() {
        return '<validator error>';
    }
}
class MinLengthRule extends Rule {
    execute(input) {
        return input && input.length >= this.options;
    }
    get errorMessage() {
        return `Requires at least ${this.options} characters`;
    }
}
class MaxLengthRule extends Rule {
    execute(input) {
        return input && input.length <= this.options;
    }
    get errorMessage() {
        return `The length must be ${this.options} characters or fewer`;
    }
}
class IntRule extends Rule {
    execute(input) {
        return !input || validator.isInt(input.toString());
    }
    get errorMessage() {
        return `The value isn't valid`;
    }
}
class EmailRule extends Rule {
    execute(input) {
        return validator.isEmail(input);
    }
    get errorMessage() {
        return `The email isn't valid`;
    }
}
class MobilePhoneRule extends Rule {
    execute(input) {
        return validator.isMobilePhone(input || '');
    }
    get errorMessage() {
        return `The mobile number isn't valid`;
    }
}
class WebsiteUrlRule extends Rule {
    execute(input) {
        return validator.isURL(input || '');
    }
    get errorMessage() {
        return `The website isn't valid`;
    }
}
class DomainRule extends Rule {
    execute(input) {
        const match = input.match(/^(?:\*\.)?[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/);
        return !!match;
    }
    get errorMessage() {
        return `The domain isn't valid`;
    }
}
class UriPathRule extends Rule {
    execute(input) {
        if (input) {
            this.input = input;
            return !input.startsWith('/') && validator.isURL(`https://www.dummy.com/${input}`, { allow_fragments: false, allow_query_components: false });
        }
        return false;
    }
    get errorMessage() {
        return typeof this.input != 'undefined' && this.input.startsWith('/') ? `The path isn't valid - It should not start with "/"` : `The path isn't valid`;
    }
}
class ZipCodeRule extends Rule {
    execute(input) {
        return validator.isPostalCode(input || '', 'any');
    }
    get errorMessage() {
        return `The zip code isn't valid`;
    }
}
function parseRules(modifiers) {
    const result = [];
    let index = 0;
    while (index < modifiers.length) {
        switch (modifiers[index]) {
            case 'min-length':
                result.push(new MinLengthRule(modifiers[++index]));
                break;
            case 'max-length':
                result.push(new MaxLengthRule(modifiers[++index]));
                break;
            case 'int':
                result.push(new IntRule());
                break;
            case 'email':
                result.push(new EmailRule());
                break;
            case 'mobile-phone':
                result.push(new MobilePhoneRule());
                break;
            case 'website':
                result.push(new WebsiteUrlRule());
                break;
            case 'domain':
                result.push(new DomainRule());
                break;
            case 'uri-path':
                result.push(new UriPathRule());
                break;
            case 'zipcode':
                result.push(new ZipCodeRule());
                break;
        }
        index++;
    }
    return result;
}
document.addEventListener('alpine:init', () => {
    Alpine.directive('validator', (el, { expression, value, modifiers }, { evaluate, effect }) => {
        if (!el._x_model && !expression.startsWith('refs')) {
            throw new Error('x-validator requires x-model or x-ref');
        }
        el.classList.add('has-validator');
        let _x_model = el._x_model;
        let input = el;
        if (!_x_model) {
            const $refs = evaluate('$refs');
            const refID = expression.split(':')[1];
            input = $refs[refID];
            _x_model = input._x_model;
        }
        const rules = parseRules(modifiers);
        effect(() => {
            let _value = _x_model.get();
            let valid = true;
            let error = '';
            for (let rule of rules) {
                if (!(valid = rule.execute(_value))) {
                    error = rule.errorMessage;
                    break;
                }
            }
            if (valid || !_value) {
                el.removeAttribute('__validator_error');
                input.removeAttribute('__invalid');
            }
            else {
                el.setAttribute('__validator_error', error);
                input.setAttribute('__invalid', '');
            }
        });
    });
});
