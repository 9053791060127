import { OptionalOf } from "#page-builder/optional-of"
import { isEmail } from "../../utils"

declare function setDefaultPermissions(member: Member): void

export class Member {
  private isEditingMember: boolean = false
  public email: string = ''
  public insight: boolean = false
  public content: boolean = false
  public people: boolean = false

  constructor(
    options: OptionalOf<Member> = {}
  ) {
    this.reset()
    this.merge(options)
  }

  public get isValidEmail(): boolean {
    return isEmail(this.email)
  }

  public isValid(field: string = '*'): boolean {
    switch (field) {
      case '*': return this.isValidEmail
      case 'email': return this.isValidEmail
    }

    return false
  }

  public reset() {
    this.email = ''
    setDefaultPermissions(this)
  }

  public merge(data: any) {
    if (this instanceof Member) {
      for(const field of Object.getOwnPropertyNames(this)) {
        if (typeof data[field] !== 'undefined')
          this[field] = data[field]
      }
    } else {
      // Trường hợp là proxy
      for(const field of Object.getOwnPropertyNames(new Member())) {
        if (typeof data[field] !== 'undefined')
          //@ts-ignore
          this[field] = data[field]
      }
    }
  }

  public clone(): Member {
    let result = new Member()
    //
    for (let k in (result as any)) {
      result[k] = this[k]
    }
    //
    return result
  }

  public showModal($data: any, $refs: Record<string, HTMLElement>) {
    this.merge($data.member)
    this.isEditingMember = $data.edit
    bootstrap.Modal.getOrCreateInstance($refs.modal, {}).show()
  }

  public save($dispatch: Function, $refs: Record<string, HTMLElement>) {
    $dispatch('saveMember', this.clone())
    bootstrap.Modal.getOrCreateInstance($refs.modal, {}).hide()
  }
}