import { csrfToken } from "./csrf";
import { showError, showValidationError } from "./dialogs";
import { hideLoadingIndicator, showLoadingIndicator } from "./loading-indicator";
export async function _fetchJson(input, init) {
    try {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken(),
        };
        const res = await fetch(input, { ...init, headers });
        if (!res.ok) {
            try {
                const json = await res.json();
                return showError(json.message);
            }
            catch {
                return res.status == 422 ? showValidationError((await res.json()).details) : showError(`${res.statusText}: ${await res.text()}`);
            }
        }
        const json = await res.json();
        if (!json.success) {
            return showError(json.message);
        }
        return json;
    }
    catch (e) {
        showError(e.message);
    }
}
export async function _fetchJson2(input, init) {
    showLoadingIndicator();
    try {
        return await _fetchJson(input, init);
    }
    finally {
        hideLoadingIndicator();
    }
}
