import { _fetchJson } from "../../_fetch"
import api from "../../api"
import { csrfToken } from "../../csrf"
import { ButtonKind, showConfirm, showError, showSuccess } from "../../dialogs"
import { hideLoadingIndicator, showLoadingIndicator } from "../../loading-indicator"
import { nextTick } from "#page-builder/nextTick"
import { hasInputError } from "../../utils"
import { Datatable } from "../datatable"

declare var userModal: HTMLElement

export class UserModel {
  private _modal: Function
  //
  public working: boolean
  //
  public loading: boolean = false
  //
  public email: string = ''
  //
  public fullName: string = ''
  //
  public company: string = ''
  //
  public phoneNumber: string = ''
  //
  public website: string = ''
  //
  public bio: string = ''
  //
  public country: string = ''
  //
  public state: string = ''
  //
  public stateOther: string = ''
  //
  public zipCode: string = ''
  //
  public address: string = ''
  //
  public timezone: string = ''
  //
  public archived: boolean = false
  //
  public status: number

  constructor(
    public id: number = 0
  ) {

  }

  public json(): string {
    const { $event, loading, working, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit($dispatch: Function) {
    if (!hasInputError(userModal)) {
      try {
        this.working = true
        //
        const json = await _fetchJson(api.USER_CREATE_OR_UPDATE, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken()
          },
          body: this.json()
        })
        //
        if (json) {
          showSuccess('The user has been saved.' + (!!json.password? `<br>The password is: ${json.password}`: ''))
          //
          this.closeModal()
          //@ts-ignore
          $dispatch(!parseInt(this.id)? 'createUserSuccess': 'editUserSuccess', JSON.stringify(json))
        }
      } finally {
        this.working = false
      }
    }
  }

  async archive($dispatch: Function) {
    try {
      this.working = true
      //
      const json = await _fetchJson(api.setParams({ domain_id: this.id }).USER_BLOCK, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-csrf-token': csrfToken()
        },
        body: JSON.stringify({ archived: !this.archived })
      })
      //
      if (json) {
        showSuccess(`The user has been ${ this.archived? 'unarchived': 'archived'}.`)
        //
        this.archived = json.archived
        //
        this.closeModal()
        //@ts-ignore
        $dispatch('editUserSuccess', JSON.stringify(json))
      }
    } finally {
      this.working = false
    }
  }

  async refresh() {
    this.email = ''
    this.fullName = ''
    this.company = ''
    this.phoneNumber = ''
    this.website = ''
    this.bio = ''
    this.country = ''
    this.state = ''
    this.stateOther = ''
    this.zipCode = ''
    this.address = ''
    this.timezone = ''
    this.archived = false

    if (!this.id) return

    try {
      const json = await _fetchJson(api.setParams({ user_id: this.id }).USER_JSON, {}) as UserModel
      if (json) {
        Object.assign(this, json)
      } else {
        this.closeModal()
      }
    } finally {
      this.loading = false
    }
  }

  showModal(jsonStr: string) {
    this.id = jsonStr? JSON.parse(jsonStr).id: 0
    //
    this.loading = false
    //
    this.refresh()
    //
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }
}

class UserInfo {
  public id: number
  public name: string
  public role: number
  public status: number
  public archived: boolean = false

  constructor(i: any) {
    Object.assign(this, i)
  }

  public toJSONStr() {
    return JSON.stringify(this)
  }

  get roleAsText(): string {
    switch (this.role) {
      case 1: return 'root'
      case 2: return 'admin'
      case 3: return 'moderator'
      default: return ''
    }
  }

  get statusAsText(): string {
    switch (this.status) {
      case 1: return 'created'
      case 10: return 'verified'
      case 100: return 'blocked'
      default: return 'Error ZV6swLX3Y0'
    }
  }

  get statusClass(): string {
    switch (this.status) {
      case 1: return 'text-bg-secondary'
      case 10: return 'text-bg-success'
      case 100: return 'text-bg-secondary'
      default: return 'text-bg-danger'
    }
  }
}

export class PeopleIndex extends Datatable {
  protected _period: number = 1
  protected _charts: Record<number, HTMLElement> = {}

  constructor(items: any[], selectAllCheckbox: HTMLInputElement) {
    super(items, {
      nameOrEmail: '',
      status: 0,
      role: 0,
    })
    //
    this._selectAllCheckbox = selectAllCheckbox
  }

  static create(items: any[], selectAllCheckbox: HTMLInputElement): PeopleIndex {
    let users = []
    for (let i of items) {
      const p = new UserInfo(i)
      //
      users.push(p)
    }
    return new PeopleIndex(users, selectAllCheckbox)
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      nameOrEmail: '',
      status: 0,
      role: 0,
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    return (
        (item.fullName as string).toLowerCase().includes(this.search.nameOrEmail.toLowerCase())
        || (item.email as string).toLowerCase().includes(this.search.nameOrEmail.toLowerCase())
      ) && (
        !this.search.status ||
        (this.search.status == item.status)
      ) && (
        !this.search.role ||
        (this.search.role == item.role)
      )
  }

  public closeToolsDropdown(el: HTMLElement) {
    nextTick(function() {
      const btn = el.querySelector('[data-bs-toggle="dropdown"]')
      const dropdown = bootstrap.Dropdown.getInstance(btn)
      if (dropdown) {
        dropdown.hide()
      }
    })
  }

  public editUserSuccess(jsonStr: string) {
    const json = JSON.parse(jsonStr)
    // Có thể phần name thay đổi => cập nhật lại listview
    for (let i of this._items) {
      if (i.id == json.id) {
        i.email = json.email
        i.fullName = json.fullName
        i.status = json.status
        i.memberFor = json.memberFor
        i.lastAccess = json.lastAccess
      }
    }
  }

  public addUserSuccess(info: string) {
    let json = JSON.parse(info)
    this.add(new UserInfo(json))
  }

  public archive(user: UserInfo) {
    const self = this
    showConfirm(!user.archived? 'Are you sure you would like to archive this account?': 'Are you sure you would like to unarchive this account?', {
      buttons: [
        {
          kind: ButtonKind.Cancel,
          dismiss: true,
        },
        {
          kind: ButtonKind.OK,
          dismiss: true,
          onclick: async function() {
            showLoadingIndicator()
            try {
              const res = await fetch(api.setParams({ user_id: user.id }).CAMPAIGN_ARCHIVE_URI, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'x-csrf-token': csrfToken()
                },
                body: JSON.stringify({ value: !user.archived })
              })
              if (!res.ok) {
                throw new Error(res.statusText)
              }
              //
              user.archived = !user.archived
              // Cập nhật lại danh sách
              self.doSearch(true)
            } catch (e){
              showError(e.message)
            } finally {
              hideLoadingIndicator()
            }
          },
        }
      ]
    })
  }
}