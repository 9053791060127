import { makeid } from "#page-builder/utils"
import { htmlEncode } from "./utils"

export enum ToastType {
  /**
   * A status message.
   */
  Info = 'info',

  /**
   * A success message.
   */

  Success = 'success',

  /**
   * A warning.
   */
  Warning = 'warning',

  /**
   * An error.
   */
  Danger = 'danger',

  /**
   * An error.
   */
  Error = 'error',
}

class ToastManager {

  private toastContainer: HTMLElement

  private initToastContainer() {
    if (!this.toastContainer) {
      this.toastContainer = document.createElement('div')
      this.toastContainer.className = 'toast-container position-fixed p-3 top-0 start-50 translate-middle-x'
      //
      document.body.appendChild(this.toastContainer)
    }
  }

  addMessage(text: string, caption: string = '', type: ToastType = ToastType.Info) {
    this.initToastContainer()
    //
    const id = makeid(10)
    //
    this.toastContainer.insertAdjacentHTML('beforeend', /* html */`
      <div id="toast-${id}" class="toast toast-${ type == ToastType.Error? 'danger': type }" role="alert" aria-live="assertive" aria-atomic="true">
      
        <div class="toast-header"> 
          <i class="bi bi-circle me-2"></i>
          
          <strong class="me-auto text-capitalize">${ caption? htmlEncode(caption): type }</strong>
          
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> 
        </div>
      
      
        <div class="toast-body">
          ${htmlEncode(text)}
        </div>
      </div>
    `)
    //
    bootstrap.Toast.getOrCreateInstance(this.toastContainer.lastElementChild).show();
    //
    return this
  }


  addError(text: string, caption: string = '') {
    return this.addMessage(text, caption, ToastType.Error)
  }

  addWarning(text: string, caption: string = '') {
    return this.addMessage(text, caption, ToastType.Warning)
  }
}

export const toasts = new ToastManager()
export default toasts