document.addEventListener('focusin', (e) => {
    if (e.target.closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
        e.stopImmediatePropagation();
    }
});
const tokens = [
    { text: "Today (long). Ex: Monday, July 22 2024", value: "today.long" },
    { text: "Today (short). Ex: July 22", value: "today.short" },
    { text: "Visitor state. Ex: New York", value: "visitor.state" },
    { text: "Countdown 1 minute", value: "countdown.1min" },
    { text: "Countdown 2 minute", value: "countdown.2min" },
    { text: "Countdown 3 minute", value: "countdown.3min" },
    { text: "Countdown 4 minute", value: "countdown.4min" },
    { text: "Countdown 5 minute", value: "countdown.5min" },
    { text: "Countdown 10 minute", value: "countdown.10min" },
    { text: "Countdown 15 minute", value: "countdown.15min" },
];
const setup = (window, document) => {
    const template = document.createElement("template");
    template.innerHTML = `
      <style>
          /* The host selector targets the shadow DOM host element
            * https://developer.mozilla.org/en-US/docs/Web/CSS/:host()
            */
          :host {
            display: inline-block; /* Required to prevent some issues with added spaces when the cursor is just before or after the custom element */
            background-color: #D6F0FF;
            padding: 0 3px;
            color: #44719B;
            border-radius: 2px;
            font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
            font-size: 0.9375em;
          }

          :host(:hover) {
            cursor: default;
          }

          /* Here we create a new selection outline using box-shadow
            * that follows the border radius (in contrast to outline that
            * creates a rectangular box)
            */
          :host([contentEditable=false][data-mce-selected]) {
            outline: none !important;
            box-shadow: 0 0 0 3px #b4d7ff;
          }
      </style>

      <!--
          The web components HTML. The content between slot will be
          replaced by the content wrapped in the <inline-token> element.
          The span isn't really needed.
          https://developer.mozilla.org/en-US/docs/Web/Web_Components/Using_templates_and_slots
      -->
      <span>
          <slot>token-not-defined</slot>
      </span>
  `;
    class InlineToken extends window.HTMLElement {
        constructor() {
            super();
            this.setAttribute("contenteditable", 'false');
            const shadow = this.attachShadow({ mode: "open" });
            this.shadowRoot.appendChild(template.content.cloneNode(true));
        }
    }
    window.customElements.define("inline-token", InlineToken);
};
document.addEventListener('alpine:init', () => {
    Alpine.directive('tinymce', (el, { expression }, { evaluate, evaluateLater }) => {
        if (el.initialized)
            return;
        let [target, field] = expression.split('.');
        let obj = evaluate(target);
        tinymce.init({
            target: el,
            height: 200,
            menubar: false,
            statusbar: false,
            skin: 'oxide',
            plugins: 'image lists link anchor charmap',
            toolbar: [
                'bold italic forecolor backcolor fontsize tokens link image alignleft aligncenter alignright'
            ],
            font_size_formats: '0.5em 0.75em 1em 1.25em 1.5em 1.75em 2em',
            toolbar_mode: 'scrolling',
            powerpaste_word_import: 'clean',
            powerpaste_html_import: 'clean',
            custom_elements: "~inline-token",
            setup: function (editor) {
                editor.on('init', function () {
                    const win = editor.getWin();
                    const doc = editor.getDoc();
                    setup(win, doc);
                    editor.setContent(obj[field]);
                });
                editor.on("preinit", () => {
                    editor.serializer.addNodeFilter("inline-token", (nodes) => {
                        nodes.forEach((node) => {
                            if (!!node.attr("contenteditable")) {
                                node.attr("contenteditable", null);
                            }
                        });
                    });
                });
                editor.ui.registry.addMenuButton("tokens", {
                    text: "Token",
                    tooltip: "Insert token",
                    fetch: (callback) => {
                        var items = tokens.map((token) => {
                            return {
                                type: "menuitem",
                                text: token.text,
                                onAction: () => {
                                    editor.insertContent(`<inline-token>${token.value}</inline-token>`);
                                }
                            };
                        });
                        callback(items);
                    }
                });
                editor.on('NodeChange', function () {
                    obj[field] = editor.getContent();
                });
            },
            content_style: `
        body {
          margin: 0.5em;
          font-size: 1em;
        }
        /* We remove the default selected outline because it doesn't follow the
         * border radius we have on the inline token element and move it to the
         * inline token element
         * /
        .mce-content-body inline-token[contentEditable=false][data-mce-selected] {
          outline: none;
          cursor: default;
        }
        `,
        });
        el.initialized = true;
    });
});
