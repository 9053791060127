import { _fetchJson } from "../../_fetch";
import apiEndpoints from "../../api";
import { csrfToken } from "../../csrf";
import { ButtonKind, showConfirm, showError, showSuccess } from "../../dialogs";
import { hideLoadingIndicator, showLoadingIndicator } from "../../loading-indicator";
import { nextTick } from "#page-builder/nextTick";
import { hasInputError } from "../../utils";
import { Datatable } from "../datatable";
export class UserModel {
    id;
    _modal;
    working;
    loading = false;
    email = '';
    fullName = '';
    company = '';
    phoneNumber = '';
    website = '';
    bio = '';
    country = '';
    state = '';
    stateOther = '';
    zipCode = '';
    address = '';
    timezone = '';
    archived = false;
    status;
    constructor(id = 0) {
        this.id = id;
    }
    json() {
        const { $event, loading, working, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    async submit($dispatch) {
        if (!hasInputError(userModal)) {
            try {
                this.working = true;
                const json = await _fetchJson(apiEndpoints.USER_CREATE_OR_UPDATE, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken()
                    },
                    body: this.json()
                });
                if (json) {
                    showSuccess('The user has been saved.' + (!!json.password ? `<br>The password is: ${json.password}` : ''));
                    this.closeModal();
                    $dispatch(!parseInt(this.id) ? 'createUserSuccess' : 'editUserSuccess', JSON.stringify(json));
                }
            }
            finally {
                this.working = false;
            }
        }
    }
    async archive($dispatch) {
        try {
            this.working = true;
            const json = await _fetchJson(apiEndpoints.setParams({ domain_id: this.id }).USER_BLOCK, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: JSON.stringify({ archived: !this.archived })
            });
            if (json) {
                showSuccess(`The user has been ${this.archived ? 'unarchived' : 'archived'}.`);
                this.archived = json.archived;
                this.closeModal();
                $dispatch('editUserSuccess', JSON.stringify(json));
            }
        }
        finally {
            this.working = false;
        }
    }
    async refresh() {
        this.email = '';
        this.fullName = '';
        this.company = '';
        this.phoneNumber = '';
        this.website = '';
        this.bio = '';
        this.country = '';
        this.state = '';
        this.stateOther = '';
        this.zipCode = '';
        this.address = '';
        this.timezone = '';
        this.archived = false;
        if (!this.id)
            return;
        try {
            const json = await _fetchJson(apiEndpoints.setParams({ user_id: this.id }).USER_JSON, {});
            if (json) {
                Object.assign(this, json);
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(jsonStr) {
        this.id = jsonStr ? JSON.parse(jsonStr).id : 0;
        this.loading = false;
        this.refresh();
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
}
class UserInfo {
    id;
    name;
    role;
    status;
    archived = false;
    constructor(i) {
        Object.assign(this, i);
    }
    toJSONStr() {
        return JSON.stringify(this);
    }
    get roleAsText() {
        switch (this.role) {
            case 1: return 'root';
            case 2: return 'admin';
            case 3: return 'moderator';
            default: return '';
        }
    }
    get statusAsText() {
        switch (this.status) {
            case 1: return 'created';
            case 10: return 'verified';
            case 100: return 'blocked';
            default: return 'Error ZV6swLX3Y0';
        }
    }
    get statusClass() {
        switch (this.status) {
            case 1: return 'text-bg-secondary';
            case 10: return 'text-bg-success';
            case 100: return 'text-bg-secondary';
            default: return 'text-bg-danger';
        }
    }
}
export class PeopleIndex extends Datatable {
    _period = 1;
    _charts = {};
    constructor(items, selectAllCheckbox) {
        super(items, {
            nameOrEmail: '',
            status: 0,
            role: 0,
        });
        this._selectAllCheckbox = selectAllCheckbox;
    }
    static create(items, selectAllCheckbox) {
        let users = [];
        for (let i of items) {
            const p = new UserInfo(i);
            users.push(p);
        }
        return new PeopleIndex(users, selectAllCheckbox);
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            nameOrEmail: '',
            status: 0,
            role: 0,
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        return (item.fullName.toLowerCase().includes(this.search.nameOrEmail.toLowerCase())
            || item.email.toLowerCase().includes(this.search.nameOrEmail.toLowerCase())) && (!this.search.status ||
            (this.search.status == item.status)) && (!this.search.role ||
            (this.search.role == item.role));
    }
    closeToolsDropdown(el) {
        nextTick(function () {
            const btn = el.querySelector('[data-bs-toggle="dropdown"]');
            const dropdown = bootstrap.Dropdown.getInstance(btn);
            if (dropdown) {
                dropdown.hide();
            }
        });
    }
    editUserSuccess(jsonStr) {
        const json = JSON.parse(jsonStr);
        for (let i of this._items) {
            if (i.id == json.id) {
                i.email = json.email;
                i.fullName = json.fullName;
                i.status = json.status;
                i.memberFor = json.memberFor;
                i.lastAccess = json.lastAccess;
            }
        }
    }
    addUserSuccess(info) {
        let json = JSON.parse(info);
        this.add(new UserInfo(json));
    }
    archive(user) {
        const self = this;
        showConfirm(!user.archived ? 'Are you sure you would like to archive this account?' : 'Are you sure you would like to unarchive this account?', {
            buttons: [
                {
                    kind: ButtonKind.Cancel,
                    dismiss: true,
                },
                {
                    kind: ButtonKind.OK,
                    dismiss: true,
                    onclick: async function () {
                        showLoadingIndicator();
                        try {
                            const res = await fetch(apiEndpoints.setParams({ user_id: user.id }).CAMPAIGN_ARCHIVE_URI, {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'x-csrf-token': csrfToken()
                                },
                                body: JSON.stringify({ value: !user.archived })
                            });
                            if (!res.ok) {
                                throw new Error(res.statusText);
                            }
                            user.archived = !user.archived;
                            self.doSearch(true);
                        }
                        catch (e) {
                            showError(e.message);
                        }
                        finally {
                            hideLoadingIndicator();
                        }
                    },
                }
            ]
        });
    }
}
