import { OptionalOf } from "#page-builder/optional-of"
import { makeid10 } from "#page-builder/utils"
import { 
  Background, 
  BgStyle, 
  Borders, 
  BoxShadow, 
  D3Style, 
  d3StyleToCss, 
  Metadata,
  ShadowStyle, 
  shadowStyleToCss, 
} from "#page-builder/types/common"

declare var alert: Function

export enum ButtonType {
  Continue    = 1,
  CallANumber = 2,
  OpenPage    = 3,
  GoToUrl     = 4,
}

export enum ButtonTarget {
  None = '',
  Blank = '_blank',
  Self = '_self',
  TopmostFrame = '_top',
  ParentFrame = '_parent'
}

export const BUTTON_WIDTH_CSS_VAR = '--pb-button-width'

export class ButtonStyle {
  public color: string = '#ffffff'
  public icon: string = '' // name
  public iconSvg: string = ''
  public iconSize: number = 100 // Chia cho 100 => đơn vị em
  public iconSpacing: number = 50 // Chia cho 100 => đơn vị em
  public fontSize = 100 // Chia cho 100 => đơn vị em
  public borders: Borders = new Borders()
  public background: Background = new Background({
    style: BgStyle.SolidColor,
    //@ts-ignore
    color: {
      color: '#1163ce',
      opacity: 100
    }
  })
  //
  public boxShadow: BoxShadow = new BoxShadow()
  public paddingX: number = 75 // Chia cho 100 => đơn vị em
  public paddingY: number = 75 // Chia cho 100 => đơn vị em
  public borderRadius: number = 25 // Chia cho 100 => đơn vị em
  //
  public shadow: ShadowStyle = ShadowStyle.None
  public d3style: D3Style = D3Style.None
  //
  public opacity: number = 100 //

  public get pojo() {
    return {
      color: this.color,
      icon: this.icon,
      iconSvg: this.iconSvg,
      iconSize: this.iconSize,
      iconSpacing: this.iconSpacing,
      fontSize: this.fontSize,
      borders: this.borders.pojo,
      background: this.background.pojo,
      boxShadow: this.boxShadow.pojo,
      paddingX: this.paddingX,
      paddingY: this.paddingY,
      borderRadius: this.borderRadius,
      shadow: this.shadow,
      d3style: this.d3style,
      opacity: this.opacity
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.color = json.color
    this.icon = json.icon
    this.iconSvg = json.iconSvg
    this.iconSize = json.iconSize
    this.iconSpacing = json.iconSpacing
    this.fontSize = json.fontSize
    this.shadow = json.shadow
    this.d3style = json.d3style
    this.paddingX = json.paddingX
    this.paddingY = json.paddingY
    this.borderRadius = json.borderRadius
    this.opacity = json.opacity

    json.borders && this.borders.fromJSON(json.borders)
    json.background && this.background.fromJSON(json.background)
    json.boxShadow && this.boxShadow.fromJSON(json.boxShadow)
    
    return this
  }

  public get btnStyle(): string {
    let result: string[] = []
    //
    if (this.paddingX) {
      result.push(`padding-left: ${(this.paddingX / 100).toFixed(2)}em`)
      result.push(`padding-right: ${(this.paddingX / 100).toFixed(2)}em`)
    }
    //
    if (this.paddingY) {
      result.push(`padding-top: ${(this.paddingY / 100).toFixed(2)}em`)
      result.push(`padding-bottom: ${(this.paddingY / 100).toFixed(2)}em`)
    }
    //
    result.push(`color: ${this.color}`)
    result.push(`background-color: ${this.background.color.color}`)
    result.push(`opacity: ${this.opacity}%`)
    result.push(`border-radius: ${(this.borderRadius / 100).toFixed(2)}em`)
    result.push(`font-size: ${(this.fontSize / 100).toFixed(2)}em`)
    result.push(`width: var(${BUTTON_WIDTH_CSS_VAR})`)
    //
    result.push(this.borders.cssStyle)
    //
    const shadow: string[] = []
    if (this.shadow != ShadowStyle.None) {
      shadow.push(shadowStyleToCss(this.shadow))
    }
    if (this.d3style != D3Style.None) {
      shadow.push(d3StyleToCss(this.d3style))
    }
    if (shadow.length) {
      result.push(`box-shadow: ${shadow.join(',')}`)
    }
    //
    return result.filter(i => i).join(';')
  }

  public get iconStyle(): string {
    if (this.iconSvg) {
      return `line-height: 1em; display: inline-flex; align-items: center; justify-content: center; margin-right: ${(this.iconSpacing / 100).toFixed(2)}em`
    }
    return ''
  }

  public get iconFinalSvg(): string {
    return this.iconSvg? this.iconSvg.replaceAll(`'16'`, `${(this.iconSize / 100).toFixed(2)}em`): ''
  }
  // Chỉ dùng cho Button Style dialog
  public get text(): string {
    return 'Preview'
  }

  public clearIcon() {
    this.icon = ''
    this.iconSvg = ''
  }
}

export class Button {
  public _type: ButtonType = ButtonType.Continue
  public uuid: string = ''
  public text: string = ''
  public value: string = ''
  public target: ButtonTarget = ButtonTarget.None
  public style: ButtonStyle = new ButtonStyle()
  public meta: Metadata = new Metadata()

  constructor(
    options: OptionalOf<Button> = {}
  ) {
    this.merge(options as any)
  }

  public get isContinueButton(): boolean {
    return this.type == ButtonType.Continue
  }

  public get isCallANumberButton(): boolean {
    return this.type == ButtonType.CallANumber
  }

  public get isGoToUrlButton(): boolean {
    return this.type == ButtonType.GoToUrl
  }

  public get isOpenPageButton(): boolean {
    return this.type == ButtonType.OpenPage
  }

  public get href(): string {
    if (this.isCallANumberButton) {
      return 'tel:' + this.value
    }
    //
    return this.value
  }
  //
  public get type(): ButtonType {
    return this._type
  }
  //
  public set type(value: ButtonType) {
    if (this._type != value) {
      this._type = value
      this.value = ''
    }
  }
  //
  public get btnStyle(): string {
    return this.style.btnStyle
  }
  //
  public merge(that: Button): this {
    this.uuid = that.uuid || makeid10()
    this.text = that.text || ''
    this.value = that.value || ''
    this.target = that.target || ButtonTarget.None
    this._type = that.type || ButtonType.Continue
    //
    that.style && this.style.fromJSON(that.style)
    if (that.meta) {
      this.meta.id = that.meta.id || ''
      this.meta.class = that.meta.class || ''
    } else {
      this.meta.id = ''
      this.meta.class = ''
    }
    //
    return this
  }
  //
  public validate(): boolean {
    if (!this.text) {
      alert('Text is required')
      return false
    }
    //
    if (!this.value && this.isCallANumberButton) {
      alert('Number is required')
      return false
    }
    //
    if (!this.value && this.isGoToUrlButton) {
      alert('URL is required')
      return false
    }
    //
    if (!this.value && this.isOpenPageButton) {
      alert('Target page is required')
      return false
    }
    //
    return true
  }

  public get pojo() {
    return {
      uuid: this.uuid,
      text: this.text,
      value: this.value,
      type: this.type,
      target: this.target,
      style: this.style.pojo,
      meta: this.meta.pojo
    }
  }

  public fromJSON(json: Record<keyof Button, any>) {
    return this.merge(json)
  }

}