import { 
  Background, 
  BgStyle, 
  BoxShadow, 
  D3Style, 
  d3StyleToCss, 
  ShadowStyle, 
  shadowStyleToCss, 
} from "#page-builder/types/common"

export class DialogStyle {
  public color: string = '#072852'
  public fontSize = 1.25 // em
  public borderColor: string = '#1163ce'
  public background: Background = new Background({
    style: BgStyle.SolidColor,
    //@ts-ignore
    color: {
      color: '#cfe0f5',
      opacity: 100
    }
  })
  //
  public boxShadow: BoxShadow = new BoxShadow()
  public paddingX: number = 0.75 // em
  public paddingY: number = 0.75 // em
  public marginX: number = 0.75 // em
  public marginY: number = 0.75 // em
  public borderRadius: number = 0.25 // em
  public borderSize: number = 0 // 0..5
  //
  public shadow: ShadowStyle = ShadowStyle.Style1
  public d3style: D3Style = D3Style.None
  //
  public position: 'center' | 'bottom' = 'bottom'
  //
  public backdrop: boolean = true

  public get pojo() {
    return {
      color: this.color,
      fontSize: this.fontSize,
      borderColor: this.borderColor,
      background: this.background.pojo,
      boxShadow: this.boxShadow.pojo,
      paddingX: this.paddingX,
      paddingY: this.paddingY,
      marginX: this.marginX,
      marginY: this.marginY,
      borderRadius: this.borderRadius,
      borderSize: this.borderSize,
      shadow: this.shadow,
      d3style: this.d3style,
      position: this.position,
      backdrop: this.backdrop
    }
  }

  public fromJSON(json: Record<keyof this, any>) {
    this.color = json.color
    this.fontSize = json.fontSize
    this.shadow = json.shadow || ShadowStyle.Style1
    this.d3style = json.d3style
    this.paddingX = json.paddingX
    this.paddingY = json.paddingY
    this.marginX = json.marginX
    this.marginY = json.marginY
    this.borderRadius = json.borderRadius
    this.borderSize = json.borderSize
    this.position = json.position || 'bottom'
    this.borderColor = json.borderColor
    this.backdrop = json.backdrop

    json.background && this.background.fromJSON(json.background)
    json.boxShadow && this.boxShadow.fromJSON(json.boxShadow)
    
    return this
  }

  public get cssStyle(): string {
    let result: string[] = []
    //
    if (this.paddingX) {
      result.push(`padding-left: ${this.paddingX.toFixed(2)}em`)
      result.push(`padding-right: ${this.paddingX.toFixed(2)}em`)
    }
    //
    if (this.paddingY) {
      result.push(`padding-top: ${this.paddingY.toFixed(2)}em`)
      result.push(`padding-bottom: ${this.paddingY.toFixed(2)}em`)
    }
    //
    if (this.marginX) {
      result.push(`width: calc(100% - 2 * ${this.marginX.toFixed(2)}em)`)
    } else {
      result.push(`width: 100%`)
    }
    //
    if (this.marginY) {
      result.push(`margin-top: ${this.marginY.toFixed(2)}em`)
      result.push(`margin-bottom: ${this.marginY.toFixed(2)}em`)
    }
    //
    result.push(`color: ${this.color}`)
    result.push(`background-color: ${this.background.color.color}`)
    result.push(`border-radius: ${this.borderRadius.toFixed(2)}em`)
    //
    result.push(this.borderColor? `border: ${this.borderSize}px solid ${this.borderColor}`: '')
    //
    const shadow: string[] = []
    if (this.shadow != ShadowStyle.None) {
      shadow.push(shadowStyleToCss(this.shadow))
    }
    if (this.d3style != D3Style.None) {
      shadow.push(d3StyleToCss(this.d3style))
    }
    if (shadow.length) {
      result.push(`box-shadow: ${shadow.join(',')}`)
    }
    //
    if (this.position == 'bottom') {
      result.push(`position: fixed; left: 50%; bottom: -100%; transform: translateX(-50%);`)
    } else {
      result.push(`position: fixed; left: 50%; top: -100%; transform: translate(-50%, -50%);`)
    }
    //
    return result.filter(i => i).join(';')
  }

}