//@ts-nocheck

document.addEventListener('alpine:init', () => {
  Alpine.directive('required', (el: HTMLElement, { expression, value, modifiers }, { evaluate, effect } ) => {
    if (!el._x_model && !expression.startsWith('refs')) {
      throw new Error('x-required requires x-model or x-ref')
    }
    //
    el.classList.add('required')
    //

    let _x_model = el._x_model
    if (!_x_model) {
      const $refs = evaluate('$refs')
      const refID = expression.split(':')[1]
      _x_model = $refs[refID]._x_model
    }

    effect(() => {
      let value = _x_model.get()
      if (!!value) {
        if (modifiers.includes('trim')) {
          value = value.trim()
        }
      }
      //
      !value? el.setAttribute('__required', '') : el.removeAttribute('__required')
    })

  });
});