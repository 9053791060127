
const messageName = '________nextTick________'
const nextTickCallbacks: Function[] = []

declare var window: any

if (typeof window != 'undefined') {
  window.addEventListener('message', function (event: any) {
    if (event != null && event.source === window && event.data === messageName) {
      event.stopPropagation();
      if (nextTickCallbacks.length > 0) {
        const fn = nextTickCallbacks.shift();
        fn!();
      }
    }
  }, true)
}

export async function nextTick(callback: Function) {
  nextTickCallbacks.push(callback)
  if (typeof window != 'undefined') {
    window.postMessage(messageName, '*')
  }
}