import { nextTick } from "../utils";
import { Datatable } from "./datatable";
class WatchdogEntry {
    id;
    channel;
    message;
    context;
    createdAt;
    constructor(i) {
        Object.assign(this, i);
    }
}
export class WatchdogIndex extends Datatable {
    constructor(items, selectAllCheckbox) {
        super(items, {
            channelOrMessage: '',
        });
        this._selectAllCheckbox = selectAllCheckbox;
    }
    static create(items, selectAllCheckbox) {
        let entries = [];
        for (let i of items) {
            const p = new WatchdogEntry(i);
            entries.push(p);
        }
        return new WatchdogIndex(entries, selectAllCheckbox);
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            channelOrMessage: '',
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        return (item.channel.toLowerCase().includes(this.search.channelOrMessage.toLowerCase())
            || item.message.toLowerCase().includes(this.search.channelOrMessage.toLowerCase()));
    }
    closeToolsDropdown(el) {
        nextTick(function () {
            const btn = el.querySelector('[data-bs-toggle="dropdown"]');
            const dropdown = bootstrap.Dropdown.getInstance(btn);
            if (dropdown) {
                dropdown.hide();
            }
        });
    }
}
