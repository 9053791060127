import { _fetchJson } from "../../_fetch";
import api from "../../api";
import { csrfToken } from "../../csrf";
import { showError, showSuccess } from "../../dialogs";
import { hasInputError } from "../../utils";
import { Datatable } from "../datatable"
import DomainStatus from "./types/domain_status"

declare var _defaultDomain: Record<string, any>
declare var domainModal: HTMLElement

function getCNAME(domain: string): string {
  const parts = domain.toLowerCase().split('.');
  // Xóa phần đuôi như .net .com
  parts.pop();
  // Xóa phần chính như cloaker-shield
  parts.pop();
  //
  return parts.length ? parts.join('.') : '@';
}

/**
 * Dùng để connect/edit domain ở modal
 */
export class DomainModel {
  private _modal: Function
  //
  public working: boolean
  //
  public loading: boolean = false
  //
  public domain: string = ''
  //
  public status: DomainStatus
  //
  public archived: boolean = false

  constructor(
    public id: number = 0
  ) {

  }

  public isValidDomain() {
    const match = this.domain.match(/^(?:\*\.)?[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/)
    return !!match
  }

  public get domainText(): string {
    if (this.domain) {
      return this.isValidDomain()? this.domain: 'INVALID DOMAIN'
    }
    return 'YOUR DOMAIN'
  }

  public get cname(): string {
    if (this.domain) {
      if (this.isValidDomain()) {
        return getCNAME(this.domain)
      } else {
        return '@'
      }
    } else {
      return 'YOUR DOMAIN'
    }
  }

  //
  public get dnsRecordValue(): string {
    return _defaultDomain.domain || 'Error QX9odB6rYq'
  }

  public copyCname() {
    navigator.clipboard.writeText(this.cname)
      .then(() => showSuccess("Copied!"))
      .catch((e) => showError("There is an error when copying! Please try again later!"))
  }

  public copyDNSValue() {
    navigator.clipboard.writeText(this.dnsRecordValue)
      .then(() => showSuccess("Copied!"))
      .catch((e) => showError("There is an error when copying! Please try again later!"))
  }

  public json(): string {
    const { $event, loading, working, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit($dispatch: Function) {
    if (!hasInputError(domainModal)) {
      try {
        this.working = true
        //
        const json = await _fetchJson(api.DOMAIN_CREATE_OR_UPDATE, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken()
          },
          body: this.json()
        })
        //
        if (json) {
          showSuccess('The Domain has been saved.')
          //
          this.closeModal()
          //@ts-ignore
          $dispatch(!parseInt(this.id)? 'connectDomainSuccess': 'editDomainSuccess', JSON.stringify(json))
        }
      } finally {
        this.working = false
      }
    }
  }

  async archive($dispatch: Function) {
    try {
      this.working = true
      //
      const json = await _fetchJson(api.setParams({ domain_id: this.id }) .DOMAIN_ARCHIVE, {
        method: 'POST',
        body: JSON.stringify({ archived: !this.archived })
      })
      //
      if (json) {
        showSuccess(`The Domain has been ${ this.archived? 'unarchived': 'archived'}.`)
        //
        this.archived = json.archived
        //
        this.closeModal()
        //@ts-ignore
        $dispatch('editDomainSuccess', JSON.stringify(json))
      }
    } finally {
      this.working = false
    }
  }

  async refresh() {
    if (!this.id) {
      this.domain = ''
      this.archived = false
      return
    }

    try {
      const json = await _fetchJson(api.setParams({ domain_id: this.id }).DOMAIN_JSON, {}) as DomainModel
      if (json) {
        this.id = json.id
        this.domain = json.domain
        this.archived = json.archived
      } else {
        this.closeModal()
      }
    } finally {
      this.loading = false
    }
  }

  showModal(jsonStr: string) {
    this.id = jsonStr? JSON.parse(jsonStr).id: 0
    //
    this.loading = false
    //
    this.refresh()
    //
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }
}

/**
 * Dùng để hiển thị thông tin domain lên listview
 */
export class DomainInfo {
  public id: number
  public domain: string
  public status: DomainStatus = DomainStatus.Pending
  public updated_at: string
  public archived: boolean

  constructor(i: any) {
    Object.assign(this, i)
  }
  //
  public get statusClass(): string {
    switch (this.status) {
      case DomainStatus.Connected: return 'success'
      case DomainStatus.SslError: return 'danger'
      default: return 'warning'
    }
  }
  //
  public get statusIcon(): string {
    switch (this.status) {
      case DomainStatus.Connected: return 'bi-check-circle-fill'
      case DomainStatus.SslError: return 'bi-exclamation-circle-fill'
      default: return 'bi-plus-circle-dotted'
    }
  }

  public get statusText(): string {
    switch (this.status) {
      case DomainStatus.Connected: return 'Connected'
      case DomainStatus.Disconnected: return 'Disconnected'
      case DomainStatus.DnsDoesNotPointToServer: return 'DNS doesn\'t point correctly'
      case DomainStatus.SslError: return 'SSL certificate error'
      default: return 'Pending'
    }
  }

  public toJSONStr() {
    return JSON.stringify(this)
  }
}

export class CampaignDomains extends Datatable {
  //
  static create(items: any[]) {
    let domains = []
    for (let i of items) {
      const p = new DomainInfo(i)
      domains.push(p)
    }
    return new CampaignDomains(domains)
  }

  constructor(items: any[]) {
    super(items, {
      domain: '',
      archived: false
    })
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      domain: '',
      archived: false
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    const result = ((item.domain as string).toLowerCase().includes(this.search.domain.toLowerCase()) || !this.search.domain) && (!!item.archived == this.search.archived)
    return result
  }

  public addDomain(info: string) {
    let json = JSON.parse(info)
    this.add(new DomainInfo(json))
  }

  public editDomain(info: string) {
    let json = JSON.parse(info)
    for (let i of this._items) {
      if (i.id == json.id) {
        i.archived = !!json.archived
        break
      }
    }
    this.doSearch()
  }


}