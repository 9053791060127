import { Datatable } from "../datatable"

enum ConversionRateType {
  Success = 'success',
  Good    = 'good',
  Bad     = 'bad',
  Worse   = 'worse'
}

export class PageInfo {
  public id: number
  public name: string
  public owner: string
  public visitors: number
  public conversions: number
  public blocked: any
  public updated_at: string
  public archived: boolean

  constructor(i: any) {
    Object.assign(this, i)
  }
  //
  public get conversionRateType(): ConversionRateType {
    if (this.visitors) {

    }
    return ConversionRateType.Good
  }
  //
  public get conversionRateTypeClass(): string {
    switch (this.conversionRateType) {
      case ConversionRateType.Success: return 'primary'
      case ConversionRateType.Good: return 'success'
      case ConversionRateType.Bad: return 'warning'
      case ConversionRateType.Worse: return 'danger'
    }
  }
  //
  public get conversionRateTypeIcon(): string {
    switch (this.conversionRateType) {
      case ConversionRateType.Success: return 'bi-award-fill'
      case ConversionRateType.Good: return 'bi-check-circle-fill'
      case ConversionRateType.Bad: return 'bi-exclamation-circle-fill'
      case ConversionRateType.Worse: return 'bi-slash-circle-fill'
    }
  }

  public toJSONStr() {
    return JSON.stringify(this)
  }
}

export class CampaignPages extends Datatable {
  //
  static create(items: any[]) {
    let pages = []
    for (let i of items) {
      const p = new PageInfo(i)
      pages.push(p)
    }
    return new CampaignPages(pages)
  }

  constructor(items: any[]) {
    super(items, {
      name: '',
      archived: false
    })
  }

  protected isEqual(a: any, b: any): boolean {
    return a.id == b.id
  }

  public resetSearch() {
    this._search = {
      name: '',
      archived: false
    }
    //
    this.doSearch()
  }

  protected matchesSearchCriteria(item: any): boolean {
    const result = ((item.name as string).toLowerCase().includes(this.search.name.toLowerCase()) || !this.search.name) && (!!item.archived == this.search.archived)
    return result
  }

  public editPage(info: string) {
    let json = JSON.parse(info)
    for (let i of this._items) {
      if (i.id == json.id) {
        i.archived = !!json.archived
        break
      }
    }
    this.doSearch()
  }


}