import { showError } from "../../dialogs"
import { ItemRelative } from "#page-builder/types/common"

export class PageBuilderAddNew {
  private _modal: Function
  // Selected template
  public template: string = ''
  //
  public placement: ItemRelative = ItemRelative.AfterCurrentElement
  //

  showModal() {
    // Reset không chọn trước template nào
    this.template = ''
    this.placement = ItemRelative.AfterCurrentElement
    // Hiển thị modal
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show()
  }

  closeModal() {
    bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide()
  }

  public async submit($dispatch: Function) {
    if (!this.template) {
      return showError('Please select a template')
    }
    $dispatch('pbAddElement', { template: this.template, placement: this.placement })
    //
    this.closeModal()
  }
}