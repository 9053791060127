import stripTags from "../util/strip_tags/striptags";
export var Icon;
(function (Icon) {
    Icon["Alert"] = "<i class=\"bi bi-exclamation-circle-fill text-danger\"></i>";
    Icon["Warning"] = "<i class=\"bi bi-exclamation-triangle-fill text-warning\"></i>";
    Icon["Question"] = "<i class=\"bi bi-question-circle-fill text-info\"></i>";
    Icon["Success"] = "<i class=\"bi bi-check-circle-fill text-success\"></i>";
    Icon["Info"] = "<i class=\"bi bi-info-square-fill text-warning\"></i>";
})(Icon || (Icon = {}));
export var ButtonKind;
(function (ButtonKind) {
    ButtonKind["OK"] = "OK";
    ButtonKind["Cancel"] = "Cancel";
    ButtonKind["Yes"] = "Yes";
    ButtonKind["No"] = "No";
    ButtonKind["Close"] = "Close";
    ButtonKind["Continue"] = "Continue";
    ButtonKind["BackToHome"] = "Back to Home";
})(ButtonKind || (ButtonKind = {}));
export var DialogType;
(function (DialogType) {
    DialogType["Info"] = "info";
    DialogType["Success"] = "success";
    DialogType["Warning"] = "warning";
    DialogType["Danger"] = "danger";
})(DialogType || (DialogType = {}));
export function showDialog(text, options = {}) {
    options = { buttons: [{ kind: ButtonKind.OK, dismiss: true }], ...options };
    const elm = document.createElement('div');
    const buttonsHtml = [];
    for (let i = 0; i < options.buttons.length; i++) {
        const btn = document.createElement('button');
        btn.setAttribute('type', 'button');
        btn.textContent = options.buttons[i].kind;
        btn.className = options.buttons[i].class ? 'btn ' + options.buttons[i].class
            : i == options.buttons.length - 1 && !options.buttons[i].class ? 'btn btn-primary' : 'btn btn-secondary';
        if (options.buttons[i].dismiss) {
            btn.setAttribute('data-bs-dismiss', 'modal');
        }
        btn.addEventListener('click', options.buttons[i].onclick);
        buttonsHtml.push(btn);
    }
    const modalHeader = options.caption ? `
    <div class="modal-header">
      <h1 class="modal-title fs-5">${stripTags(options.caption, { allowedTags: 'b i em strong br span' })}</h1><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    `
        : '';
    let body = options.icon ? `
    <div class="dialog-body">
      ${options.icon}
      <div class="d-flex align-items-center">
        <div class="w-100">${stripTags(text, { allowedTags: 'b i em strong br span' })}</div>
      </div>
    </div>
    `
        : stripTags(text, { allowedTags: 'b i em strong br span' });
    if (options.link) {
        const u = new URL(options.link);
        body = body.replaceAll(':link', `<div class="rounded-3 mt-1 p-2 bg-secondary-subtle"><a href="${options.link}" target="_blank" class="text-decoration-none">${options.link}</a></div>`);
    }
    elm.className = 'dialog';
    elm.innerHTML = `
    <div class="modal" 
      data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-hidden="true"
      style="--bs-modal-zindex: 100000; --bs-backdrop-zindex: 99999"
    >
      <div class="modal-dialog p-3">
        <div class="modal-content">
          ${modalHeader}
          <div class="modal-body">
            ${body}
          </div>
          <div class="modal-footer py-2">
          </div>
        </div>
      </div>
    </div>
  `;
    const modal = new bootstrap.Modal(elm.querySelector('.modal'));
    elm.addEventListener('hidden.bs.modal', function () {
        setTimeout(function () {
            modal.dispose();
            elm.parentNode.removeChild(elm);
        }, 100);
    });
    elm.querySelector('.modal-footer').replaceChildren(...buttonsHtml);
    document.body.appendChild(elm);
    modal.show();
}
export function showError(text, options = {}) {
    showDialog(text, { icon: Icon.Alert, ...options });
}
export function showWarning(text, options = {}) {
    showDialog(text, { icon: Icon.Warning, ...options });
}
export function showValidationError(details, options = {}) {
    showDialog(`<span class="fw-semibold">Validation failure</span> <br> ${details.join("<br>")}`, { icon: Icon.Alert, ...options });
}
export function showInfo(text, options = {}) {
    showDialog(text, { icon: Icon.Info, ...options });
}
export function showSuccess(text, options = {}) {
    showDialog(text, { icon: Icon.Success, ...options });
}
export function showConfirm(text, options = {}) {
    showDialog(text, { icon: Icon.Question, ...options });
}
