import { _fetchJson, _fetchJson2 } from "../../_fetch";
import apiEndpoints from "../../api";
import { csrfToken } from "../../csrf";
import { showError, showSuccess } from "../../dialogs";
import { hasInputError } from "../../utils";
import { Datatable } from "../datatable";
import DomainStatus from "./types/domain_status";
function getCNAME(domain) {
    const parts = domain.toLowerCase().split('.');
    parts.pop();
    parts.pop();
    return parts.length ? parts.join('.') : '@';
}
export class DomainModel {
    id;
    _modal;
    working;
    loading = false;
    domain = '';
    status;
    archived = false;
    constructor(id = 0) {
        this.id = id;
    }
    isValidDomain() {
        const match = this.domain.match(/^(?:\*\.)?[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/);
        return !!match;
    }
    get domainText() {
        if (this.domain) {
            return this.isValidDomain() ? this.domain : 'INVALID DOMAIN';
        }
        return 'YOUR DOMAIN';
    }
    get cname() {
        if (this.domain) {
            if (this.isValidDomain()) {
                return getCNAME(this.domain);
            }
            else {
                return '@';
            }
        }
        else {
            return 'YOUR DOMAIN';
        }
    }
    get dnsRecordValue() {
        return _defaultDomain.domain || 'Error QX9odB6rYq';
    }
    copyCname() {
        navigator.clipboard.writeText(this.cname)
            .then(() => showSuccess("Copied!"))
            .catch((e) => showError("There is an error when copying! Please try again later!"));
    }
    copyDNSValue() {
        navigator.clipboard.writeText(this.dnsRecordValue)
            .then(() => showSuccess("Copied!"))
            .catch((e) => showError("There is an error when copying! Please try again later!"));
    }
    json() {
        const { $event, loading, working, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    async submit($dispatch) {
        if (!hasInputError(domainModal)) {
            try {
                this.working = true;
                const json = await _fetchJson(apiEndpoints.DOMAIN_CREATE_OR_UPDATE, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken()
                    },
                    body: this.json()
                });
                if (json) {
                    showSuccess('The domain has been saved');
                    this.closeModal();
                    $dispatch(!parseInt(this.id) ? 'connectDomainSuccess' : 'editDomainSuccess', JSON.stringify(json));
                }
            }
            finally {
                this.working = false;
            }
        }
    }
    async publish() {
        const res = await _fetchJson2(apiEndpoints.setParams({ domain_id: this.id }).DOMAIN_PUBLICATION, {
            method: 'POST',
        });
        if (res) {
            showSuccess(`The pages which associate with the domain have been published`);
        }
    }
    async archive($dispatch) {
        try {
            this.working = true;
            const json = await _fetchJson(apiEndpoints.setParams({ domain_id: this.id }).DOMAIN_ARCHIVE, {
                method: 'POST',
                body: JSON.stringify({ archived: !this.archived })
            });
            if (json) {
                showSuccess(`The domain has been ${this.archived ? 'unarchived' : 'archived'}.`);
                this.archived = json.archived;
                this.closeModal();
                $dispatch('editDomainSuccess', JSON.stringify(json));
            }
        }
        finally {
            this.working = false;
        }
    }
    async refresh() {
        if (!this.id) {
            this.domain = '';
            this.archived = false;
            return;
        }
        try {
            this.loading = true;
            const json = await _fetchJson(apiEndpoints.setParams({ domain_id: this.id }).DOMAIN_JSON, {});
            if (json) {
                this.id = json.id;
                this.domain = json.domain;
                this.archived = json.archived;
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(jsonStr) {
        this.id = jsonStr ? JSON.parse(jsonStr).id : 0;
        this.loading = false;
        this.refresh();
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
}
export class DomainInfo {
    id;
    domain;
    status = DomainStatus.Pending;
    updated_at;
    archived;
    constructor(i) {
        Object.assign(this, i);
    }
    get statusClass() {
        switch (this.status) {
            case DomainStatus.Connected: return 'success';
            case DomainStatus.SslError: return 'danger';
            default: return 'warning';
        }
    }
    get statusIcon() {
        switch (this.status) {
            case DomainStatus.Connected: return 'bi-check-circle-fill';
            case DomainStatus.SslError: return 'bi-exclamation-circle-fill';
            default: return 'bi-plus-circle-dotted';
        }
    }
    get statusText() {
        switch (this.status) {
            case DomainStatus.Connected: return 'Connected';
            case DomainStatus.Disconnected: return 'Disconnected';
            case DomainStatus.DnsDoesNotPointToServer: return 'DNS doesn\'t point correctly';
            case DomainStatus.SslError: return 'SSL certificate error';
            default: return 'Pending';
        }
    }
    toJSONStr() {
        return JSON.stringify(this);
    }
}
export class CampaignDomains extends Datatable {
    static create(items) {
        let domains = [];
        for (let i of items) {
            const p = new DomainInfo(i);
            domains.push(p);
        }
        return new CampaignDomains(domains);
    }
    constructor(items) {
        super(items, {
            domain: '',
            archived: false
        });
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            domain: '',
            archived: false
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        const result = (item.domain.toLowerCase().includes(this.search.domain.toLowerCase()) || !this.search.domain) && (!!item.archived == this.search.archived);
        return result;
    }
    addDomain(info) {
        let json = JSON.parse(info);
        this.add(new DomainInfo(json));
    }
    editDomain(info) {
        let json = JSON.parse(info);
        for (let i of this._items) {
            if (i.id == json.id) {
                i.archived = !!json.archived;
                break;
            }
        }
        this.doSearch();
    }
}
