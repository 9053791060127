import { Datatable } from "../datatable";
var ConversionRateType;
(function (ConversionRateType) {
    ConversionRateType["Success"] = "success";
    ConversionRateType["Good"] = "good";
    ConversionRateType["Bad"] = "bad";
    ConversionRateType["Worse"] = "worse";
})(ConversionRateType || (ConversionRateType = {}));
export class PageInfo {
    id;
    name;
    owner;
    visitors;
    conversions;
    blocked;
    updated_at;
    archived;
    constructor(i) {
        Object.assign(this, i);
    }
    get conversionRateType() {
        if (this.visitors) {
        }
        return ConversionRateType.Good;
    }
    get conversionRateTypeClass() {
        switch (this.conversionRateType) {
            case ConversionRateType.Success: return 'primary';
            case ConversionRateType.Good: return 'success';
            case ConversionRateType.Bad: return 'warning';
            case ConversionRateType.Worse: return 'danger';
        }
    }
    get conversionRateTypeIcon() {
        switch (this.conversionRateType) {
            case ConversionRateType.Success: return 'bi-award-fill';
            case ConversionRateType.Good: return 'bi-check-circle-fill';
            case ConversionRateType.Bad: return 'bi-exclamation-circle-fill';
            case ConversionRateType.Worse: return 'bi-slash-circle-fill';
        }
    }
    toJSONStr() {
        return JSON.stringify(this);
    }
}
export class CampaignPages extends Datatable {
    static create(items) {
        let pages = [];
        for (let i of items) {
            const p = new PageInfo(i);
            pages.push(p);
        }
        return new CampaignPages(pages);
    }
    constructor(items) {
        super(items, {
            name: '',
            archived: false
        });
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            name: '',
            archived: false
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        const result = (item.name.toLowerCase().includes(this.search.name.toLowerCase()) || !this.search.name) && (!!item.archived == this.search.archived);
        return result;
    }
    editPage(info) {
        let json = JSON.parse(info);
        for (let i of this._items) {
            if (i.id == json.id) {
                i.archived = !!json.archived;
                break;
            }
        }
        this.doSearch();
    }
}
