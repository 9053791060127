import { opacity100ToHex } from "#page-builder/utils";
function merge(_this, _that) {
    for (let i in _that) {
        if (typeof _this[i] == 'object') {
            merge(_this[i], _that[i]);
        }
        else {
            _this[i] = _that[i];
        }
    }
}
export var ItemRelative;
(function (ItemRelative) {
    ItemRelative[ItemRelative["AfterCurrentElement"] = 1] = "AfterCurrentElement";
    ItemRelative[ItemRelative["BeforeCurrentElement"] = 11] = "BeforeCurrentElement";
    ItemRelative[ItemRelative["AtTheBeginningOfCurrentBlock"] = 21] = "AtTheBeginningOfCurrentBlock";
    ItemRelative[ItemRelative["AtTheEndOfCurrentBlock"] = 31] = "AtTheEndOfCurrentBlock";
    ItemRelative[ItemRelative["AtTheBeginningOfCurrentSection"] = 2] = "AtTheBeginningOfCurrentSection";
    ItemRelative[ItemRelative["AtTheEndOfCurrentSection"] = 12] = "AtTheEndOfCurrentSection";
    ItemRelative[ItemRelative["AtTheBeginningOfThePage"] = 3] = "AtTheBeginningOfThePage";
    ItemRelative[ItemRelative["AtTheEndOfThePage"] = 13] = "AtTheEndOfThePage";
})(ItemRelative || (ItemRelative = {}));
export var ItemRelativeGroup;
(function (ItemRelativeGroup) {
    ItemRelativeGroup[ItemRelativeGroup["None"] = 0] = "None";
    ItemRelativeGroup[ItemRelativeGroup["Block"] = 1] = "Block";
    ItemRelativeGroup[ItemRelativeGroup["Section"] = 2] = "Section";
    ItemRelativeGroup[ItemRelativeGroup["Page"] = 3] = "Page";
})(ItemRelativeGroup || (ItemRelativeGroup = {}));
export var Protection;
(function (Protection) {
    Protection["None"] = "";
    Protection["ShowToBlockedVisitors"] = "show-to-blocked-visitors";
    Protection["HideFromBlockedVisitors"] = "hide-from-blocked-visitors";
})(Protection || (Protection = {}));
export var DeviceTypes;
(function (DeviceTypes) {
    DeviceTypes["Desktop"] = "desktop";
    DeviceTypes["Tablet"] = "tablet";
    DeviceTypes["Mobile"] = "mobile";
})(DeviceTypes || (DeviceTypes = {}));
export class ScreenBreakpoints {
    mobile = 576;
    tablet = 992;
    desktop = 999999;
    get pojo() {
        return {
            mobile: this.mobile,
            tablet: this.tablet,
            desktop: this.desktop
        };
    }
    fromJSON(json) {
        this.mobile = json.mobile;
        this.tablet = json.tablet;
        this.desktop = json.desktop;
    }
}
export var VerticalAlignment;
(function (VerticalAlignment) {
    VerticalAlignment["Auto"] = "auto";
    VerticalAlignment["Start"] = "start";
    VerticalAlignment["Center"] = "center";
    VerticalAlignment["End"] = "end";
    VerticalAlignment["Stretch"] = "stretch";
})(VerticalAlignment || (VerticalAlignment = {}));
export var HorizontalAlignment;
(function (HorizontalAlignment) {
    HorizontalAlignment["Start"] = "start";
    HorizontalAlignment["Center"] = "center";
    HorizontalAlignment["End"] = "end";
})(HorizontalAlignment || (HorizontalAlignment = {}));
export class CssSizeValue {
    value = 0;
    unit = 'px';
    auto = false;
    constructor(options = {}) {
        options.auto !== undefined && (this.auto = options.auto);
        options.unit !== undefined && (this.unit = options.unit);
        options.value !== undefined && (this.value = options.value);
    }
    get pojo() {
        return {
            value: this.value,
            unit: this.unit,
            auto: this.auto
        };
    }
    fromJSON(json) {
        this.value = json.value;
        this.unit = json.unit || 'px';
        this.auto = json.auto;
    }
    toCss(scale = 1) {
        switch (this.unit) {
            case 'vh': return `calc(var(--vh) * ${this.value})`;
            case 'vw': return `calc(var(--vw) * ${this.value})`;
            case 'calc': return `calc(${this.value})`.replaceAll('vh', '*var(--vh)').replaceAll('vw', '*var(--vw)');
            default: return parseInt(this.value) ? `${this.value * scale}${this.unit}` : '0px';
        }
    }
}
export class Margin {
    top = new CssSizeValue();
    right = new CssSizeValue();
    bottom = new CssSizeValue();
    left = new CssSizeValue();
    get cssStyle() {
        const result = [];
        if (this.top.value) {
            result.push(`margin-top: ${this.top.value}${this.top.unit}`);
        }
        if (this.right.value) {
            result.push(`margin-right: ${this.right.value}${this.right.unit}`);
        }
        if (this.bottom.value) {
            result.push(`margin-bottom: ${this.bottom.value}${this.bottom.unit}`);
        }
        if (this.left.value) {
            result.push(`margin-left: ${this.left.value}${this.left.unit}`);
        }
        return result.join(' ;');
    }
    get pojo() {
        return {
            top: this.top.pojo,
            right: this.right.pojo,
            bottom: this.bottom.pojo,
            left: this.left.pojo
        };
    }
    fromJSON(json) {
        json.top && this.top.fromJSON(json.top);
        json.right && this.right.fromJSON(json.right);
        json.bottom && this.bottom.fromJSON(json.bottom);
        json.left && this.left.fromJSON(json.left);
    }
}
export class Padding {
    top = new CssSizeValue();
    right = new CssSizeValue();
    bottom = new CssSizeValue();
    left = new CssSizeValue();
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get cssStyle() {
        const result = [];
        if (this.top.value) {
            result.push(`padding-top: ${this.top.value}${this.top.unit}`);
        }
        if (this.right.value) {
            result.push(`padding-right: ${this.right.value}${this.right.unit}`);
        }
        if (this.bottom.value) {
            result.push(`padding-bottom: ${this.bottom.value}${this.bottom.unit}`);
        }
        if (this.left.value) {
            result.push(`padding-left: ${this.left.value}${this.left.unit}`);
        }
        return result.join(' ;');
    }
    get pojo() {
        return {
            top: this.top.pojo,
            right: this.right.pojo,
            bottom: this.bottom.pojo,
            left: this.left.pojo
        };
    }
    fromJSON(json) {
        json.top && this.top.fromJSON(json.top);
        json.right && this.right.fromJSON(json.right);
        json.bottom && this.bottom.fromJSON(json.bottom);
        json.left && this.left.fromJSON(json.left);
    }
}
export var Overflow;
(function (Overflow) {
    Overflow["Visible"] = "visible";
    Overflow["Hidden"] = "hidden";
})(Overflow || (Overflow = {}));
export class Dimension {
    width = new CssSizeValue({ auto: true });
    height = new CssSizeValue({ auto: true });
    maxWidth = new CssSizeValue({ auto: true });
    minWidth = new CssSizeValue({ auto: true });
    maxHeight = new CssSizeValue({ auto: true });
    minHeight = new CssSizeValue({ auto: true });
    overflow = Overflow.Hidden;
    get cssStyle() {
        const result = [];
        if (!this.width.auto) {
            result.push(`width: ${this.width.toCss()}`);
        }
        if (!this.height.auto) {
            result.push(`height: ${this.height.toCss()}`);
        }
        if (!this.maxWidth.auto) {
            result.push(`max-width: ${this.maxWidth.toCss()}`);
        }
        if (!this.minWidth.auto) {
            result.push(`min-width: ${this.minWidth.toCss()}`);
        }
        if (!this.maxHeight.auto) {
            result.push(`max-height: ${this.maxHeight.toCss()}`);
        }
        if (!this.minHeight.auto) {
            result.push(`min-height: ${this.minHeight.toCss()}`);
        }
        result.push(`overflow: ${this.overflow}`);
        return result.join(';');
    }
    get pojo() {
        return {
            width: this.width.pojo,
            height: this.height.pojo,
            maxWidth: this.maxWidth.pojo,
            minWidth: this.minWidth.pojo,
            maxHeight: this.maxHeight.pojo,
            minHeight: this.minHeight.pojo,
            overflow: this.overflow
        };
    }
    fromJSON(json) {
        this.overflow = json.overflow || Overflow.Hidden;
        json.width && this.width.fromJSON(json.width);
        json.height && this.height.fromJSON(json.height);
        json.maxWidth && this.maxWidth.fromJSON(json.maxWidth);
        json.minWidth && this.minWidth.fromJSON(json.minWidth);
        json.maxHeight && this.maxHeight.fromJSON(json.maxHeight);
        json.minHeight && this.minHeight.fromJSON(json.minHeight);
    }
}
export var BgStyle;
(function (BgStyle) {
    BgStyle[BgStyle["None"] = 0] = "None";
    BgStyle[BgStyle["SolidColor"] = 1] = "SolidColor";
    BgStyle[BgStyle["Image"] = 2] = "Image";
    BgStyle[BgStyle["Video"] = 3] = "Video";
    BgStyle[BgStyle["Pattern"] = 4] = "Pattern";
    BgStyle[BgStyle["Gradient"] = 5] = "Gradient";
})(BgStyle || (BgStyle = {}));
export var BgImagePosition;
(function (BgImagePosition) {
    BgImagePosition["Top"] = "top";
    BgImagePosition["Bottom"] = "bottom";
    BgImagePosition["Left"] = "left";
    BgImagePosition["Right"] = "right";
    BgImagePosition["Center"] = "center";
})(BgImagePosition || (BgImagePosition = {}));
export class ColorWithOpacity {
    color = '#f0f0f0';
    opacity = 100;
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get pojo() {
        return {
            color: this.color,
            opacity: this.opacity
        };
    }
    fromJSON(json) {
        this.color = json.color || '#f0f0f0';
        this.opacity = json.opacity || 100;
    }
    toCss() {
        return `${this.color}${opacity100ToHex(this.opacity)}`;
    }
}
export class Typography {
    color = new ColorWithOpacity({ color: '#212529' });
    family = '';
    size = 100;
    weight = 400;
    italic = false;
    underline = false;
    lineHeight = 150;
    override = false;
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get pojo() {
        return {
            color: this.color.pojo,
            family: this.family,
            size: this.size,
            weight: this.weight,
            italic: this.italic,
            underline: this.underline,
            lineHeight: this.lineHeight,
            override: this.override
        };
    }
    fromJSON(json) {
        this.family = json.family,
            this.size = json.size || 100;
        this.weight = json.weight || 400;
        this.italic = json.italic || false;
        this.underline = json.underline || false;
        this.override = json.override || false;
        this.lineHeight = json.lineHeight || 150;
        json.color && this.color.fromJSON(json.color);
    }
    get cssStyle() {
        const result = [];
        if (this.override) {
            this.family && result.push(`font-family: "${this.family}"`);
            this.italic && result.push(`font-style: italic`);
            this.underline && result.push(`text-decoration: underline`);
            result.push(`font-size: ${(this.size / 100).toFixed(2)}em`);
            result.push(`font-weight: ${this.weight}`);
            result.push(`line-height: ${(this.lineHeight / 100).toFixed(2)}em`);
            result.push(`color: ${this.color.toCss()}`);
        }
        return result.join(' ;');
    }
}
export class TypographyPerDevice {
    mobile = new Typography();
    tablet = new Typography();
    desktop = new Typography();
    constructor() {
    }
    get pojo() {
        return {
            mobile: this.mobile.pojo,
            tablet: this.tablet.pojo,
            desktop: this.desktop.pojo
        };
    }
    fromJSON(json) {
        json.mobile && this.mobile.fromJSON(json.mobile);
        json.tablet && this.tablet.fromJSON(json.tablet);
        json.desktop && this.desktop.fromJSON(json.desktop);
    }
    get cssStyle() {
        return '';
    }
    getFontFaces() {
        const result = [];
        if (this.mobile.override) {
            result.push(this.mobile.family);
        }
        if (this.tablet.override) {
            result.push(this.tablet.family);
        }
        if (this.desktop.override) {
            result.push(this.desktop.family);
        }
        return result;
    }
}
export class GradientStop {
    color;
    stop;
    constructor(color = '#000000', stop = 50) {
        this.color = color;
        this.stop = stop;
    }
}
export var GradientStyle;
(function (GradientStyle) {
    GradientStyle["Linear"] = "linear";
    GradientStyle["Radial"] = "radial";
})(GradientStyle || (GradientStyle = {}));
export class BgGradient {
    style = GradientStyle.Linear;
    angle = 0;
    stops = [];
    get pojo() {
        const stops = [];
        for (let i of this.stops) {
            stops.push({
                color: i.color,
                stop: i.stop
            });
        }
        return {
            style: this.style,
            angle: this.angle,
            stops
        };
    }
    fromJSON(json) {
        this.style = json.style;
        this.angle = json.angle;
        const stops = [];
        if (Array.isArray(json.stops) && json.stops.length) {
            for (let i of json.stops) {
                stops.push(new GradientStop(i.color, i.stop));
            }
        }
        this.stops = stops;
    }
}
export class Background {
    parallaxEffect = false;
    stretch = true;
    fitContainer = true;
    position = BgImagePosition.Center;
    color = new ColorWithOpacity();
    gradient = new BgGradient();
    style = BgStyle.None;
    constructor(options = {}) {
        merge(this, options);
    }
    get imageFilename() {
        return 'file-name.jpg';
    }
    get cssStyle() {
        if (this.style == BgStyle.SolidColor)
            return `background-color: ${this.color.toCss()}`;
        return '';
    }
    get pojo() {
        return {
            parallaxEffect: this.parallaxEffect,
            stretch: this.stretch,
            fitContainer: this.fitContainer,
            position: this.position,
            color: this.color.pojo,
            style: this.style,
            gradient: this.gradient.pojo
        };
    }
    fromJSON(json) {
        this.parallaxEffect = json.parallaxEffect;
        this.stretch = json.stretch;
        this.fitContainer = json.fitContainer;
        this.position = json.position;
        this.style = json.style;
        json.color && this.color.fromJSON(json.color);
        json.gradient && this.gradient.fromJSON(json.gradient);
    }
}
export var BorderStyle;
(function (BorderStyle) {
    BorderStyle["None"] = "none";
    BorderStyle["Dotted"] = "dotted";
    BorderStyle["Dashed"] = "dashed";
    BorderStyle["Solid"] = "solid";
    BorderStyle["Double"] = "double";
    BorderStyle["Groove"] = "groove";
    BorderStyle["Ridge"] = "ridge";
    BorderStyle["Inset"] = "inset";
    BorderStyle["Outset"] = "outset";
})(BorderStyle || (BorderStyle = {}));
export class Border {
    side;
    width = new CssSizeValue({ value: 1 });
    style = BorderStyle.None;
    color = '#cccccc';
    constructor(side = '') {
        this.side = side;
    }
    get pojo() {
        return {
            width: this.width.pojo,
            style: this.style,
            color: this.color
        };
    }
    fromJSON(json) {
        this.width.fromJSON(json.width);
        this.style = json.style;
        this.color = json.color;
    }
    get cssStyle() {
        if ((this.style != BorderStyle.None) && this.width.value) {
            return `border${this.side ? '-' : ''}${this.side}: ${this.width.value}${this.width.unit} ${this.style} ${this.color}`;
        }
        return `border${this.side ? '-' : ''}${this.side}: 0 none`;
    }
}
export class Borders {
    top = new Border('top');
    right = new Border('right');
    bottom = new Border('bottom');
    left = new Border('left');
    expandTop = false;
    expandBottom = false;
    radius = new CssSizeValue({
        auto: false,
        value: 0
    });
    get pojo() {
        return {
            top: this.top.pojo,
            right: this.right.pojo,
            bottom: this.bottom.pojo,
            left: this.left.pojo,
            radius: this.radius.pojo,
            expandTop: this.expandTop,
            expandBottom: this.expandBottom
        };
    }
    fromJSON(json) {
        json.top && this.top.fromJSON(json.top);
        json.right && this.right.fromJSON(json.right);
        json.bottom && this.bottom.fromJSON(json.bottom);
        json.left && this.left.fromJSON(json.left);
        json.radius && this.radius.fromJSON(json.radius);
        this.expandTop = json.expandTop;
        this.expandBottom = json.expandBottom;
    }
    get cssStyle() {
        const borderRadiusCss = this.radius.value ? `border-radius: ${this.radius.toCss()}` : ``;
        return [this.top.cssStyle, this.right.cssStyle, this.bottom.cssStyle, this.left.cssStyle, borderRadiusCss].filter(i => i).join(';');
    }
}
export class Visibility {
    mobile = true;
    tablet = true;
    desktop = true;
    delayShowing = false;
    delayShowingValue = 0;
    get pojo() {
        return {
            mobile: this.mobile,
            tablet: this.tablet,
            desktop: this.desktop,
            delayShowing: this.delayShowing,
            delayShowingValue: this.delayShowingValue
        };
    }
    fromJSON(json) {
        this.mobile = json.mobile;
        this.tablet = json.tablet;
        this.desktop = json.desktop;
        this.delayShowing = !!json?.delayShowing;
        this.delayShowingValue = json.delayShowingValue || 0;
    }
}
export class GridSizing {
    mobile = 12;
    tablet = 12;
    desktop = 12;
    get pojo() {
        return {
            mobile: this.mobile,
            tablet: this.tablet,
            desktop: this.desktop
        };
    }
    fromJSON(json) {
        this.mobile = json.mobile;
        this.tablet = json.tablet;
        this.desktop = json.desktop;
    }
}
export class Metadata {
    id = '';
    class = '';
    get pojo() {
        return {
            id: this.id,
            class: this.class,
        };
    }
    fromJSON(json) {
        this.id = json.id;
        this.class = json.class;
    }
}
export var ShadowStyle;
(function (ShadowStyle) {
    ShadowStyle["None"] = "-";
    ShadowStyle["Style1"] = "style-1";
    ShadowStyle["Style2"] = "style-2";
    ShadowStyle["Style3"] = "style-3";
    ShadowStyle["Style4"] = "style-4";
    ShadowStyle["Style5"] = "style-5";
})(ShadowStyle || (ShadowStyle = {}));
export function shadowStyleToCss(style) {
    switch (style) {
        case ShadowStyle.Style1: return '0 2px 4px 0 rgba(0,0,0,0.2), 0 2px 5px 0 rgba(0,0,0,0.19)';
        case ShadowStyle.Style2: return '0 4px 8px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19)';
        case ShadowStyle.Style3: return '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)';
        case ShadowStyle.Style4: return '5px 5px rgba(0,0,0,0.5)';
        case ShadowStyle.Style5: return '-5px -5px rgba(0,0,0,0.5)';
    }
    return '';
}
export var D3Style;
(function (D3Style) {
    D3Style["None"] = "-";
    D3Style["Style1"] = "style-1";
    D3Style["Style2"] = "style-2";
    D3Style["Style3"] = "style-3";
    D3Style["Style4"] = "style-4";
    D3Style["Style5"] = "style-5";
})(D3Style || (D3Style = {}));
export function d3StyleToCss(style) {
    switch (style) {
        case D3Style.Style1: return 'inset 1px 2px 0 rgba(255, 255, 255, 0.15), inset -1px -2px 0 rgba(255, 255, 255, 0.15)';
        case D3Style.Style2: return 'inset -2px -2px 0 rgba(255, 255, 255, 0.25),inset 2px 2px 0 rgba(255, 255, 255, 0.25)';
        case D3Style.Style3: return 'inset 2px 2px 0 rgba(255, 255, 255, 0.25)';
    }
    return '';
}
export class BoxShadowValue {
    color = new ColorWithOpacity({
        color: '#000000',
        opacity: 100
    });
    positionX = new CssSizeValue();
    positionY = new CssSizeValue();
    blur = new CssSizeValue();
    spread = new CssSizeValue();
    inset = false;
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get pojo() {
        return {
            color: this.color,
            positionX: this.positionX.pojo,
            positionY: this.positionY.pojo,
            blur: this.blur.pojo,
            spread: this.spread.pojo,
        };
    }
    fromJSON(json) {
        this.color = json.color;
        json.positionX && this.positionX.fromJSON(json.positionX);
        json.positionY && this.positionY.fromJSON(json.positionY);
        json.blur && this.blur.fromJSON(json.blur);
        json.spread && this.spread.fromJSON(json.spread);
    }
    toCss() {
        let result = [
            this.inset ? 'inset' : '',
            this.positionX.toCss(),
            this.positionY.toCss(),
            this.blur.toCss(),
            this.spread.toCss(),
            this.color
        ].filter(i => i).join(' ');
        return result;
    }
}
export class BoxShadow {
    values = [];
    get pojo() {
        return this.values.map(i => i.pojo);
    }
    fromJSON(json) {
        if (Array.isArray(json)) {
            this.values = json.map(i => new BoxShadowValue(i));
        }
    }
    get cssStyle() {
        return this.values.length ? `box-shadow: ${this.values.map(i => i.toCss()).join(',')}` : '';
    }
}
export class DropShadow {
    color = new ColorWithOpacity({
        color: '#9e9e9f',
        opacity: 100
    });
    positionX = new CssSizeValue({ value: 1 });
    positionY = new CssSizeValue({ value: 2 });
    blur = new CssSizeValue({ value: 3 });
    borderOnly = true;
    enabled = false;
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get pojo() {
        return {
            color: this.color,
            positionX: this.positionX.pojo,
            positionY: this.positionY.pojo,
            blur: this.blur.pojo,
            borderOnly: this.borderOnly,
            enabled: this.enabled
        };
    }
    fromJSON(json) {
        this.enabled = json.enabled;
        this.borderOnly = typeof json.borderOnly == "boolean" ? json.borderOnly : true;
        json.color && this.color.fromJSON(json.color);
        json.positionX && this.positionX.fromJSON(json.positionX);
        json.positionY && this.positionY.fromJSON(json.positionY);
        json.blur && this.blur.fromJSON(json.blur);
    }
    toCssFilter() {
        if (!this.enabled || this.borderOnly)
            return '';
        let result = [
            this.positionX.toCss(),
            this.positionY.toCss(),
            this.blur.toCss(),
            this.color.toCss()
        ].filter(i => i).join(' ');
        return `drop-shadow(${result})`;
    }
    get cssStyle() {
        if (!this.enabled || !this.borderOnly)
            return '';
        return `box-shadow: ${this.positionX.toCss()} ${this.positionY.toCss()} ${this.blur.toCss()} ${this.color.toCss()}`;
    }
}
export class Sticky {
    sticky = false;
    top = new CssSizeValue({ auto: false, value: 0 });
    right = new CssSizeValue({ auto: true });
    bottom = new CssSizeValue({ auto: true });
    left = new CssSizeValue({ auto: true });
    get cssStyle() {
        const result = [];
        if (this.sticky) {
            result.push(`position: sticky`);
            result.push(`will-change: transform`);
            result.push(`z-index: 1023`);
            if (!this.top.auto) {
                result.push(`top: ${this.top.value}${this.top.unit}`);
            }
            if (!this.right.auto) {
                result.push(`right: ${this.right.value}${this.right.unit}`);
            }
            if (!this.bottom.auto) {
                result.push(`bottom: ${this.bottom.value}${this.bottom.unit}`);
            }
            if (!this.left.auto) {
                result.push(`left: ${this.left.value}${this.left.unit}`);
            }
        }
        return result.join(' ;');
    }
    get pojo() {
        return {
            sticky: this.sticky,
            top: this.top.pojo,
            right: this.right.pojo,
            bottom: this.bottom.pojo,
            left: this.left.pojo
        };
    }
    fromJSON(json) {
        this.sticky = json.sticky;
        json.top && this.top.fromJSON(json.top);
        json.right && this.right.fromJSON(json.right);
        json.bottom && this.bottom.fromJSON(json.bottom);
        json.left && this.left.fromJSON(json.left);
    }
}
export var VoiceType;
(function (VoiceType) {
    VoiceType["Male1"] = "male-1";
    VoiceType["Male2"] = "male-2";
    VoiceType["Female1"] = "female-1";
    VoiceType["Female2"] = "female-2";
})(VoiceType || (VoiceType = {}));
export var VoiceLanguage;
(function (VoiceLanguage) {
    VoiceLanguage["AUTO"] = "";
    VoiceLanguage["ENGLISH"] = "eng";
    VoiceLanguage["SPANISH"] = "spa";
    VoiceLanguage["GERMAN"] = "ger";
})(VoiceLanguage || (VoiceLanguage = {}));
export class TextToSpeech {
    enabled = true;
    language = VoiceLanguage.AUTO;
    voiceType = VoiceType.Male1;
    rate = 1;
    pitch = 1;
    get pojo() {
        return {
            enabled: this.enabled,
            language: this.language,
            voiceType: this.voiceType,
            rate: this.rate,
            pitch: this.pitch
        };
    }
    fromJSON(json) {
        this.enabled = json.enabled;
        this.language = json.language,
            this.voiceType = json.voiceType;
        this.rate = json.rate;
        this.pitch = json.pitch;
    }
}
