declare var OverlayScrollbarsGlobal: any

const Default = {
  scrollbarTheme: "os-theme-dark",
  scrollbarAutoHide: "leave",
  scrollbarClickScroll: true,
}

export function initScrollBox(ele: HTMLElement) {
  if (
    !!ele &&
    typeof OverlayScrollbarsGlobal?.OverlayScrollbars !== "undefined"
  ) {
    OverlayScrollbarsGlobal.OverlayScrollbars(ele, {
      scrollbars: {
        theme: Default.scrollbarTheme,
        autoHide: Default.scrollbarAutoHide,
        clickScroll: Default.scrollbarClickScroll,
      },
    })
  }
}

export function initDrageToMove(ele: HTMLElement) {
  if (!!ele) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }

    const mouseDownHandler = function (e: any) {
      if (e.target !== ele) {
        return
      }

      ele.style.cursor = 'grabbing'
      ele.style.userSelect = 'none'

      pos = {
        left: ele.parentElement.scrollLeft,
        top: ele.parentElement.scrollTop,
        // Get the current mouse position
        x: e.clientX,
        y: e.clientY,
      };

      document.addEventListener('mousemove', mouseMoveHandler)
      document.addEventListener('mouseup', mouseUpHandler)
    }

    const mouseMoveHandler = function (e: any) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x
      const dy = e.clientY - pos.y

      // Scroll the element
      ele.parentElement.scrollTop = pos.top - dy
      ele.parentElement.scrollLeft = pos.left - dx
    };

    const mouseUpHandler = function () {
      ele.style.cursor = 'auto'
      ele.style.removeProperty('user-select')

      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    };

    // Attach the handler
    ele.addEventListener('mousedown', mouseDownHandler)
  }
}