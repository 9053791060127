class API {
    params;
    queryParams;
    _(str) {
        let result = str;
        const params = { ...requestParams || {}, ...this.params };
        for (let k in params) {
            result = result.replaceAll(`:${k}`, params[k]);
        }
        const query = [];
        const queryParams = this.queryParams || {};
        for (let k in queryParams) {
            query.push(`${k}=${encodeURI(queryParams[k])}`);
        }
        this.params = {};
        this.queryParams = {};
        return query.length ? result + '?' + query.join('&') : result;
    }
    setParams(params) {
        this.params = { ...params };
        return this;
    }
    setQueryParams(params) {
        this.queryParams = { ...params };
        return this;
    }
    get CAMPAIGN_PAGE_URI() {
        return this._('/campaigns');
    }
    get CAMPAIGN_CREATE_URI() {
        return this._('/campaigns/create');
    }
    get CAMPAIGN_EDIT_URI() {
        return this._('/campaigns/:campaign_id');
    }
    get CAMPAIGN_JSON_URI() {
        return this._('/campaigns/:campaign_id/json');
    }
    get CAMPAIGN_ARCHIVE_URI() {
        return this._('/campaigns/:campaign_id/archive');
    }
    get CAMPAIGN_STATISTICS_URI() {
        return this._('/campaigns/:campaign_id/statistics');
    }
    get CAMPAIGN_BLOCKED_LIST_URI() {
        return this._('/campaigns/:campaign_id/blocked-list');
    }
    get CAMPAIGN_ACTIVE_PAGES_URI() {
        return this._('/campaigns/:campaign_id/active-pages');
    }
    get CAMPAIGN_ACTIVE_DOMAINS_AND_PAGES_URI() {
        return this._('/campaigns/:campaign_id/active-domains-and-pages');
    }
    get PAGE_CREATE_URI() {
        return this._('/campaigns/:campaign_id/pages/create');
    }
    get PAGE_EDIT_URI() {
        return this._('/campaigns/:campaign_id/pages/edit');
    }
    get PAGE_BUILDER_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/builder');
    }
    get PAGE_JSON_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/json');
    }
    get PAGE_URLS_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/urls');
    }
    get PAGE_ARCHIVE_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/archive');
    }
    get PAGE_PUBLISH_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/publish');
    }
    get PAGE_EDIT_NAME_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/name');
    }
    get PAGE_EDIT_URL_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/url');
    }
    get PAGE_EDIT_TOTAL_PROTECTION_URI() {
        return this._('/campaigns/:campaign_id/pages/:page_id/total-protection');
    }
    get DOMAIN_CREATE_OR_UPDATE() {
        return this._('/campaigns/:campaign_id/domains');
    }
    get DOMAIN_JSON() {
        return this._('/campaigns/:campaign_id/domains/:domain_id');
    }
    get DOMAIN_ARCHIVE() {
        return this._('/campaigns/:campaign_id/domains/:domain_id/archive');
    }
    get DOMAIN_PUBLICATION() {
        return this._('/campaigns/:campaign_id/domains/:domain_id/publish');
    }
    get RESOURCE_CREATE_OR_UPDATE() {
        return this._('/campaigns/:campaign_id/resources');
    }
    get RESOURCE_JSON() {
        return this._('/campaigns/:campaign_id/resources/:resource_id');
    }
    get RESOURCE_ARCHIVE() {
        return this._('/campaigns/:campaign_id/resources/:resource_id/archive');
    }
    get RESOURCE_PUBLICATION() {
        return this._('/campaigns/:campaign_id/resources/:resource_id/publish');
    }
    get USER_JSON() {
        return this._('/admin/people/:user_id');
    }
    get USER_CREATE_OR_UPDATE() {
        return this._('/admin/people');
    }
    get USER_BLOCK() {
        return this._('/admin/people');
    }
    get DATA_PLAN() {
        return this._('/admin/people/:user_id/data-plan');
    }
}
export const apiEndpoints = new API();
export default apiEndpoints;
