import { compose } from "#page-builder/compose"
import { nextTick } from "#page-builder/nextTick"
import { OptionalOf } from "#page-builder/optional-of"
import { makeid, makeid10 } from "#page-builder/utils"
import { Button, ButtonStyle, ButtonType } from "#page-builder/types/buttons"
import { withImageCropper } from "#page-builder/utils/image-cropper"

declare var bootstrap: any
declare class LeaderLine {}
declare class HTMLElement {}

const LEADER_LINE_SIZE = 2

function makeNodeID() {
  return makeid(10)
}

enum NodeType {
  start = 0,
  text = 1,
  image = 2,
  voice = 3,
  video = 4,
  file = 5,
  cta = 10,
  quiz = 20,
  end = 100
}

class Node {
  public uuid: string = ''
  public type: NodeType = NodeType.text
  public content: string = ''
  public x: number = 0
  public y: number = 0
  // Dùng để nối node hiện tại đến "nextNode"
  public leaderLine: Function|null = null
  // Node kế tiếp
  public nextNode: Node|null = null
  // Khi LiveChat nạp từ JSON sẽ mất nextNode
  // => lưu lại để xử lý nextNode sau đó
  public _nextNodeUuid: string = ''
  //
  public _el: Function|null = null
  //
  constructor(
    meta: OptionalOf<Node> = {},
  ) {
    const defaultOptions = {
      uuid: '',
      type: NodeType.text,
      content: '',
      x: 0,
      y: 0,
      _nextNodeUuid: ''
    }
    meta = { ...defaultOptions, ...meta }
    //
    this.uuid = meta.uuid || makeNodeID()
    this.type = meta.type!
    this.content = meta.content!
    this.x = meta.x!
    this.y = meta.y!
    //@ts-ignore
    this._nextNodeUuid = meta.nextNode || meta._nextNodeUuid
  }

  public get isStart() {
    return this.type == NodeType.start
  }

  public get isEnd() {
    return this.type == NodeType.end
  }

  public get isText() {
    return this.type == NodeType.text
  }

  public get isImage() {
    return this.type == NodeType.image
  }

  public get isVoice() {
    return this.type == NodeType.voice
  }

  public get isVideo() {
    return this.type == NodeType.video
  }

  public get isFile() {
    return this.type == NodeType.file
  }

  public get isCta() {
    return this.type == NodeType.cta
  }

  public link(node: Node, board: HTMLElement): void {
    if (this.uuid != node.uuid) {
      this.nextNode = node
      //
      if (this.leaderLine) {
        //@ts-ignore
        this.leaderLine().end = node._el()
      } else {
        //@ts-ignore
        const ll =  new LeaderLine(this._el(), node._el(), { size: LEADER_LINE_SIZE, parent: board })
        this.leaderLine = () => ll
      }
    }
  }

  public destroyLeaderLines() {
    if (this.leaderLine) {
      this.leaderLine().remove()
      // Ở LiveChat.deleteNode xử lý cả việc destroyLeaderLines
      // của các node trỏ đến node bị delete. Dẫn đến khi clear toàn bộ
      // các node thì việc destroyLeaderLines có thể xảy ra 2 lần.
      // Ví dụ: A trỏ đến B. Nếu B bị xóa trước thì leaderlines của A cũng
      // bị xóa đi khi B được xóa. Đến khi xóa A sẽ tiếp tục gọi destroyLeaderLines
      // => Set thành null tránh bị xóa 2 lần.
      this.leaderLine = null
    }
  }

  public get pojo(): any {
    const result = {
      uuid: this.uuid,
      type: this.type,
      content: this.content,
      x: this.x,
      y: this.y,
      nextNode: this.nextNode?.uuid || this._nextNodeUuid
    }
    return result
  }

  static fromJSON(json: any): Node {
    //@ts-ignore
    let result = new NodeClassMap[NodeType[json.type]](json)
    return result
  }
}

class StartNode extends Node {
  constructor(meta: OptionalOf<StartNode> = {}) {
    super({ ...meta, type: NodeType.start })
  }
}

class TextNode extends Node {
  constructor(meta: OptionalOf<Node> = {}) {
    super({ ...meta, type: NodeType.text })
  }
}

class ImageNode extends compose(Node, withImageCropper('content', Node)) {
  constructor(meta: OptionalOf<Node> = {}) {
    super({ ...meta, type: NodeType.image })
  }
}

class VoiceNode extends Node {
  constructor(meta: OptionalOf<Node> = {}) {
    super({ ...meta, type: NodeType.voice })
  }
}

class VideoNode extends Node {
  constructor(meta: OptionalOf<Node> = {}) {
    super({ ...meta, type: NodeType.video })
  }
}

class FileNode extends Node {
  constructor(meta: OptionalOf<Node> = {}) {
    super({ ...meta, type: NodeType.file })
  }
}

class CallToActionButton extends Button {
  // Dùng để nối node hiện tại đến "nextNode"
  public leaderLine: Function|null = null
  // Node kế tiếp
  // Không gán giá trị '' tại đây vì code do typescript sẽ chuyển vào sau cùng của constructor => nextNode sẽ bị xóa dữ liệu
  public nextNode: string
  //
  public _el: Function|null = null
  //
  constructor(
    options: OptionalOf<CallToActionButton> = {}
  ) {
    super(options)
    this.nextNode = options.nextNode || ''
  }
  //
  public get pojo() {
    let result = super.pojo as any

    return {
      ...result,
      nextNode: this.nextNode
    }
  }
  //
  public merge(that: CallToActionButton): this {
    //!! Nếu thấy "Edit me" => node mặc định
    super.merge(that)
    this.nextNode = that.nextNode
    return this
  }
  //
  public link(node: Node, board: HTMLElement): void {
    this.nextNode = node.uuid
    //
    if (this.leaderLine) {
      //@ts-ignore
      this.leaderLine().end = node._el()
    } else {
      //@ts-ignore
      const ll = new LeaderLine(this._el(), node._el(), { size: LEADER_LINE_SIZE, startSocket: 'right', parent: board })
      this.leaderLine = () => ll
    }
  }

  public destroyLeaderLines() {
    if (this.leaderLine) {
      this.leaderLine().remove()
      // Đánh dấu đã xóa
      this.leaderLine = null
    }
  }
}

class CtaNode extends Node {
  public buttons: CallToActionButton[] = [
    new CallToActionButton({
      text: 'Edit me',
      type: ButtonType.Continue
    })
  ]

  public editing: boolean = false
  public editButton: CallToActionButton|null = null
  public editStyle: ButtonStyle = new ButtonStyle()
  public applyToAll: boolean = false

  constructor(meta: OptionalOf<CtaNode> = {}) {
    super({ ...meta, type: NodeType.cta })
    //
    if (meta.buttons) {
      let buttons = []
      for (let i of meta.buttons) {
        const b = new CallToActionButton(i as any)
        buttons.push(b)
      }
      //
      this.buttons = buttons
    }
  }

  public updateLeaderLines(uuid: string = '') {
    for (let i of this.buttons) {
      if (!uuid || i.nextNode == uuid) {
        if (i.leaderLine) {
          i.leaderLine().position()
        }
      }
    }
  }

  public find(uuid: string): CallToActionButton|undefined {
    for (let i of this.buttons) {
      if (i.uuid == uuid) {
        return i
      }
    }
  }

  public move(btn: CallToActionButton, index: number) {
    if (index >= 0 && index < this.buttons.length) {
      //
      const array = this.buttons.filter(i => i.uuid != btn.uuid)
      //
      array.splice(index, 0, btn)
      //
      this.buttons = array
    }
  }

  public moveUp(btn: CallToActionButton) {
    const index = this.buttons.findIndex(i => i.uuid == btn.uuid)
    this.move(btn, index - 1)
  }

  public moveDown(btn: CallToActionButton) {
    const index = this.buttons.findIndex(i => i.uuid == btn.uuid)
    this.move(btn, index + 1)
  }

  public newButton() {
    this.editing = true
    this.editButton = new CallToActionButton()
  }

  public edit(button: CallToActionButton) {
    this.editing = true
    this.editButton = new CallToActionButton(button as any)
  }

  public delete(button: CallToActionButton) {
    this.buttons = this.buttons.filter(i => i.uuid != button.uuid)
  }

  public cancel() {
    this.editing = false
    const self = this
    queueMicrotask(function() {
      self.editButton = null
    })
    
  }

  public save() {
    /**
     * Do x-if có thể sinh lỗi ở <template x-if="node.editing && node.editButton">
     * nên chèn cái editing vào để điều kiện if sai => remove khỏi DOM tree
     * Sau đó mới set editButton = null, tránh lỗi node.editButton is null ở các x-model bên trong
     */
    this.editing = false
    const self = this
    queueMicrotask(function() {
      if (self.editButton && self.editButton.validate()) {
        try {
          for (let i of self.buttons) {
            if (i.uuid == self.editButton.uuid) {
              i.merge(self.editButton)
              return
            }
          }
          self.buttons.push(self.editButton)
        } finally {
          self.editButton = null
        }
      }
    })
  }

  public destroyLeaderLines() {
    super.destroyLeaderLines()
    for (let i of this.buttons) {
      i.destroyLeaderLines()
    }
  }

  public get pojo(): any {
    let result = super.pojo
    //
    result.buttons = []
    for (let i of this.buttons) {
      result.buttons.push(i.pojo)
    }
    return result
  }

  public showButtonStyleModal(modal: HTMLElement) {
    if (this.editButton)
      this.editStyle.fromJSON(this.editButton.style.pojo as any)
    bootstrap.Modal.getOrCreateInstance(modal, {}).show()
  }

  public applyStyle() {
    if (this.applyToAll) {
      for (let i of this.buttons) {
        i.style.fromJSON(this.editStyle)
      }
    }
    //
    if (this.editButton)
      this.editButton.style.fromJSON(this.editStyle)
  }
}

class EndNode extends Node {
  constructor(meta: OptionalOf<Node> = {}) {
    super({ ...meta, type: NodeType.end })
  }
}

type NodeClassKeys = keyof typeof NodeType;

const NodeClassMap: { [type in NodeClassKeys]: typeof Node } = {
  start: StartNode,
  text : TextNode,
  image: ImageNode,
  voice: VoiceNode,
  video: VideoNode,
  file : FileNode,
  cta  : CtaNode,
  quiz : EndNode,
  end  : EndNode,
}

interface NewNodeOptions {
  x?: number,
  y?: number,
  finishAddingNewNode?: boolean
}

export class LiveChat {
  // Trường hợp có nhiều LiveChat hoặc Survey => dùng cho editor phân biệt
  public uuid: string = makeid10()
  //@ts-ignore
  private _zoom = 1
  //@ts-ignore
  private _newNode: Node|null = null
  //@ts-ignore
  private _movingNode: Node|null = null
  //@ts-ignore
  private _movingBoard: boolean = false
  //
  public _el: Function|null = null
  //
  public _updateLeaderLinesExTimer = null
  //
  public nodes: Node[] = [
    new StartNode({ x: 200, y: 50 }),
    // new EndNode({ x: 202, y: 500 }),
  ]
  //
  constructor(
    public isSurvey: boolean = false
  ) {

  }
  //
  public isMovingNode(node: any): boolean {
    return node?.uuid === this._movingNode?.uuid
  }
  //
  public getNodeByID(id: string): Node|CallToActionButton|undefined {
    const [n, b] = id.split('/') // n: node, b: button
    //
    const node = this.nodes.find(i => i.uuid == n)
    //
    if (node && node instanceof CtaNode) {
      const btn = node.buttons.find(i => i.uuid == b)
      return btn ? btn : node
    }
    //
    return node
  }
  //
  public linkNodes(srcID: string, destID: string, board: HTMLElement) {
    const src = this.getNodeByID(srcID)
    const dest = this.getNodeByID(destID)
    //
    nextTick(function () {
      if (src && dest) {
        //@ts-ignore Dest chắc chắn là Node do sự kiện Drop ở trên Node mà thôi
        src.link(dest, board)
      }
    })
  }
  //
  public updateLeaderLines() {
    if (this._movingNode) {
      if (this._movingNode.leaderLine) {
        this._movingNode.leaderLine().position()
      }
      //
      if (this._movingNode.isCta) {
        (this._movingNode as any).updateLeaderLines()
      }
      //
      for (let i of this.nodes) {
        if (i.nextNode?.uuid == this._movingNode.uuid) {

          i.leaderLine!().position()
        }
        //
        if (i.isCta) {
          (i as CtaNode).updateLeaderLines(this._movingNode.uuid)
        }
      }
    }
  }
  //
  public updateLeaderLinesEx() {
    const self = this
    if (self._updateLeaderLinesExTimer) {
      clearTimeout(self._updateLeaderLinesExTimer)
    }
    //@ts-ignore
    self._updateLeaderLinesExTimer = setTimeout(function() {
      for (let i of self.nodes) {
        if (i.leaderLine) {
          i.leaderLine().position()
        }
        if (i.isCta) {
          for (let j of (i as CtaNode).buttons) {
            if (j.leaderLine) {
              j.leaderLine().position()
            }
          }
        }
      }
      //@ts-ignore
      self._updateLeaderLinesExTimer = 0
      //
      self.adjustBoardSize()
    }, 25);
  }
  //
  public newTextNode(options: NewNodeOptions) {
    this._newNode = new TextNode(options)
    if (options?.finishAddingNewNode) {
      this.finishAddingNewNode()
    }
  }
  //
  public newImageNode(options: NewNodeOptions) {
    this._newNode = new ImageNode(options)
    if (options?.finishAddingNewNode) {
      this.finishAddingNewNode()
    }
  }
  //
  public newVoiceNode(options: NewNodeOptions) {
    this._newNode = new VoiceNode(options)
    if (options?.finishAddingNewNode) {
      this.finishAddingNewNode()
    }
  }
  //
  public newVideoNode(options: NewNodeOptions) {
    this._newNode = new VideoNode(options)
    if (options?.finishAddingNewNode) {
      this.finishAddingNewNode()
    }
  }
  //
  public newFileNode(options: NewNodeOptions) {
    this._newNode = new FileNode(options)
    if (options?.finishAddingNewNode) {
      this.finishAddingNewNode()
    }
  }
  //
  public newCtaNode(options: NewNodeOptions) {
    this._newNode = new CtaNode(options)
    if (options?.finishAddingNewNode) {
      this.finishAddingNewNode()
    }
  }
  //
  public finishAddingNewNode() {
    if (this._newNode) {
      this.nodes.push(this._newNode)
      this._newNode = null
    }
  }
  //
  public reset() {
    //
    for (let i of this.nodes) {
      this.deleteNode(i)
    }
    //
    this.nodes = [
      new StartNode({ x: 200, y: 50 }),
      // new EndNode({ x: 202, y: 500 }),
    ]
    //
    this._movingNode = null
    this._newNode = null
  }
  //
  public deleteNode(node: Node) {
    this.nodes = this.nodes.filter(i => i.uuid != node.uuid)
    //
    node.destroyLeaderLines()
    //
    for (let i of this.nodes) {
      if (i.nextNode?.uuid == node.uuid) {
        i.destroyLeaderLines()
      }
    }
  }
  //
  public saveToJSON() {
    let nodes = []
    for (let i of this.nodes) {
      nodes.push(i.pojo)
    }
    return {
      isSurvey: this.isSurvey,
      uuid: this.uuid,
      nodes,
    }

  }
  //
  public saveToStr() {
    const result = JSON.stringify(this.saveToJSON())
    return result
  }
  //
  public loadFromJson(json: OptionalOf<LiveChat>, checkUUID: boolean = false, board: HTMLElement|null = null) {
    const self = this

    function loadNodes() {
      const nodes: Node[] = []
      if (json.nodes && Array.isArray(json.nodes)) {
        for (let i of json.nodes) {
          nodes.push(Node.fromJSON(i))
        }
      }
      self.nodes = nodes
    }

    if (checkUUID && this.uuid != json.uuid) return
    // Làm rỗng để hiển thị loading indicator
    while (this.nodes.length) {
      this.deleteNode(this.nodes.at(0)!)
    }
    // Nạp các dữ liệu khác
    this.isSurvey = !!json.isSurvey
    this.uuid = json.uuid!
    this._movingNode = null
    this._newNode = null
    this._zoom = 1
    //
    if (board) {
      // Nếu open live chat editor => đóng lại => mở lại (ko có thay đổi dữ liệu)
      // thì các node đều có _el = undefined
      // Lý do: x-for thấy các node này ko đổi nội dung nên ko gọi x-init để gán _el
      // Mà các _el này bị undefined do nạp lại từ json bên ngoài!
      // => làm this.nodes = [] để hiển thị loading indicator, rồi sau đó nạp nodes
      setTimeout(function() {
        loadNodes()
        self.fixLeaderLines(json, board)
      }, 150) // Modal fade transition
    } else {
      loadNodes()
    }
  }
  //
  public loadFromStr(str: string, checkUUID: boolean = false, board: HTMLElement|null = null) {
    const json = JSON.parse(str)
    this.loadFromJson(json, checkUUID, board)
  }
  // Sau khi loadFromJSON, cần gọi hàm này ở file .edge để hiển thị các leaderLine
  // Không đưa hàm này vào loadFromJSON vì ở page-producer không dùng đến (ko có HTMLElement...)
  public fixLeaderLines(json: any, board: HTMLElement) {
    if (json.nodes && Array.isArray(json.nodes)) {
      for (let i of json.nodes) {
        if (i.nextNode) {
          this.linkNodes(i.uuid, i.nextNode, board)
        }
        if (i.type == NodeType.cta) {
          const self = this
          // Chờ cho việc nạp node hoàn thành để các btn có _el
          setTimeout(function() {
            const node = self.getNodeByID(i.uuid) as CtaNode
            if (node) {
              for (let j of i.buttons) {
                const btn = node.find(j.uuid)
                const target = self.getNodeByID(j.nextNode) as Node
                if (btn && target) {
                  btn.link(target, board)
                }
              }
            }
          }, 10)
        }
      }
    }
  }
  //
  public adjustBoardSize() {
    let [x, y] = [0, 0]
    for (let i of this.nodes) {
      const rect = i._el!().getBoundingClientRect()
      x = i.x + rect.width > x ? i.x + rect.width : x
      y = i.y + rect.height > y ? i.y + rect.height : y
    }
    //
    this._el!().style.width = `${x + 100}px`
    this._el!().style.height = `${y + 100}px`
  }
  //
  public zoomIn() {
    this._zoom += 0.25
    this.updateLeaderLines()
  }
  //
  public zoomOut() {
    this._zoom -= 0.25
    this.updateLeaderLines()
  }
  //
  public buildMessagesJson() {
    const nodes = this.saveToJSON().nodes
    for (let i of nodes) {
      if (i.type == NodeType.cta) {
        for (let button of i.buttons) {
          // Trường hợp gọi số điện thoại
          if (button.type == ButtonType.CallANumber) {
            button.value = 'tel:' + button.value
          }
          // Xử lý buttonStyle
          const style: ButtonStyle = new ButtonStyle().fromJSON(button.style)
          button.btnStyle = style.btnStyle
          button.iconStyle = style.iconStyle
          button.iconFinalSvg = style.iconFinalSvg
          //
          delete button.style
        }
      }
    }
    return nodes
  }
}