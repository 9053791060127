export class TotalProtectionOptions {
    blockTabletVisitors;
    blockDesktopVisitors;
    blockVPNProxies;
    blockSearchEngines;
    allowFacebookBotTraffic;
    allowGoogleBotTraffic;
    smartBlocking;
    allowedReferrers;
    constructor(blockTabletVisitors = false, blockDesktopVisitors = true, blockVPNProxies = true, blockSearchEngines = true, allowFacebookBotTraffic = true, allowGoogleBotTraffic = true, smartBlocking = true, allowedReferrers = []) {
        this.blockTabletVisitors = blockTabletVisitors;
        this.blockDesktopVisitors = blockDesktopVisitors;
        this.blockVPNProxies = blockVPNProxies;
        this.blockSearchEngines = blockSearchEngines;
        this.allowFacebookBotTraffic = allowFacebookBotTraffic;
        this.allowGoogleBotTraffic = allowGoogleBotTraffic;
        this.smartBlocking = smartBlocking;
        this.allowedReferrers = allowedReferrers;
    }
}
