import { onDOMContentLoaded } from '../onDOMContentLoaded'

onDOMContentLoaded(() => {
  let inputs = document.querySelectorAll('input[type="number"]');
  inputs.forEach(item => {
    item.addEventListener('keydown', (e: KeyboardEvent) => {
      const key = e.key;
      if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete' && key !== 'ArrowLeft' && key !== 'ArrowRight' && key !== 'ArrowUp' && key !== 'ArrowDown') {
        e.preventDefault();
      }
    });
  })
})