import { compose } from "#page-builder/compose";
import { withImageCropper } from "#page-builder/utils/image-cropper";
import { LiveChat } from "#page-builder/live-chat";
import { getMessageBubbleStyle, MessageBubbleStyle } from "#page-builder/types/message-bubble-style";
import { getSurveyStyle, SurveyStyle } from "#page-builder/types/survey-style";
import { Button, ButtonStyle, BUTTON_WIDTH_CSS_VAR, ButtonType, ButtonStyleManager } from "#page-builder/types/buttons";
import { makeid10 } from "#page-builder/utils";
import { Background, BgStyle, Borders, BoxShadow, ColorWithOpacity, CssSizeValue, DeviceTypes, Dimension, DropShadow, GridSizing, HorizontalAlignment, Margin, Metadata, Padding, Protection, Sticky, TextToSpeech, Typography, TypographyPerDevice, VerticalAlignment, Visibility } from "#page-builder/types/common";
import { DialogStyle } from "#page-builder/types/dialog-style";
export const THE_END_OF = -1;
export const THE_BEGINNING_OF = -2;
export const RIGHT_BEFORE = -3;
export const RIGHT_AFTER = -4;
function isDesigner() {
    return typeof __isDesigner != 'undefined';
}
export var ElementType;
(function (ElementType) {
    ElementType[ElementType["Base"] = 0] = "Base";
    ElementType[ElementType["Wysiwyg"] = 1] = "Wysiwyg";
    ElementType[ElementType["ButtonGroup"] = 2] = "ButtonGroup";
    ElementType[ElementType["Image"] = 3] = "Image";
    ElementType[ElementType["Video"] = 4] = "Video";
    ElementType[ElementType["Carousel"] = 5] = "Carousel";
    ElementType[ElementType["Ticker"] = 6] = "Ticker";
    ElementType[ElementType["OnlineIndicator"] = 7] = "OnlineIndicator";
    ElementType[ElementType["OnlineIndicator2"] = 8] = "OnlineIndicator2";
    ElementType[ElementType["Form"] = 9] = "Form";
    ElementType[ElementType["SpinWheel"] = 10] = "SpinWheel";
    ElementType[ElementType["LiveChat"] = 50] = "LiveChat";
    ElementType[ElementType["Quiz"] = 60] = "Quiz";
    ElementType[ElementType["Survey"] = 70] = "Survey";
    ElementType[ElementType["Custom"] = 100] = "Custom";
    ElementType[ElementType["Block"] = 1000] = "Block";
    ElementType[ElementType["Section"] = 10000] = "Section";
})(ElementType || (ElementType = {}));
let ElementClassMap = {
    Base: null,
    Wysiwyg: null,
    ButtonGroup: null,
    Image: null,
    Video: null,
    Carousel: null,
    Ticker: null,
    OnlineIndicator: null,
    OnlineIndicator2: null,
    Form: null,
    SpinWheel: null,
    LiveChat: null,
    Quiz: null,
    Survey: null,
    Custom: null,
    Block: null,
    Section: null
};
export class BaseElement {
    uuid = makeid10();
    type = ElementType.Base;
    name = '';
    verticalAlignment = VerticalAlignment.Start;
    horizontalAlignment = HorizontalAlignment.Center;
    meta = new Metadata();
    background = new Background();
    dimension = new Dimension();
    margin = new Margin();
    padding = new Padding();
    borders = new Borders();
    visibility = new Visibility();
    boxShadow = new BoxShadow();
    dropShadow = new DropShadow();
    typography = new Typography();
    protection = Protection.None;
    placeOnSingleRow = true;
    isElement = true;
    isButtonGroup = false;
    isWysiwyg = false;
    isImage = false;
    isTicker = false;
    isSpinWheel = false;
    isOnlineIndicator = false;
    isOnlineIndicator2 = false;
    isHtmlForm = false;
    isLiveChat = false;
    isSurvey = false;
    contextMenu = {
        show: false,
        position: {
            top: 0,
            right: 0
        }
    };
    constructor(options = {}) {
        const self = this;
        queueMicrotask(function () {
            self.fromJSON(options);
        });
    }
    static create(options) {
        let result = new ElementClassMap[ElementType[options.type]](options);
        return result;
    }
    clone() {
        return BaseElement.create(this.pojo);
    }
    get basicHtml() {
        return '[ placeholder ]';
    }
    get typeName() {
        switch (parseInt(this.type.toString())) {
            case ElementType.ButtonGroup: return 'button-group';
            case ElementType.Carousel: return 'carousel';
            case ElementType.Image: return 'image';
            case ElementType.Ticker: return 'ticker';
            case ElementType.OnlineIndicator: return 'online-indicator';
            case ElementType.OnlineIndicator2: return 'online-indicator2';
            case ElementType.Form: return 'form';
            case ElementType.LiveChat: return 'live-chat';
            case ElementType.Quiz: return 'quiz';
            case ElementType.Survey: return 'survey';
            case ElementType.Wysiwyg: return 'wysiwyg';
            case ElementType.Video: return 'video';
            case ElementType.Custom: return 'custom';
        }
        return '';
    }
    get pojo() {
        return {
            uuid: this.uuid,
            type: this.type,
            name: this.name,
            meta: this.meta.pojo,
            background: this.background.pojo,
            dimension: this.dimension.pojo,
            margin: this.margin.pojo,
            padding: this.padding.pojo,
            borders: this.borders.pojo,
            boxShadow: this.boxShadow,
            dropShadow: this.dropShadow,
            visibility: this.visibility.pojo,
            typography: this.typography.pojo,
            verticalAlignment: this.verticalAlignment,
            horizontalAlignment: this.horizontalAlignment,
            protection: this.protection,
            placeOnSingleRow: this.placeOnSingleRow
        };
    }
    get elementID() {
        return this.meta.id || `${this.typeName}-${this.uuid}`;
    }
    getHorizontalAlignmentCss(flexBox = true) {
        let result = '';
        switch (this.horizontalAlignment) {
            case HorizontalAlignment.Center:
                result = this.placeOnSingleRow && flexBox ? 'justify-content: center;' : 'margin: 0 auto;';
                break;
            case HorizontalAlignment.End:
                result = this.placeOnSingleRow && flexBox ? 'justify-content: end;' : 'margin: 0 0 0 auto;';
                break;
            case HorizontalAlignment.Start:
                result = this.placeOnSingleRow && flexBox ? 'justify-content: start;' : 'margin: 0 0 0 0;';
                break;
        }
        return result;
    }
    get protectionClass() {
        switch (this.protection) {
            case Protection.HideFromBlockedVisitors: return 'hfbv';
            case Protection.ShowToBlockedVisitors: return 'stbv';
        }
        return '';
    }
    get wrapperCssStyleObject() {
        let verticalAlignment = '';
        if (this.verticalAlignment) {
            verticalAlignment = `align-self: ${this.verticalAlignment}`;
        }
        const result = {
            dimension: this.dimension.cssStyle,
            margin: this.getHorizontalAlignmentCss(),
            verticalAlignment,
            placeOnSingleRow: this.placeOnSingleRow ? `display: flex; flex-basis: 100%;` : '',
        };
        return result;
    }
    get wrapperCssStyle() {
        return Object.values(this.wrapperCssStyleObject).filter(i => i).join(';');
    }
    get wrapperCssClassObject() {
        return {
            protection: this.protectionClass
        };
    }
    get wrapperCssClass() {
        return Object.values(this.wrapperCssClassObject).filter(i => i).join(' ');
    }
    get filters() {
        const filters = [
            this.dropShadow.toCssFilter()
        ].filter(i => i);
        return filters.length ? `filter: ${filters.join(',')}` : '';
    }
    get cssStyleObject() {
        const result = {
            filter: this.filters,
            background: this.background.cssStyle,
            borders: this.borders.cssStyle,
            boxShadow: this.boxShadow.cssStyle,
            shadow: this.dropShadow.cssStyle,
            margin: this.margin.cssStyle,
            padding: this.padding.cssStyle,
            typography: this.typography.cssStyle,
            width: this.placeOnSingleRow ? 'width: 100%' : ''
        };
        return result;
    }
    get cssStyle() {
        return Object.values(this.cssStyleObject).filter(i => i).join(';');
    }
    get cssClass() {
        return [this.meta.class, this.typeName, this.wrapperCssClass].filter(i => i).join(' ');
    }
    fromJSON(json) {
        this.uuid = json.uuid || makeid10();
        this.type = json.type || this.type || ElementType.Base;
        this.name = json.name || this.type;
        this.verticalAlignment = json.verticalAlignment || VerticalAlignment.Start;
        this.horizontalAlignment = json.horizontalAlignment || HorizontalAlignment.Center;
        this.protection = json.protection || Protection.None;
        this.placeOnSingleRow = json.placeOnSingleRow;
        json.meta && this.meta.fromJSON(json.meta);
        json.background && this.background.fromJSON(json.background);
        json.dimension && this.dimension.fromJSON(json.dimension);
        json.margin && this.margin.fromJSON(json.margin);
        json.padding && this.padding.fromJSON(json.padding);
        json.borders && this.borders.fromJSON(json.borders);
        json.visibility && this.visibility.fromJSON(json.visibility);
        json.boxShadow && this.boxShadow.fromJSON(json.boxShadow);
        json.dropShadow && this.dropShadow.fromJSON(json.dropShadow);
        json.typography && this.typography.fromJSON(json.typography);
        return this;
    }
    triggerDefaultAction(_$dispatch) {
    }
}
class ButtonGroupElement extends BaseElement {
    isButtonGroup = true;
    buttons = [];
    itemsPerRow = 1;
    equalWidth = true;
    gap = 0.25;
    btnWidth = new CssSizeValue({ auto: true });
    editButton = null;
    constructor(options = {}) {
        super(options);
        this.type = ElementType.ButtonGroup;
    }
    get cssStyleObject() {
        let result = {
            pbButtonWidth: `${BUTTON_WIDTH_CSS_VAR}: ${this.equalWidth ? '100%' : 'auto'}`,
            ...super.cssStyleObject
        };
        if (this.equalWidth && this.placeOnSingleRow) {
            result['width'] = 'width: 100%';
        }
        if (!this.buttons.length) {
            delete result.borders;
        }
        return result;
    }
    innerStyle(index) {
        if (this.itemsPerRow > 0 && this.equalWidth) {
            return `
        display: grid;
        gap: ${this.gap}em;
        grid-template-columns: repeat(${this.itemsPerRow}, 1fr);
        width: ${this.equalWidth ? this.btnWidth.toCss(this.itemsPerRow) : 'auto'};
        ${this.placeOnSingleRow ? this.getHorizontalAlignmentCss(false) : ''}
      `;
        }
        else {
            if (index > 0) {
                return `
          display: flex;
          flex-wrap: wrap;
          gap: ${this.gap}em;
          margin-top: ${this.gap}px;
        `;
            }
            else {
                return `
          display: flex;
          flex-wrap: wrap;
          gap: ${this.gap}em;
        `;
            }
        }
    }
    get buttonGroups() {
        let result = [];
        for (let i = 0; i < Math.ceil(this.buttons.length / this.itemsPerRow); i++) {
            let group = [];
            for (let j = 0; j < this.itemsPerRow; j++) {
                if (i * this.itemsPerRow + j < this.buttons.length) {
                    group.push(this.buttons[i * this.itemsPerRow + j]);
                }
            }
            result.push(group);
        }
        return result;
    }
    get pojo() {
        let buttons = [];
        for (let i of this.buttons) {
            buttons.push(i.pojo);
        }
        return {
            itemsPerRow: this.itemsPerRow,
            equalWidth: this.equalWidth,
            btnWidth: this.btnWidth.pojo,
            gap: this.gap,
            buttons,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.itemsPerRow = typeof json.itemsPerRow != 'undefined' ? json.itemsPerRow : 1;
        this.equalWidth = typeof json.equalWidth != 'undefined' ? json.equalWidth : true;
        this.gap = typeof json.equalWidth != 'undefined' ? parseInt(json.gap) : 0.25;
        json.btnWidth && this.btnWidth.fromJSON(json.btnWidth);
        if (json.buttons) {
            let buttons = [];
            for (let i of json.buttons) {
                buttons.push(new Button(i));
            }
            this.buttons = buttons;
        }
        return this;
    }
    move(btn, index) {
        if (index >= 0 && index < this.buttons.length) {
            const array = this.buttons.filter(i => i.uuid != btn.uuid);
            array.splice(index, 0, btn);
            this.buttons = array;
        }
    }
    moveUp(btn) {
        const index = this.buttons.findIndex(i => i.uuid == btn.uuid);
        this.move(btn, index - 1);
    }
    moveDown(btn) {
        const index = this.buttons.findIndex(i => i.uuid == btn.uuid);
        this.move(btn, index + 1);
    }
    newButton() {
        this.editButton = new Button({ type: ButtonType.GoToUrl });
    }
    edit(button) {
        this.editButton = new Button(button);
    }
    delete(button) {
        this.buttons = this.buttons.filter(i => i.uuid != button.uuid);
    }
    cancel() {
        this.editButton = null;
    }
    save() {
        if (this.editButton && this.editButton.validate()) {
            try {
                for (let i of this.buttons) {
                    if (i.uuid == this.editButton.uuid) {
                        i.merge(this.editButton);
                        return;
                    }
                }
                this.buttons.push(this.editButton);
            }
            finally {
                this.editButton = null;
            }
        }
    }
    applyStyle(btn, style) {
        if (this.editButton?.uuid == btn) {
            this.editButton.styleUUID = style;
        }
    }
}
class WysiwygElement extends BaseElement {
    static defaultContent = 'New text element';
    isWysiwyg = true;
    html = WysiwygElement.defaultContent;
    constructor(options = {}) {
        super(options);
        this.margin.left.auto = false;
        this.margin.left.unit = 'em';
        this.margin.left.value = 0.5;
        this.margin.right.auto = false;
        this.margin.right.unit = 'em';
        this.margin.right.value = 0.5;
        this.type = ElementType.Wysiwyg;
    }
    get pojo() {
        return {
            html: this.html,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.html = json.html || WysiwygElement.defaultContent;
        return this;
    }
    triggerDefaultAction($dispatch) {
        $dispatch('openRichtextEditor', this.html);
    }
}
class ImageElement extends compose(BaseElement, withImageCropper('image', BaseElement)) {
    isImage = true;
    image = '';
    rounded = 0;
    scale = 1;
    constructor(options = {}) {
        super(options);
        this.type = ElementType.Image;
    }
    get cssStyleObject() {
        let result = {
            rounded: `border-radius: ${this.rounded}px`,
            overflow: 'overflow: hidden',
            ...super.cssStyleObject
        };
        return result;
    }
    get scaleCss() {
        if (this.scale < 1) {
            return `zoom: ${this.scale}`;
        }
    }
    get pojo() {
        return {
            image: this.image,
            rounded: this.rounded,
            scale: this.scale,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.image = json.image || '';
        this.rounded = json.rounded || 0;
        this.scale = json.scale || 1;
        return this;
    }
    removeImage() {
        this.image = '';
    }
}
export var FormInputControlType;
(function (FormInputControlType) {
    FormInputControlType["email"] = "email";
    FormInputControlType["phone"] = "tel";
    FormInputControlType["text"] = "text";
    FormInputControlType["number"] = "number";
    FormInputControlType["choice"] = "choice";
    FormInputControlType["speech"] = "speech";
})(FormInputControlType || (FormInputControlType = {}));
export class FormInputControl {
    uuid = makeid10();
    type = FormInputControlType.text;
    id = '';
    name = 'Input';
    items = [];
    constructor(options = {}) {
        this.merge(options);
    }
    get pojo() {
        let items = [];
        for (let i of this.items) {
            items.push({ value: i.value });
        }
        const result = {
            uuid: this.uuid,
            id: this.id,
            type: this.type,
            name: this.name,
            items
        };
        return result;
    }
    merge(that) {
        this.uuid = that.uuid || makeid10();
        this.type = that.type || FormInputControlType.text;
        this.id = that.id || '';
        this.name = that.name || 'Input';
        this.items = [];
        if (typeof that.items != 'undefined' && Array.isArray(that.items)) {
            for (let i of that.items) {
                this.items.push({
                    value: i.value
                });
            }
        }
        return this;
    }
    validate() {
        return true;
    }
    deleteItem(index) {
        this.items.splice(index, 1);
    }
}
class HtmlFormElement extends BaseElement {
    isHtmlForm = true;
    dataCollectorEndpoint = '/form';
    inputs = [];
    submitButton = 'Submit';
    nextButton = 'Next';
    backButton = 'Back';
    thankyouMessage = '';
    splitInputsIntoMultiPage = false;
    alignCenter = false;
    textToSpeech = new TextToSpeech();
    editInput = null;
    _currentInput = -1;
    get currentInput() {
        if (this.editInput)
            return this.editInput;
        if (this._currentInput >= 0 && this._currentInput < this.inputs.length) {
            return this.inputs[this._currentInput];
        }
        else if (this.inputs.length) {
            return this.inputs[0];
        }
        return null;
    }
    constructor(options = {}) {
        super(options);
        this.type = ElementType.Form;
    }
    get cssStyleObject() {
        let result = {
            ...super.cssStyleObject
        };
        return result;
    }
    innerStyle(_index) {
        return ``;
    }
    get pojo() {
        let inputs = [];
        for (let i of this.inputs) {
            inputs.push(i.pojo);
        }
        return {
            submitButton: this.submitButton,
            nextButton: this.nextButton,
            backButton: this.backButton,
            dataCollectorEndpoint: this.dataCollectorEndpoint,
            thankyouMessage: this.thankyouMessage,
            splitInputsIntoMultiPage: this.splitInputsIntoMultiPage,
            alignCenter: this.alignCenter,
            inputs: inputs,
            textToSpeech: this.textToSpeech.pojo,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.submitButton = json.submitButton || 'Submit';
        this.nextButton = json.nextButton || 'Next';
        this.backButton = json.backButton || 'Back';
        this.dataCollectorEndpoint = json.dataCollectorEndpoint || '';
        this.thankyouMessage = json.thankyouMessage || 'Thank you for submitting. We will review your information and contacting you shortly!';
        this.splitInputsIntoMultiPage = typeof json.splitInputsIntoMultiPage != 'undefined' ? json.splitInputsIntoMultiPage : false;
        this.alignCenter = !!json.alignCenter;
        json.textToSpeech && this.textToSpeech.fromJSON(json.textToSpeech);
        if (json.inputs) {
            let inputs = [];
            for (let i of json.inputs) {
                inputs.push(new FormInputControl(i));
            }
            this.inputs = inputs;
        }
        return this;
    }
    move(input, index) {
        if (index >= 0 && index < this.inputs.length) {
            const array = this.inputs.filter(i => i.uuid != input.uuid);
            array.splice(index, 0, input);
            this.inputs = array;
        }
    }
    moveUp(input) {
        const index = this.inputs.findIndex(i => i.uuid == input.uuid);
        this.move(input, index - 1);
    }
    moveDown(input) {
        const index = this.inputs.findIndex(i => i.uuid == input.uuid);
        this.move(input, index + 1);
    }
    newInput() {
        this.editInput = new FormInputControl();
    }
    edit(input) {
        this.editInput = new FormInputControl(input);
    }
    delete(input) {
        this.inputs = this.inputs.filter(i => i.uuid != input.uuid);
    }
    cancel() {
        this.editInput = null;
    }
    save() {
        if (this.editInput && this.editInput.validate()) {
            try {
                for (let i of this.inputs) {
                    if (i.uuid == this.editInput.uuid) {
                        i.merge(this.editInput);
                        return;
                    }
                }
                this.inputs.push(this.editInput);
            }
            finally {
                this.editInput = null;
            }
        }
    }
    showNextInput() {
        if (this._currentInput < 1) {
            this._currentInput = 1;
        }
        else if (this._currentInput >= this.inputs.length - 1) {
            this._currentInput = this.inputs.length - 1;
        }
        else {
            this._currentInput = this._currentInput + 1;
        }
    }
    lastInputIsSpeech() {
        return this.inputs[this.inputs.length - 1].type == FormInputControlType.speech;
    }
}
class TickerElement extends BaseElement {
    static defaultContent = 'Your text here';
    isTicker = true;
    text = TickerElement.defaultContent;
    duration = 10;
    constructor(options = {}) {
        super(options);
        this.type = ElementType.Ticker;
        this.background.style = BgStyle.SolidColor;
        this.background.color.color = '#000000';
        this.typography.override = true;
        this.typography.color.color = '#FFFFFF';
    }
    get cssStyleObject() {
        let result = {
            duration: `--duration: ${this.duration}s`,
            width: this.placeOnSingleRow ? 'width: 100%' : '',
            ...super.cssStyleObject
        };
        return result;
    }
    get pojo() {
        return {
            text: this.text,
            duration: this.duration,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.text = json.text || TickerElement.defaultContent;
        this.duration = json.duration || 10;
        return this;
    }
}
class OnlineIndicator extends BaseElement {
    isOnlineIndicator = true;
    textOnline = '[FULL NAME] is online';
    textOffline = '[FULL NAME] is offline';
    status = 'auto';
    colorOnline = '#198754';
    colorOffline = '#adb5bd';
    constructor(options = {}) {
        super({ ...options, type: ElementType.OnlineIndicator });
    }
    get cssStyleObject() {
        let result = {
            duration: `--online-indicator-color: ${this.colorOnline}`,
            width: this.placeOnSingleRow ? 'width: 100%' : '',
            ...super.cssStyleObject
        };
        return result;
    }
    get pojo() {
        return {
            textOnline: this.textOnline,
            textOffline: this.textOffline,
            status: this.status,
            colorOnline: this.colorOnline,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.textOnline = json.textOnline || '[FULL NAME] is online';
        this.textOffline = json.textOffline || '[FULL NAME] is offline';
        this.status = json.status || 'auto';
        this.colorOnline = json.colorOnline || '#198754';
        this.colorOffline = json.colorOffline || '#adb5bd';
        return this;
    }
    get wrapperCssClassObject() {
        const onlineIndicator = isDesigner() ? this.status == 'offline' ? 'livechat-offline' : 'livechat-online' : '';
        return {
            ...super.wrapperCssClassObject, onlineIndicator
        };
    }
    get indicatorColorsStyle() {
        return `--indicator-color-online: ${this.colorOnline}; --indicator-color-offline: ${this.colorOffline} `;
    }
}
class OnlineIndicator2 extends compose(BaseElement, withImageCropper('image', BaseElement)) {
    isOnlineIndicator2 = true;
    textOnline = '[FULL NAME] is online';
    textOffline = '[FULL NAME] is offline';
    descriptionOnline = 'License ID: xxxxxxxxxxx';
    descriptionOffline = 'License ID: xxxxxxxxxxx';
    status = 'auto';
    colorOnline = '#198754';
    colorOffline = '#adb5bd';
    animation = false;
    image = '';
    rounded = 0;
    positionX = 0;
    positionY = 0;
    scale = 1;
    constructor(options = {}) {
        super({ ...options, type: ElementType.OnlineIndicator2 });
    }
    get cssStyleObject() {
        let result = {
            duration: `--online-indicator-color: ${this.colorOnline}`,
            width: this.placeOnSingleRow ? 'width: 100%' : '',
            ...super.cssStyleObject
        };
        return result;
    }
    get imageCssStyle() {
        if (this.scale < 1)
            return `zoom: ${this.scale}; border-radius: 50%; overflow: hidden`;
        else
            return `border-radius: 50%; overflow: hidden`;
    }
    get positionCssStyle() {
        return `right: ${this.positionX}%; bottom: ${this.positionY}%`;
    }
    get pojo() {
        return {
            textOnline: this.textOnline,
            textOffline: this.textOffline,
            descriptionOnline: this.descriptionOnline,
            descriptionOffline: this.descriptionOffline,
            animation: this.animation,
            status: this.status,
            colorOnline: this.colorOnline,
            image: this.image,
            rounded: this.rounded,
            scale: this.scale,
            positionX: this.positionX,
            positionY: this.positionY,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.textOnline = json.textOnline || '[FULL NAME] is online';
        this.textOffline = json.textOffline || '[FULL NAME] is offline';
        this.status = json.status || 'auto';
        this.colorOnline = json.colorOnline || '#198754';
        this.colorOffline = json.colorOffline || '#adb5bd';
        this.image = json.image || '';
        this.rounded = json.rounded || 0;
        this.scale = json.scale || 1;
        this.positionX = json.positionX || 0;
        this.positionY = json.positionY || 0;
        return this;
    }
    removeImage() {
        this.image = '';
    }
    get wrapperCssClassObject() {
        const onlineIndicator = isDesigner() ? this.status == 'offline' ? 'livechat-offline' : 'livechat-online' : '';
        return {
            ...super.wrapperCssClassObject, onlineIndicator
        };
    }
    get indicatorColorsStyle() {
        return `--indicator-color-online: ${this.colorOnline}; --indicator-color-offline: ${this.colorOffline} `;
    }
}
class MessageStyle {
    avatar = '';
    messageBg = '';
    messageColor = '';
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get pojo() {
        return {
            avatar: this.avatar,
            messageBg: this.messageBg,
            messageColor: this.messageColor
        };
    }
    fromJSON(json) {
        this.avatar = json.avatar || '';
        this.messageBg = json.messageBg || '#0866FF';
        this.messageColor = json.messageColor || '#ffffff';
    }
    toCss() {
        return `
      color: ${this.messageColor};
      background: ${this.messageBg};
    `;
    }
}
class LiveChatElement extends BaseElement {
    isLiveChat = true;
    livechat = new LiveChat();
    startingMessage = '';
    startingDelay = 1;
    closePeriod = 5;
    closeSessionEnabled = true;
    closedMessage = '';
    typingSpeed = 0.5;
    showTimestamp = false;
    thankyouMessage = '';
    messageBubbleStyle = MessageBubbleStyle.Style1;
    agentStyle = new MessageStyle({
        avatar: 'agent-avatar.svg',
        messageBg: '#E4E6E8',
        messageColor: '#000000'
    });
    visitorStyle = new MessageStyle({
        avatar: 'visitor-avatar.svg',
    });
    dialogStyle = new DialogStyle();
    textToSpeech = new TextToSpeech();
    constructor(options = {}) {
        super(options);
        this.type = ElementType.LiveChat;
    }
    get pojo() {
        return {
            livechat: this.livechat.saveToJSON(),
            startingMessage: this.startingMessage,
            startingDelay: this.startingDelay,
            closePeriod: this.closePeriod,
            closeSessionEnabled: this.closeSessionEnabled,
            closedMessage: this.closedMessage,
            thankyouMessage: this.thankyouMessage,
            agentStyle: this.agentStyle.pojo,
            visitorStyle: this.visitorStyle.pojo,
            typingSpeed: this.typingSpeed,
            messageBubbleStyle: this.messageBubbleStyle,
            dialogStyle: this.dialogStyle.pojo,
            textToSpeech: this.textToSpeech.pojo,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.startingMessage = json.startingMessage || 'Your session will be started soon...';
        this.startingDelay = json.startingDelay || 1;
        this.closePeriod = json.closePeriod || 5;
        this.closeSessionEnabled = json.closeSessionEnabled;
        this.closedMessage = json.closedMessage || 'You session is closed.';
        this.typingSpeed = json.typingSpeed || 0.5;
        this.thankyouMessage = json.thankyouMessage || 'Thank you for contacting us';
        this.messageBubbleStyle = json.messageBubbleStyle || MessageBubbleStyle.Style1;
        json.agentStyle && this.agentStyle.fromJSON(json.agentStyle);
        json.visitorStyle && this.visitorStyle.fromJSON(json.visitorStyle);
        json.dialogStyle && this.dialogStyle.fromJSON(json.dialogStyle);
        json.textToSpeech && this.textToSpeech.fromJSON(json.textToSpeech);
        json.livechat && this.livechat.loadFromJson(json.livechat);
        return this;
    }
    get isEmpty() {
        return this.livechat.nodes.length <= 1;
    }
    get messageBubbleStyleCssStyle() {
        const scope = `#${this.elementID}`;
        return getMessageBubbleStyle.apply(this, [scope]);
    }
}
class SurveyElement extends BaseElement {
    isSurvey = true;
    survey = new LiveChat(true);
    closePeriod = 30;
    closedMessage = '';
    thankyouMessage = 'Thank you for submiting';
    buttonWidth = new CssSizeValue({ auto: true });
    style = SurveyStyle.Style1;
    textToSpeech = new TextToSpeech();
    constructor(options = {}) {
        super(options);
        this.type = ElementType.Survey;
    }
    get pojo() {
        return {
            survey: this.survey.saveToJSON(),
            closePeriod: this.closePeriod,
            closedMessage: this.closedMessage,
            thankyouMessage: this.thankyouMessage,
            style: this.style,
            buttonWidth: this.buttonWidth,
            textToSpeech: this.textToSpeech.pojo,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.closePeriod = json.closePeriod || 30;
        this.closedMessage = json.closedMessage || 'The survey is closed.';
        this.thankyouMessage = json.thankyouMessage || 'Thank you for submiting';
        this.style = json.style || SurveyStyle.Style1;
        json.textToSpeech && this.textToSpeech.fromJSON(json.textToSpeech);
        json.buttonWidth && this.buttonWidth.fromJSON(json.buttonWidth);
        json.survey && this.survey.loadFromJson(json.survey);
        return this;
    }
    get cssStyleObject() {
        const { typography, ...result } = super.cssStyleObject;
        return result;
    }
    get isEmpty() {
        return this.survey.nodes.length <= 1;
    }
    get dummyQuestion() {
        const result = this.isEmpty ? null : this.survey.nodes[1];
        return result;
    }
    get firstQuestion() {
        if (!this.isEmpty) {
            const start = this.survey.nodes[0];
            return this.survey.getNodeByID(start._nextNodeUuid) || this.survey.nodes[1];
        }
        return null;
    }
    get surveyCssStyle() {
        const scope = `#${this.elementID}`;
        return getSurveyStyle(scope, this.buttonWidth, this.typography);
    }
}
export class BaseSpinWheelItem {
    uuid = makeid10();
    label = '';
    labelColor = new ColorWithOpacity({ color: '#000000' });
    backgroundColor = new ColorWithOpacity();
    image = '';
    weight = 100;
    winningMessage = '';
    buttons = [];
    confettiFallingAnimation = false;
    editing = false;
    editButton = null;
    constructor(options = {}) {
        this.fromJSON(options);
    }
    get pojo() {
        const buttons = [];
        for (let i of this.buttons) {
            buttons.push(i.pojo);
        }
        return {
            uuid: this.uuid,
            label: this.label,
            backgroundColor: this.backgroundColor.pojo,
            labelColor: this.labelColor.pojo,
            image: this.image,
            weight: this.weight,
            buttons,
            winningMessage: this.winningMessage,
            confettiFallingAnimation: this.confettiFallingAnimation,
        };
    }
    fromJSON(json) {
        this.uuid = json.uuid || makeid10();
        this.label = json.label || '';
        this.image = json.image || '';
        this.weight = json.weight || 0;
        this.winningMessage = json.winningMessage;
        this.confettiFallingAnimation = !!json.confettiFallingAnimation;
        json.backgroundColor && this.backgroundColor.fromJSON(json.backgroundColor);
        json.labelColor && this.labelColor.fromJSON(json.labelColor);
        this.buttons = [];
        if (Array.isArray(json.buttons)) {
            for (let i of json.buttons) {
                this.buttons.push(new Button(i));
            }
        }
        return this;
    }
    merge(that) {
        return this.fromJSON(that.pojo);
    }
    validate() {
        return true;
    }
    move(btn, index) {
        if (index >= 0 && index < this.buttons.length) {
            const array = this.buttons.filter(i => i.uuid != btn.uuid);
            array.splice(index, 0, btn);
            this.buttons = array;
        }
    }
    moveUp(btn) {
        const index = this.buttons.findIndex(i => i.uuid == btn.uuid);
        this.move(btn, index - 1);
    }
    moveDown(btn) {
        const index = this.buttons.findIndex(i => i.uuid == btn.uuid);
        this.move(btn, index + 1);
    }
    newButton() {
        this.editing = true;
        this.editButton = new Button({ type: ButtonType.GoToUrl });
    }
    edit(button) {
        this.editing = true;
        this.editButton = new Button(button);
    }
    delete(button) {
        this.buttons = this.buttons.filter(i => i.uuid != button.uuid);
    }
    cancel() {
        this.editButton = null;
    }
    save() {
        if (this.editButton && this.editButton.validate()) {
            try {
                for (let i of this.buttons) {
                    if (i.uuid == this.editButton.uuid) {
                        i.merge(this.editButton);
                        return;
                    }
                }
                this.buttons.push(this.editButton);
            }
            finally {
                this.editing = false;
                const self = this;
                setTimeout(function () {
                    self.editButton = null;
                }, 25);
            }
        }
    }
}
class SpinWheelItem extends compose(BaseSpinWheelItem, withImageCropper('image', BaseSpinWheelItem)) {
    removeImage() {
        this.image = '';
    }
}
class SpinWheelElement extends BaseElement {
    isSpinWheel = true;
    items = [];
    editItem = null;
    labelFont = '';
    spinButtonCaption = 'Spin';
    spinButtonStyle = new ButtonStyle();
    editStyle = new ButtonStyle();
    text = '';
    size = 250;
    _skin = 'wheelSkin1';
    dialogStyle = new DialogStyle({ position: "center" });
    textToSpeech = new TextToSpeech();
    constructor(options = {}) {
        super(options);
        this.type = ElementType.SpinWheel;
    }
    get pojo() {
        const items = [];
        for (let i of this.items) {
            items.push(i.pojo);
        }
        return {
            items,
            labelFont: this.labelFont,
            spinButtonCaption: this.spinButtonCaption,
            spinButtonStyle: this.spinButtonStyle.pojo,
            text: this.text,
            size: this.size,
            skin: this.skin,
            dialogStyle: this.dialogStyle.pojo,
            textToSpeech: this.textToSpeech.pojo,
            ...super.pojo
        };
    }
    fromJSON(json) {
        super.fromJSON(json);
        this.labelFont = json.labelFont || '';
        this.spinButtonCaption = json.spinButtonCaption || 'Spin';
        this.text = json.text || '';
        this.size = json.size || 250;
        this.skin = json.skin || 'wheelSkin1';
        this.items = [];
        if (typeof json.items != 'undefined') {
            for (let i of json.items) {
                this.items.push(new SpinWheelItem(i));
            }
        }
        json.spinButtonStyle && this.spinButtonStyle.fromJSON(json.spinButtonStyle);
        json.dialogStyle && this.dialogStyle.fromJSON(json.dialogStyle);
        json.textToSpeech && this.textToSpeech.fromJSON(json.textToSpeech);
        this.updateWheels();
        return this;
    }
    get cssStyleObject() {
        const { typography, ...result } = super.cssStyleObject;
        return result;
    }
    get isEmpty() {
        return !this.items.length;
    }
    get skin() {
        return this._skin;
    }
    set skin(value) {
        this._skin = value;
        this.updateWheels();
    }
    move(item, index) {
        if (index >= 0 && index < this.items.length) {
            const array = this.items.filter(i => i.uuid != item.uuid);
            array.splice(index, 0, item);
            this.items = array;
            this.updateWheels();
        }
    }
    moveUp(item) {
        const index = this.items.findIndex(i => i.uuid == item.uuid);
        this.move(item, index - 1);
    }
    moveDown(item) {
        const index = this.items.findIndex(i => i.uuid == item.uuid);
        this.move(item, index + 1);
    }
    newItem() {
        this.editItem = new SpinWheelItem();
    }
    edit(item) {
        this.editItem = new SpinWheelItem(item);
    }
    delete(button) {
        this.items = this.items.filter(i => i.uuid != button.uuid);
        this.updateWheels();
    }
    cancel() {
        this.editItem = null;
    }
    save() {
        if (this.editItem && this.editItem.validate()) {
            try {
                for (let i of this.items) {
                    if (i.uuid == this.editItem.uuid) {
                        i.merge(this.editItem);
                        return;
                    }
                }
                this.items.push(this.editItem);
            }
            finally {
                this.editItem = null;
                this.updateWheels();
            }
        }
    }
    createWheelItems(includeImages = true) {
        const result = [];
        for (let i of this.items) {
            result.push({
                label: i.label,
                labelColor: i.labelColor.toCss(),
                backgroundColor: i.backgroundColor.toCss(),
                $weight: i.weight,
                image: includeImages ? i.image : null
            });
        }
        return result;
    }
    updateWheels() {
        const self = this;
        setTimeout(function () {
            self.doUpdateWheels();
        }, 25);
    }
    doUpdateWheels() {
        if (typeof window != 'undefined') {
            window.dispatchEvent(new CustomEvent('updateWheelItems', {
                detail: {
                    uuid: this.uuid,
                    skin: this.skin,
                    items: this.createWheelItems(),
                }
            }));
        }
    }
    showButtonStyleModal(modal) {
        this.editStyle.fromJSON(this.spinButtonStyle.pojo);
        bootstrap.Modal.getOrCreateInstance(modal, {}).show();
    }
    applyStyle() {
        this.spinButtonStyle.fromJSON(this.editStyle);
    }
    set fileInput(value) {
        if (this.editItem) {
            this.editItem.fileInput = value;
        }
    }
    set cropperImage(value) {
        if (this.editItem) {
            this.editItem.cropperImage = value;
        }
    }
    set modal(value) {
        if (this.editItem) {
            this.editItem.modal = value;
            this.editItem.afterInject('modal');
        }
    }
    uploadOK() {
        if (this.editItem) {
            this.editItem.uploadOK();
        }
    }
}
var BlockDirection;
(function (BlockDirection) {
    BlockDirection[BlockDirection["Row"] = 1] = "Row";
    BlockDirection[BlockDirection["Column"] = 2] = "Column";
})(BlockDirection || (BlockDirection = {}));
export class Block {
    isBlock = true;
    uuid = makeid10();
    type = ElementType.Block;
    name = '';
    meta = new Metadata();
    background = new Background();
    dropShadow = new DropShadow();
    dimension = new Dimension();
    margin = new Margin();
    padding = new Padding();
    borders = new Borders();
    visibility = new Visibility();
    typography = new Typography();
    protection = Protection.None;
    sizing = new GridSizing();
    elements = [];
    direction = BlockDirection.Column;
    showAddNewPopup = false;
    contextMenu = {
        show: false,
        position: {
            top: '0px',
            left: '0px'
        }
    };
    showContextMenu($event) {
        this.contextMenu.show = true;
        this.contextMenu.position.left = $event.clientX + 'px';
        this.contextMenu.position.top = $event.clientY + 'px';
    }
    contextMenuStyle() {
        return typeof this.contextMenu != 'undefined' ? this.contextMenu.position : {};
    }
    constructor(options = {}) {
        this.fromJSON(options);
    }
    newWysiwyg(options = {}) {
        let result = new ElementClassMap.Wysiwyg(options);
        this.elements.push(result);
        return result;
    }
    newButtonGroup(options = {}) {
        let result = new ElementClassMap.ButtonGroup(options);
        this.elements.push(result);
        return result;
    }
    newImage(options = {}) {
        let result = new ElementClassMap.Image(options);
        this.elements.push(result);
        return result;
    }
    newTicker(options = {}) {
        let result = new ElementClassMap.Ticker(options);
        this.elements.push(result);
        return result;
    }
    newLiveChat(options = {}) {
        let result = new ElementClassMap.LiveChat(options);
        this.elements.push(result);
        return result;
    }
    newSurvey(options = {}) {
        let result = new ElementClassMap.Survey(options);
        this.elements.push(result);
        return result;
    }
    getGridClass() {
        return `pb-block-mobile-${this.sizing.mobile} pb-block-tablet-${this.sizing.tablet} pb-block-desktop-${this.sizing.desktop}`;
    }
    find(uuid) {
        for (let i of this.elements) {
            if (i.uuid == uuid) {
                return i;
            }
        }
        return null;
    }
    contains(uuid) {
        return !!this.find(uuid);
    }
    move(elm, index) {
        if (index >= 0 && index < this.elements.length) {
            const array = this.elements.filter(i => i.uuid != elm.uuid);
            array.splice(index, 0, elm);
            this.elements = array;
            return true;
        }
    }
    moveUp(elm) {
        const index = this.elements.findIndex(i => i.uuid == elm.uuid);
        return this.move(elm, index - 1);
    }
    moveDown(elm) {
        const index = this.elements.findIndex(i => i.uuid == elm.uuid);
        return !this.move(elm, index + 1);
    }
    insert(item, options = {}) {
        insert.apply(this, [item, 'elements', options]);
    }
    clone() {
        return new Block(this.pojo);
    }
    loadElements(arr = []) {
        const elements = [];
        for (let i of arr) {
            elements.push(BaseElement.create(i));
        }
        const self = this;
        queueMicrotask(function () {
            self.elements = elements;
        });
    }
    deleteElement(elm) {
        this.elements = this.elements.filter(i => i.uuid != elm.uuid);
    }
    get protectionClass() {
        switch (this.protection) {
            case Protection.HideFromBlockedVisitors: return 'hfbv';
            case Protection.ShowToBlockedVisitors: return 'stbv';
        }
        return '';
    }
    get cssStyleArray() {
        const result = [
            this.background.cssStyle,
            this.borders.cssStyle,
            this.margin.cssStyle,
            this.padding.cssStyle,
            this.dimension.cssStyle,
            this.typography.cssStyle,
            this.dropShadow.cssStyle,
        ];
        return result;
    }
    get cssStyle() {
        return this.cssStyleArray.filter(i => i).join(' ;');
    }
    get cssClass() {
        return [this.meta.class, this.getGridClass(), this.protectionClass, !this.elements.length ? 'empty' : ''].filter(i => i).join(' ');
    }
    get elementID() {
        return this.meta.id || `block-${this.uuid}`;
    }
    get pojo() {
        const elements = [];
        for (let i of this.elements) {
            elements.push(i.pojo);
        }
        return {
            uuid: this.uuid,
            type: this.type,
            direction: this.direction,
            meta: this.meta.pojo,
            background: this.background.pojo,
            dropShadow: this.dropShadow.pojo,
            dimension: this.dimension.pojo,
            margin: this.margin.pojo,
            padding: this.padding.pojo,
            borders: this.borders.pojo,
            visibility: this.visibility.pojo,
            sizing: this.sizing.pojo,
            typography: this.typography.pojo,
            protection: this.protection,
            elements
        };
    }
    fromJSON(json) {
        this.uuid = json.uuid || makeid10();
        this.type = ElementType.Block;
        this.name = json.name || 'New block';
        this.direction = json.direction;
        this.protection = json.protection || Protection.None;
        json.meta && this.meta.fromJSON(json.meta);
        json.background && this.background.fromJSON(json.background);
        json.dropShadow && this.dropShadow.fromJSON(json.dropShadow);
        json.dimension && this.dimension.fromJSON(json.dimension);
        json.margin && this.margin.fromJSON(json.margin);
        json.padding && this.padding.fromJSON(json.padding);
        json.borders && this.borders.fromJSON(json.borders);
        json.visibility && this.visibility.fromJSON(json.visibility);
        json.typography && this.typography.fromJSON(json.typography);
        json.sizing && this.sizing.fromJSON(json.sizing);
        json.elements && this.loadElements(json.elements);
        return this;
    }
}
export class Section {
    BlockClass = Block;
    isSection = true;
    uuid = makeid10();
    type = ElementType.Section;
    name = '';
    background = new Background();
    dropShadow = new DropShadow();
    dimension = new Dimension();
    sticky = new Sticky();
    margin = new Margin();
    padding = new Padding({
        left: {
            auto: false,
            unit: 'em',
            value: 0.25
        },
        right: {
            auto: false,
            unit: 'em',
            value: 0.25
        }
    });
    borders = new Borders();
    visibility = new Visibility();
    typography = new Typography();
    meta = new Metadata();
    blocks = [new this.BlockClass(),];
    protection = Protection.None;
    showAddNewPopup = false;
    constructor(options = {}) {
        this.fromJSON(options);
    }
    loadBlocks(arr) {
        const blocks = [];
        for (let i of arr) {
            blocks.push(new this.BlockClass().fromJSON(i));
        }
        const self = this;
        queueMicrotask(function () {
            self.blocks = blocks;
        });
    }
    newBlock(append = true) {
        let result = new this.BlockClass();
        if (append) {
            this.blocks.push(result);
        }
        else {
            this.blocks.splice(0, 0, result);
        }
        return result;
    }
    get firstBlock() {
        return this.blocks[0];
    }
    deleteBlock(block) {
        const blocks = this.blocks.filter(i => i.uuid != block.uuid);
        this.blocks = blocks.length ? blocks : [new this.BlockClass()];
    }
    equal(that) {
        return that?.uuid == this.uuid;
    }
    clone() {
        const constructor = Object.getPrototypeOf(this).constructor;
        return new constructor(this.pojo);
    }
    find(uuid) {
        for (let i of this.blocks) {
            if (i.uuid == uuid) {
                return i;
            }
            else {
                let temp;
                if (temp = i.find(uuid)) {
                    return temp;
                }
            }
        }
        return null;
    }
    contains(uuid) {
        return !!this.find(uuid);
    }
    move(block, index) {
        if (index >= 0 && index < this.blocks.length) {
            const array = this.blocks.filter(i => i.uuid != block.uuid);
            array.splice(index, 0, block);
            this.blocks = array;
            return true;
        }
    }
    moveUp(block) {
        const index = this.blocks.findIndex(i => i.uuid == block.uuid);
        return this.move(block, index - 1);
    }
    moveDown(block) {
        const index = this.blocks.findIndex(i => i.uuid == block.uuid);
        return !this.move(block, index + 1);
    }
    insert(item, options = {}) {
        insert.apply(this, [item, 'blocks', options]);
    }
    get pojo() {
        const blocks = [];
        for (let i of this.blocks) {
            blocks.push(i.pojo);
        }
        return {
            uuid: this.uuid,
            type: this.type,
            name: this.name,
            protection: this.protection,
            background: this.background.pojo,
            dropShadow: this.dropShadow.pojo,
            dimension: this.dimension.pojo,
            sticky: this.sticky.pojo,
            margin: this.margin.pojo,
            padding: this.padding.pojo,
            borders: this.borders.pojo,
            visibility: this.visibility.pojo,
            meta: this.meta.pojo,
            typography: this.typography.pojo,
            blocks
        };
    }
    fromJSON(json) {
        this.uuid = json.uuid || makeid10();
        this.type = ElementType.Section;
        this.name = json.name || 'New section';
        this.protection = json.protection || Protection.None;
        json.background && this.background.fromJSON(json.background);
        json.dropShadow && this.dropShadow.fromJSON(json.dropShadow);
        json.dimension && this.dimension.fromJSON(json.dimension);
        json.sticky && this.sticky.fromJSON(json.sticky);
        json.margin && this.margin.fromJSON(json.margin);
        json.padding && this.padding.fromJSON(json.padding);
        json.borders && this.borders.fromJSON(json.borders);
        json.visibility && this.visibility.fromJSON(json.visibility);
        json.meta && this.meta.fromJSON(json.meta);
        json.blocks && this.loadBlocks(json.blocks);
        json.typography && this.typography.fromJSON(json.typography);
        return this;
    }
    get protectionClass() {
        switch (this.protection) {
            case Protection.HideFromBlockedVisitors: return 'hfbv';
            case Protection.ShowToBlockedVisitors: return 'stbv';
        }
        return '';
    }
    get cssStyleArray() {
        const result = [
            this.background.cssStyle,
            this.borders.cssStyle,
            this.margin.cssStyle,
            this.padding.cssStyle,
            this.dimension.cssStyle,
            this.typography.cssStyle,
            this.dropShadow.cssStyle
        ];
        return result;
    }
    get cssStyle() {
        return this.cssStyleArray.filter(i => i).join(' ;');
    }
    get cssClass() {
        return [this.meta.class, this.protectionClass].filter(i => i).join(' ');
    }
    get elementID() {
        return this.meta.id || `section-${this.uuid}`;
    }
}
export class PageBuilder {
    _typography = new TypographyPerDevice();
    uuid = makeid10();
    title = '';
    disableRightClickMenu = true;
    disableViewsourceShortcutKey = true;
    expandedSection = '';
    background = new Background();
    dimension = new Dimension();
    borders = new Borders();
    dropShadow = new DropShadow();
    margin = new Margin();
    padding = new Padding();
    buttonStyles = new ButtonStyleManager();
    gridMode = false;
    _deviceType = DeviceTypes.Mobile;
    sections = [new Section()];
    constructor() {
        const self = this;
        queueMicrotask(function () {
            self.buttonStyles.setAsStyleResolver();
        });
    }
    newSection(index = 0) {
        let result = new SectionClass();
        this.sections.splice(index, 0, result);
        return result;
    }
    deleteSection(section) {
        const sections = this.sections.filter(i => i.uuid != section.uuid);
        if (section.uuid == this.expandedSection) {
            this.expandedSection = '';
        }
        this.sections = sections.length ? sections : [new SectionClass()];
    }
    move(section, index) {
        if (index >= 0 && index < this.sections.length) {
            const array = this.sections.filter(i => i.uuid != section.uuid);
            array.splice(index, 0, section);
            this.sections = array;
            return true;
        }
    }
    moveUp(section) {
        const index = this.sections.findIndex(i => i.uuid == section.uuid);
        return this.move(section, index - 1);
    }
    moveDown(section) {
        const index = this.sections.findIndex(i => i.uuid == section.uuid);
        return this.move(section, index + 1);
    }
    insert(item, options = {}) {
        insert.apply(this, [item, 'sections', options]);
    }
    save() {
        let result = {
            pbVersion: 1,
            title: this.title,
            expandedSection: this.expandedSection,
            disableRightClickMenu: this.disableRightClickMenu,
            disableViewsourceShortcutKey: this.disableViewsourceShortcutKey,
            background: this.background.pojo,
            borders: this.borders.pojo,
            typography: this._typography.pojo,
            dropShadow: this.dropShadow.pojo,
            dimension: this.dimension.pojo,
            margin: this.margin.pojo,
            padding: this.padding.pojo,
            buttonStyles: this.buttonStyles.pojo
        };
        let sections = [];
        for (let i of this.sections) {
            sections.push(i.pojo);
        }
        result.sections = sections;
        return result;
    }
    saveToStr() {
        return JSON.stringify(this.save());
    }
    fromJSON(json) {
        json.background && this.background.fromJSON(json.background);
        json.borders && this.borders.fromJSON(json.borders);
        json.dropShadow && this.dropShadow.fromJSON(json.dropShadow);
        json.dimension && this.dimension.fromJSON(json.dimension);
        json.margin && this.margin.fromJSON(json.margin);
        json.padding && this.padding.fromJSON(json.padding);
        json.buttonStyles && this.buttonStyles.fromJSON(json.buttonStyles);
        json.typography && this._typography.fromJSON(json.typography);
        this.title = json.title || '';
        this.expandedSection = json.expandedSection;
        this.disableRightClickMenu = json.disableRightClickMenu;
        this.disableViewsourceShortcutKey = json.disableViewsourceShortcutKey;
        const sections = [];
        if (json && Array.isArray(json.sections)) {
            for (let i of json.sections) {
                sections.push(new SectionClass().fromJSON(i));
            }
        }
        const self = this;
        queueMicrotask(function () {
            self.sections = sections.length ? sections : [new SectionClass()];
        });
        return this;
    }
    loadFromStr(str) {
        const json = JSON.parse(str);
        return this.fromJSON(json);
    }
    find(uuid) {
        for (let i of this.sections) {
            if (i.uuid == uuid) {
                return i;
            }
            else {
                let temp = i.find(uuid);
                if (temp) {
                    return temp;
                }
            }
        }
    }
    parentOf(item) {
        if (isPbElement(item)) {
            for (let section of this.sections) {
                for (let block of section.blocks) {
                    if (block.contains(item.uuid)) {
                        return block;
                    }
                }
            }
        }
        if (isPbBlock(item)) {
            for (let i of this.sections) {
                if (i.contains(item.uuid)) {
                    return i;
                }
            }
        }
        if (isPbSection(item))
            return this;
    }
    get typography() {
        throw new Error('Not implemented');
    }
    get cssStyleArray() {
        const result = [
            this.background.cssStyle,
            this.borders.cssStyle,
            this.dimension.cssStyle,
            this.dropShadow.cssStyle,
            typeof this.isDesigner !== 'undefined' ? this.typography.cssStyle : '',
        ];
        return result;
    }
    get cssStyle() {
        return this.cssStyleArray.filter(i => i).join(' ;');
    }
}
ElementClassMap.Wysiwyg = WysiwygElement;
ElementClassMap.ButtonGroup = ButtonGroupElement;
ElementClassMap.Image = ImageElement;
ElementClassMap.Ticker = TickerElement;
ElementClassMap.OnlineIndicator = OnlineIndicator;
ElementClassMap.OnlineIndicator2 = OnlineIndicator2;
ElementClassMap.Form = HtmlFormElement;
ElementClassMap.SpinWheel = SpinWheelElement;
ElementClassMap.LiveChat = LiveChatElement;
ElementClassMap.Survey = SurveyElement;
export let WysiwygElementClass = WysiwygElement;
export let ImageElementClass = ImageElement;
export let TickerElementClass = TickerElement;
export let OnlineIndicatorClass = OnlineIndicator;
export let OnlineIndicator2Class = OnlineIndicator2;
export let HtmlFormElementClass = HtmlFormElement;
export let ButtonGroupElementClass = ButtonGroupElement;
export let SpinWheelElementClass = SpinWheelElement;
export let SurveyElementClass = SurveyElement;
export let LiveChatElementClass = LiveChatElement;
export let BlockClass = Block;
export let SectionClass = Section;
export function setBlockClass(cls) {
    BlockClass = cls;
}
export function setSectionClass(cls) {
    SectionClass = cls;
}
export function isPbElement(item) {
    return typeof item.isElement != 'undefined' && item.isElement;
}
export function isPbBlock(item) {
    return typeof item.isBlock != 'undefined' && item.isBlock;
}
export function isPbSection(item) {
    return typeof item.isSection != 'undefined' && item.isSection;
}
export function isPbPageBuilder(item) {
    return typeof item.sections != 'undefined';
}
function indexOf(item, array, position) {
    let result = undefined;
    if (item) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].uuid == item.uuid) {
                result = i;
                break;
            }
        }
    }
    if (result !== undefined && position) {
        if (position == RIGHT_BEFORE)
            return result;
        if (position == RIGHT_AFTER)
            return ++result;
        if (position == THE_BEGINNING_OF)
            return 0;
        if (position == THE_END_OF)
            return array.length;
    }
    return result;
}
function insert(item, array, options = {}) {
    let index = options.index;
    let reference = options.reference;
    while (index == undefined && reference) {
        index = indexOf(reference, this[array], options.position);
        reference = options.pageBuilder.parentOf(reference);
    }
    if (!options.reference) {
        if (options.position == THE_BEGINNING_OF) {
            move.apply(this, [item, array, 0, false]);
        }
        else {
            this[array].push(item);
        }
    }
    else if (options.position == THE_END_OF || (options.position == RIGHT_AFTER && options.reference.uuid == this.uuid))
        this[array].push(item);
    else if (options.position == THE_BEGINNING_OF)
        move.apply(this, [item, array, 0, false]);
    else
        move.apply(this, [item, array, index || 0, false]);
}
function move(item, field, index, doNotAppend = true) {
    if (index >= 0 && index < this[field].length) {
        const array = this[field].filter(i => i.uuid != item.uuid);
        array.splice(index, 0, item);
        this[field] = array;
        return true;
    }
    else if (index == this[field].length && !doNotAppend) {
        this[field].push(item);
    }
}
