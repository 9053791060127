import { nextTick } from "#page-builder/nextTick"
import { OptionalOf } from "#page-builder/optional-of"
import {
  RIGHT_AFTER,
  RIGHT_BEFORE,
  THE_BEGINNING_OF,
  THE_END_OF,
  isPbBlock,
  isPbElement,
  isPbSection,  
  BaseElement,
  Block,
  BlockClass,
  ButtonGroupElementClass,
  ImageElementClass,
  LiveChatElementClass,
  OnlineIndicatorClass,
  OnlineIndicator2Class,
  HtmlFormElementClass,
  PageBuilder,
  Section,
  SectionClass,
  setBlockClass,
  setSectionClass,
  SurveyElementClass,
  TickerElementClass,
  WysiwygElementClass,
} from "#page-builder/page-builder"
import {
  BgStyle,
  BorderStyle,
  DeviceTypes,
  HorizontalAlignment,
  ItemRelative,
  ItemRelativeGroup,
  Overflow,
  ScreenResolution,
  Typography,
  VerticalAlignment
} from "#page-builder/types/common"
import { image } from "#page-builder/utils"
import { _fetchJson } from "../../_fetch"
import api from "../../api"
import { ButtonKind, showConfirm, showSuccess } from "../../dialogs"
import toasts from "../../toasts"

export { LiveChat } from "#page-builder/live-chat"

export class _Block extends Block {
  public moveUp(elm: BaseElement) {
    if (!super.moveUp(elm)) {
      toasts.addWarning(`Can't move this element because it is at the beginning of the block. Please try to move the block instead!`)
      return false
    }
    return true
  }

  public moveDown(elm: BaseElement) {
    if (!super.moveDown(elm)) {
      toasts.addWarning(`Can't move this element because it is at the end of the block. Please try to move the block instead!`)
      return false
    }
    return true
  }
}

export class _Section extends Section {
  readonly BlockClass = _Block
  //
  public deleteBlock(block: Block): void {
    const self = this
    const deleteBlock = super.deleteBlock
    showConfirm('Are you sure you want to delete this block?',
      {
        buttons: [
          {
            kind: ButtonKind.Cancel,
            dismiss: true
          },
          {
            kind: ButtonKind.OK,
            dismiss: true,
            onclick: function() {
              deleteBlock.apply(self, [block])
            }
          },
        ]
      }
    )
  }

  public moveUp(block: Block) {
    if (!super.moveUp(block)) {
      toasts.addWarning(`Can't move this block because it is at the beginning of the section. Please try to move the sectopm instead!`)
      return false
    }
    return true
  }

  public moveDown(block: Block) {
    if (!super.moveDown(block)) {
      toasts.addWarning(`Can't move this block because it is at the end of the section. Please try to move the section instead!`)
      return false
    }
    return true
  }
}

export class _PageBuilder extends PageBuilder {
  readonly isDesigner: boolean = true
  //
  private $$$: Section | Block| BaseElement = null
  //
  public opening: boolean = true
  //
  public openingError: boolean = false
  //
  public changed: boolean = false
  //
  public _showDashedLines: boolean = false
  //
  public _dataFields = [
    '_typography',
    'title', 'disableRightClickMenu', 'disableViewsourceShortcutKey', 'expandedSection',
    'background', 'dimension', 'borders', 'dropShadow', 'margin', 'padding', 'sections'
  ]
  //
  constructor() {
    super()
    // Tránh pb-menu bị ẩn
    this.dimension.overflow = Overflow.Visible
  }
  //
  public is$$$(item: Section | Block| BaseElement, strict: boolean = false): boolean {
    let result = this.$$$?.uuid == item?.uuid
    //@ts-ignore
    if (!strict && !result && typeof item.isBlock != 'undefined' && item.isBlock) {
      for (let i of (item as Block).elements) {
        if (i.uuid == this.$$$?.uuid) {
          return true
        }
      }
    }
    return result
  }
  //
  public async submit() {
    const res = await _fetchJson(api.PAGE_BUILDER_URI, {
      method: 'POST',
      body: this.saveToStr()
    })
    //
    if (res) {
      this.changed = false
      showSuccess('The page has been saved')
    }
  }
  //
  public async publish() {
    const res = await _fetchJson(api.PAGE_PUBLISH_URI, {
      method: 'POST',
    })
    //
    if (res) {
      showSuccess(`The page has been published at :link`, { link: res.url })
    }
  }
  //
  public async refresh() {
    // Reset value
    this.opening = true
    this.openingError = false
    //
    const res = await _fetchJson(api.setQueryParams({ json: true }) .PAGE_BUILDER_URI, {
      method: 'GET'
    })
    //
    this.opening = false
    if (res) {
      if (res.body) {
        this.fromJSON(res.body)
      }
      this.openingError = false
    } else {
      this.openingError = true
    }
  }
  //
  public setSelection(uuid: string) {
    const found = this.find(uuid)
    if (found) {
      this.$$$ = found
    }
  }
  /*
   * Tìm đối tượng cha mà item có thể được chèn vào
   */
  public getInsertPlacement(item: BaseElement|Block|Section, relative: ItemRelativeGroup, inserted: BaseElement|Block|Section): Block|Section|this {
    // Nếu cần insert vào page hoặc đối tượng được insert là section => trả về page
    if (relative == ItemRelativeGroup.Page || isPbSection(inserted))
      return this
    //
    if (isPbElement(item)) {
      for (let section of this.sections) {
        for (let block of section.blocks) {
          if (block.contains(item.uuid)) {
            // Nếu đối tượng được insert là block => phải trả về section
            return relative == ItemRelativeGroup.Section || isPbBlock(inserted)? section: block
          }
        }
      }
    }
    if (isPbBlock(item)) {
      if ((relative <= ItemRelativeGroup.Block) && (isPbElement(inserted))) {
        return item as Block
      }

      for (let i of this.sections) {
        if (i.contains(item.uuid)) {
          return i
        }
      }
    }
    if (isPbSection(item) && relative <= ItemRelativeGroup.Section) {
      return item as Section
    }
    //
    if (relative != ItemRelativeGroup.None)
      return this
  }
  //
  public deleteSection(section: Section): void {
    const self = this
    showConfirm('Are you sure you want to delete this section?',
      {
        buttons: [
          {
            kind: ButtonKind.Cancel,
            dismiss: true
          },
          {
            kind: ButtonKind.OK,
            dismiss: true,
            onclick: function() {
              const sections = self.sections.filter(i => i.uuid != section.uuid)
              //
              if (section.uuid == self.expandedSection) {
                self.expandedSection = ''
              }
              //
              self.sections = sections.length? sections: [ new Section() ]
            }
          },
        ]
      }
    )
  }
  //
  public moveUp(section: Section): boolean {
    if (!super.moveUp(section)) {
      toasts.addWarning(`Can't move this section because it is at the beginning of the page.`)
      return false
    }
    return true
  }

  public moveDown(section: Section): boolean {
    if (!super.moveDown(section)) {
      toasts.addWarning(`Can't move this section because it is at the end of the page.`)
      return false
    }
    return true
  }

  public fromJSON(json: OptionalOf<PageBuilder>) {
    super.fromJSON(json)
    // Tránh pb-menu bị ẩn
    this.dimension.overflow = Overflow.Visible
    //
    const self = this
    queueMicrotask(function() {
      nextTick(function() {
        self.changed = false
      })
    })

    return this
  }
  public newSection(index: number = 0) {
    const result = super.newSection(index)
    //
    const self = this
    nextTick(function() {
      self.$$$ = result
    })
    //
    return result
  }
  //
  public get isEmpty(): boolean {
    const result = this.sections.length == 1 && this.sections[0].blocks.length == 1 && !this.sections[0].blocks[0].elements.length
    return result
  }
  //
  public get showDashedLines(): boolean {
    return this._showDashedLines || this.isEmpty
  }
  //
  public set showDashedLines(value: boolean) {
    this._showDashedLines = value
    updatePageBuilderScrollbars()
  }
  //
  public get deviceType(): DeviceTypes {
    return this._deviceType
  }
  //
  public set deviceType(value: DeviceTypes) {
    this._deviceType = value
    updatePageBuilderScrollbars()
  }
  //
  public get getViewportVariables(): string {
    switch (this.deviceType) {
      case DeviceTypes.Mobile: return `--vw: ${(375/100).toFixed(4)}px; --vh: ${(667/100).toFixed(4)}px`
      case DeviceTypes.Tablet: return `--vw: ${(834/100).toFixed(4)}px; --vh: ${(1194/100).toFixed(4)}px`
      case DeviceTypes.Desktop: return `--vw: ${(1280/100).toFixed(4)}px; --vh: ${(720/100).toFixed(4)}px`
    }
  }
  //
  public get screenResolution(): ScreenResolution {
    switch (this.deviceType) {
      case DeviceTypes.Mobile: return { width: '375px', height: '667px' }
      case DeviceTypes.Tablet: return { width: '834px', height: '1194px' }
      case DeviceTypes.Desktop: return { width: '1280px', height: '720px' }
    }
  }
  //
  public get typography(): Typography {
    switch (this.deviceType) {
      case DeviceTypes.Mobile: return this._typography.mobile
      case DeviceTypes.Tablet: return this._typography.tablet.override? this._typography.tablet : this._typography.mobile
      case DeviceTypes.Desktop: return this._typography.desktop.override? this._typography.desktop : this._typography.mobile
    }
  }
  //
  public addElement({ template, placement }: { template: string, placement: ItemRelative }) {

    // American Homeowners May Be Eligible for Cash Payout with New Program
    // => add Text with image 1

    let item: BaseElement|Block|Section = null
    switch (template) {
      case 'wysiwyg':
        item = new WysiwygElementClass({
          placeOnSingleRow: true
        })
        break

      case 'image':
        item = new ImageElementClass({
          placeOnSingleRow: true,
          image: image('template-thumbnails/image.png')
        })
        break

      case 'ticker':
        item = new TickerElementClass({
          placeOnSingleRow: true
        })
        break

      case 'online-indicator':
        item = new OnlineIndicatorClass({
          placeOnSingleRow: true
        })
        break

      case 'online-indicator2':
        item = new OnlineIndicator2Class({
          placeOnSingleRow: true
        })
        break

      case 'html-form':
        item = new HtmlFormElementClass({
          placeOnSingleRow: true
        })
        break

      case 'button-group':
        item = new ButtonGroupElementClass({
          placeOnSingleRow: true,
          buttons: [
            //@ts-ignore
            { text: 'Button 1' },
            //@ts-ignore
            { text: 'Button 2' },
          ],
          //@ts-ignore
          btnWidth: {
            auto: false,
            value: 200,
            unit: 'px'
          }
        })
        break

      case 'survey':
        item = new SurveyElementClass({
          placeOnSingleRow: true
        })
        break

      case 'live-chat':
        item = new LiveChatElementClass({
          placeOnSingleRow: true
        })
        break

      case 'block-empty':
        item = new BlockClass()
        break

      case 'text-with-image-1':
        item = new BlockClass()
        item.newWysiwyg({ placeOnSingleRow: true })
        item.newImage({ placeOnSingleRow: true, image: image('template-thumbnails/image.png') })
        break

      case 'text-with-image-2':
        item = new BlockClass()
        item.newImage({ placeOnSingleRow: true, image: image('template-thumbnails/image.png') })
        item.newWysiwyg({ placeOnSingleRow: true })
        break

      case 'button-group-with-text':
        item = new BlockClass()
        item.newWysiwyg({
          placeOnSingleRow: true
        })
        item.newButtonGroup({
          placeOnSingleRow: true,
          buttons: [
            //@ts-ignore
            { text: 'Button 1' },
            //@ts-ignore
            { text: 'Button 2' },
          ],
          //@ts-ignore
          btnWidth: {
            auto: false,
            value: 200,
            unit: 'px'
          }
        })
        break

      case 'basic-section':
        item = new SectionClass()
        break

      case 'simple-footer':
        placement = ItemRelative.AtTheEndOfThePage
        item = new SectionClass({
          borders: {
            //@ts-ignore
            top: {
              color: '#CCCCCC',
              style: BorderStyle.Solid,
              //@ts-ignore
              width: {
                value: 1,
                unit: 'px'
              }
            }
          },
          padding: {
            //@ts-ignore
            top: {
              value: 0.5,
              unit: 'em'
            },
            //@ts-ignore
            bottom: {
              value: 0.5,
              unit: 'em'
            }
          },
          margin: {
            //@ts-ignore
            top: {
              value: 0.5,
              unit: 'em'
            }
          }
        })
        item.firstBlock.newWysiwyg({
          placeOnSingleRow: true,
          html: `<p style="text-align:center"><a href="/privacy">Privacy</a> | <a href="/terms">Terms</a> | <a href="/contact">Contact</a></p>`
        })
        break

      case 'simple-header':
        placement = ItemRelative.AtTheBeginningOfThePage
        item = new SectionClass({
          background: {
            style: BgStyle.SolidColor,
            //@ts-ignore
            color: {
              color: '#1163CE',
              opacity: 100
            }
          },
          padding: {
            //@ts-ignore
            top: {
              value: 0.25,
              unit: 'em'
            },
            //@ts-ignore
            bottom: {
              value: 0.25,
              unit: 'em'
            },
            //@ts-ignore
            right: {
              value: 0.25,
              unit: 'em'
            },
            //@ts-ignore
            left: {
              value: 0.25,
              unit: 'em'
            }
          },
          margin: {
            //@ts-ignore
            bottom: {
              value: 0.25,
              unit: 'em'
            }
          },
          dropShadow: {
            enabled: true,
            borderOnly: true,
            //@ts-ignore
            positionX: {
              value: 1,
            },
            //@ts-ignore
            positionY: {
              value: 2
            },
            //@ts-ignore
            color: {
              color: '#9e9e9f',
              opacity: 100
            }
          }
        })
        item.firstBlock.newImage({
          placeOnSingleRow: false,
          rounded: 5,
          scale: 0.5,
          horizontalAlignment: HorizontalAlignment.Start,
          image: image('template-thumbnails/logo-sample.jpg'),
        })
        item.firstBlock.newWysiwyg({
          placeOnSingleRow: false,
          horizontalAlignment: HorizontalAlignment.Start,
          verticalAlignment: VerticalAlignment.Center,
          html: 'Lorem ipsum.',
          margin: {
            //@ts-ignore
            left: {
              auto: false,
              value: 0.25,
              unit: 'em'
            }
          }
        })
        break

      case 'header-with-ticker':
        placement = ItemRelative.AtTheBeginningOfThePage
        item = new SectionClass({
          background: {
            style: BgStyle.SolidColor,
            //@ts-ignore
            color: {
              color: '#000000',
              opacity: 100
            }
          },
          margin: {
            //@ts-ignore
            bottom: {
              value: 0.25,
              unit: 'em'
            }
          },
          padding: {
            //@ts-ignore
            left: {
              auto: false,
              unit: 'em',
              value: 0,
            },
            //@ts-ignore
            right: {
              auto: false,
              unit: 'em',
              value: 0,
            }
          },
          dropShadow: {
            enabled: true,
            borderOnly: true,
            //@ts-ignore
            positionX: {
              value: 1,
            },
            //@ts-ignore
            positionY: {
              value: 2
            },
            //@ts-ignore
            color: {
              color: '#9e9e9f',
              opacity: 100
            }
          }
        })

        //@ts-ignore
        item.firstBlock.dimension.fromJSON({
          //@ts-ignore
          width: {
            auto: false,
            value: 100,
            unit: '%'
          }
        })

        item.firstBlock.newTicker({
          text: 'This is a ticker',
          horizontalAlignment: HorizontalAlignment.Start,
          verticalAlignment: VerticalAlignment.Center,
          dimension: {
            //@ts-ignore
            width: {
              auto: false,
              value: 100,
              unit: '%'
            }
          }
        })
        break
    }

    if (item) {
      let position = null
      let relativeGroup = placement % 10
      //
      const selectedUUID = item.uuid
      // !this.$$$ <=> this.$$$ == null : nghĩa là không biết insert vào đâu => tạo section mới và đưa vào cuối cùng

      if (item instanceof BaseElement) {
        if (relativeGroup == ItemRelativeGroup.Section || relativeGroup == ItemRelativeGroup.Page || !this.$$$ || isPbSection(this.$$$)) {
          let block = new BlockClass()
          block.elements.push(item)
          item = block
        }
      }

      if (item instanceof Block) {
        if (relativeGroup == ItemRelativeGroup.Page || !this.$$$) {
          let section = new SectionClass()
          // Do section luôn tạo 1 block rỗng
          section.blocks[0] = item
          //
          item = section
        }
      }

      const parent = this.$$$? this.getInsertPlacement(this.$$$, relativeGroup, item) as Block|Section|this: this
      switch (parseInt(placement.toString())) {
        case ItemRelative.AfterCurrentElement:
          position = RIGHT_AFTER
          break

        case ItemRelative.BeforeCurrentElement:
          position = RIGHT_BEFORE
          break

        case ItemRelative.AtTheBeginningOfCurrentBlock:
          position = THE_BEGINNING_OF
          break

        case ItemRelative.AtTheEndOfCurrentBlock:
          position = THE_END_OF
          break

        case ItemRelative.AtTheBeginningOfCurrentSection:
          position = THE_BEGINNING_OF
          break

        case ItemRelative.AtTheEndOfCurrentSection:
          position = THE_END_OF
          break

        case ItemRelative.AtTheBeginningOfThePage:
          position = THE_BEGINNING_OF
          break

        case ItemRelative.AtTheEndOfThePage:
          position = THE_END_OF
          break

        default:
          position = THE_END_OF
      }
      //
      parent.insert(item, {
        reference: this.$$$,
        pageBuilder: this,
        position
      })
      //
      this.setSelection(selectedUUID)
      //
      hidePageBuilderContextMenu()
      //
      scrollPageBuilderSelectionIntoView()
    }
  }
}

export function updatePageBuilderScrollbars() {
  nextTick(function() {
    window.dispatchEvent(new CustomEvent('pbUpdateScrollbar'))
  })
}
//
export function hidePageBuilderContextMenu() {
  nextTick(function() {
    window.dispatchEvent(new CustomEvent('hidePbContextMenu'))
  })
}
//
export function scrollPageBuilderSelectionIntoView() {
  setTimeout(function() {
    window.dispatchEvent(new CustomEvent('pbScrollSelectionIntoView'))
  }, 100)
}

setBlockClass(_Block)
setSectionClass(_Section)