import api from "../../api"
import { csrfToken } from "../../csrf"
import { Icon, showDialog } from "../../dialogs"
import { blockForm, hasInputError } from "../../utils"
import { Member } from "./member"
import { TotalProtectionOptions } from "./types/total_protection_options"

declare var campaignForm: HTMLFormElement

export class CampaignCreate {
  /**
   * 
   */
  public working: boolean = false
  //
  public totalProtectionOptions: TotalProtectionOptions = new TotalProtectionOptions()

  constructor(
    public id: number = 0,
    public name: string = '',
    public limitTraffic: boolean = false,
    public quota: number|'' = '',
    public totalProtectionEnabled: boolean = true,
    public members: Member[] = [],
    public currentMember: Member = null,
  ) {
    //
    this.reset()
  }

  public reset() {
    this.currentMember = null
  }

  public editMember(member: Member, $dispatch: Function): void {
    this.currentMember = member
    $dispatch('editMember', {
      member: this.currentMember.clone(),
      edit: true
    })
  }

  public addNewMember($dispatch): void {
    $dispatch('editMember', {
      member: new Member(),
      edit: false
    })
  }

  public getCurrentMember(): Member {
    return this.currentMember
  }

  public save(member: Member) {
    if (this.currentMember) {
      let memberIndex = -1
      for (let i in this.members) {
        if (this.members[i] != this.currentMember) {
          if (this.members[i].email.toLowerCase() == member.email.toLowerCase()) {
            return showDialog('Email is already in the list', { icon: Icon.Alert } )
          }
        } else {
          memberIndex = parseInt(i)
        }
      }
      //
      if (memberIndex >= 0) {
        this.members[memberIndex] = member.clone()
      }
    } else {
      for (let i in this.members) {
        if (this.members[i].email.toLowerCase() == member.email.toLowerCase()) {
          return showDialog('Email is already in the list', { icon: Icon.Alert } )
        }
      }      
      this.members.push(member.clone())
    }
    //
    this.reset()
  }

  public removeMember(member: Member): void {
    this.members = this.members.filter(i => i != member)
  }

  public get count(): number {
    return this.members.length
  }

  public json(): string {
    const { $event, id, working, currentMember, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit() {
    if (!hasInputError(campaignForm)) {
      try {
        this.working = true
        //
        blockForm(campaignForm, true)
        //
        const res = await fetch(api.CAMPAIGN_CREATE_URI, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken()
          },        
          body: this.json()
        })
        //
        const json = await res.json()
        //
        if (json.success) {
          location.href = api.CAMPAIGN_PAGE_URI + '/' + json.campaign_id
        } else {
          showDialog(json.message, {
            icon: Icon.Alert
          })
        }
      } finally {
        this.working = false
        blockForm(campaignForm, false)
      }
    }
  }
}