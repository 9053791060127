import api from "../../api"
import { csrfToken } from "../../csrf"
import { showError } from "../../dialogs"
import { blockForm, hasInputError } from "../../utils"

declare var pageForm: HTMLFormElement

export class PageCreate {
  public domainId: string = ''
  public path: string = ''
  /**
   *
   */
  public working: boolean = false

  constructor(
    public id: number = 0,
    public name: string = '',
    public template: string = ''
  ) {
    //
    this.reset()
  }

  public reset() {

  }


  public json(): string {
    const { $event, id, working, ...result } = JSON.parse(JSON.stringify(this))
    return JSON.stringify(result)
  }

  public async submit() {
    if (!hasInputError(pageForm)) {
      try {
        this.working = true
        //
        blockForm(pageForm, true)
        //
        const res = await fetch(api.PAGE_CREATE_URI, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-csrf-token': csrfToken()
          },
          body: this.json()
        })
        //
        const json = await res.json()
        //
        if (json.success) {
          // Chuyển sang page/builder
          location.href = api.setParams({ page_id: json.id }) .PAGE_BUILDER_URI
        } else {
          showError(json.message)
        }
      } finally {
        this.working = false
        blockForm(pageForm, false)
      }
    }
  }
}