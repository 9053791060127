import { makeid } from "#page-builder/utils";
import { htmlEncode } from "./utils";
export var ToastType;
(function (ToastType) {
    ToastType["Info"] = "info";
    ToastType["Success"] = "success";
    ToastType["Warning"] = "warning";
    ToastType["Danger"] = "danger";
    ToastType["Error"] = "error";
})(ToastType || (ToastType = {}));
class ToastManager {
    toastContainer;
    initToastContainer() {
        if (!this.toastContainer) {
            this.toastContainer = document.createElement('div');
            this.toastContainer.className = 'toast-container position-fixed p-3 top-0 start-50 translate-middle-x';
            document.body.appendChild(this.toastContainer);
        }
    }
    addMessage(text, caption = '', type = ToastType.Info) {
        this.initToastContainer();
        const id = makeid(10);
        this.toastContainer.insertAdjacentHTML('beforeend', `
      <div id="toast-${id}" class="toast toast-${type == ToastType.Error ? 'danger' : type}" role="alert" aria-live="assertive" aria-atomic="true">
      
        <div class="toast-header"> 
          <i class="bi bi-circle me-2"></i>
          
          <strong class="me-auto text-capitalize">${caption ? htmlEncode(caption) : type}</strong>
          
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button> 
        </div>
      
      
        <div class="toast-body">
          ${htmlEncode(text)}
        </div>
      </div>
    `);
        bootstrap.Toast.getOrCreateInstance(this.toastContainer.lastElementChild).show();
        return this;
    }
    addError(text, caption = '') {
        return this.addMessage(text, caption, ToastType.Error);
    }
    addWarning(text, caption = '') {
        return this.addMessage(text, caption, ToastType.Warning);
    }
}
export const toasts = new ToastManager();
export default toasts;
