import apiEndpoints from "../../api";
import { _fetchJson, _fetchJson2 } from "../../_fetch";
import { csrfToken } from "../../csrf";
import { showSuccess } from "../../dialogs";
import { fireEvent } from "ts/autoload/utils";
export class EditPageName {
    id;
    name;
    _modal;
    loading = true;
    working = false;
    constructor(id = 0, name = '') {
        this.id = id;
        this.name = name;
    }
    async refresh() {
        try {
            this.loading = true;
            const json = await _fetchJson(apiEndpoints.setParams({ page_id: this.id }).PAGE_JSON_URI);
            if (json) {
                Object.assign(this, json);
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal() {
        this.loading = true;
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
    json() {
        const { $event, working, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    async submit() {
        try {
            this.working = true;
            const res = await _fetchJson2(apiEndpoints.PAGE_EDIT_NAME_URI, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: this.json()
            });
            if (res) {
                showSuccess('The page name has been changed');
                fireEvent('updatePageTitle', { title: `Page: <em>${this.name}</em>` });
            }
        }
        finally {
            this.working = false;
            this.closeModal();
        }
    }
}
