import apiEndpoints from "../../api";
import { _fetchJson } from "../../_fetch";
import { csrfToken } from "../../csrf";
import { showError, showSuccess } from "../../dialogs";
import { hasInputError } from "../../utils";
export class DataPlanModal {
    _modal;
    working;
    loading = false;
    userId = 0;
    planId = '';
    email = '';
    json() {
        const { $event, loading, working, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    async submit() {
        if (!hasInputError(dataPlanModal)) {
            try {
                this.working = true;
                const json = await _fetchJson(apiEndpoints.setParams({ user_id: this.userId }).DATA_PLAN, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken()
                    },
                    body: this.json()
                });
                if (json) {
                    showSuccess('The user has been saved.');
                    this.closeModal();
                }
            }
            finally {
                this.working = false;
            }
        }
    }
    async refresh() {
        this.planId = '';
        if (!this.userId) {
            return showError('Error X1VqCrQqCf');
        }
        try {
            const json = await _fetchJson(apiEndpoints.setParams({ user_id: this.userId }).DATA_PLAN);
            if (json) {
                this.planId = json.planId || '';
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(jsonStr) {
        const json = jsonStr ? JSON.parse(jsonStr) : null;
        if (json) {
            this.userId = json.id;
            this.planId = json.planId;
            this.email = json.email;
            this.loading = false;
            this.refresh();
            bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
        }
        else {
            showError('Error kNI9neQpvW');
        }
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
}
