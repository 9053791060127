import { CssSizeValue, Typography } from "#page-builder/types/common";

export enum SurveyStyle {
  Style1 = 'style-1',
  Style2 = 'style-2',
  Style3 = 'style-3',
}

export function getSurveyStyle(scope: string, btnWidth: CssSizeValue, typography: Typography ): string {
  return /* html */`
    <style type="text/css">
      ${scope} .choices {
        display: inline-grid;
        margin: 1em auto 0;
        justify-content: center;
        grid-template-columns: repeat(1, 1fr);
        gap: 0.25em;
        width: ${btnWidth.toCss()};
      }

      ${scope} .question {
        ${typography.cssStyle}
      }
    </style>
  `
}