import apiEndpoints from "../../api";
import { csrfToken } from "../../csrf";
import { Icon, showDialog } from "../../dialogs";
import { blockForm, hasInputError } from "../../utils";
import { Member } from "./member";
import { TotalProtectionOptions } from "./types/total_protection_options";
export class CampaignCreate {
    id;
    name;
    limitTraffic;
    quota;
    totalProtectionEnabled;
    members;
    currentMember;
    working = false;
    totalProtectionOptions = new TotalProtectionOptions();
    constructor(id = 0, name = '', limitTraffic = false, quota = '', totalProtectionEnabled = true, members = [], currentMember = null) {
        this.id = id;
        this.name = name;
        this.limitTraffic = limitTraffic;
        this.quota = quota;
        this.totalProtectionEnabled = totalProtectionEnabled;
        this.members = members;
        this.currentMember = currentMember;
        this.reset();
    }
    reset() {
        this.currentMember = null;
    }
    editMember(member, $dispatch) {
        this.currentMember = member;
        $dispatch('editMember', {
            member: this.currentMember.clone(),
            edit: true
        });
    }
    addNewMember($dispatch) {
        $dispatch('editMember', {
            member: new Member(),
            edit: false
        });
    }
    getCurrentMember() {
        return this.currentMember;
    }
    save(member) {
        if (this.currentMember) {
            let memberIndex = -1;
            for (let i in this.members) {
                if (this.members[i] != this.currentMember) {
                    if (this.members[i].email.toLowerCase() == member.email.toLowerCase()) {
                        return showDialog('Email is already in the list', { icon: Icon.Alert });
                    }
                }
                else {
                    memberIndex = parseInt(i);
                }
            }
            if (memberIndex >= 0) {
                this.members[memberIndex] = member.clone();
            }
        }
        else {
            for (let i in this.members) {
                if (this.members[i].email.toLowerCase() == member.email.toLowerCase()) {
                    return showDialog('Email is already in the list', { icon: Icon.Alert });
                }
            }
            this.members.push(member.clone());
        }
        this.reset();
    }
    removeMember(member) {
        this.members = this.members.filter(i => i != member);
    }
    get count() {
        return this.members.length;
    }
    json() {
        const { $event, id, working, currentMember, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    async submit() {
        if (!hasInputError(campaignForm)) {
            try {
                this.working = true;
                blockForm(campaignForm, true);
                const res = await fetch(apiEndpoints.CAMPAIGN_CREATE_URI, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken()
                    },
                    body: this.json()
                });
                const json = await res.json();
                if (json.success) {
                    location.href = apiEndpoints.CAMPAIGN_PAGE_URI + '/' + json.campaign_id;
                }
                else {
                    showDialog(json.message, {
                        icon: Icon.Alert
                    });
                }
            }
            finally {
                this.working = false;
                blockForm(campaignForm, false);
            }
        }
    }
}
