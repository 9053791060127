import apiEndpoints from "../../api";
import { Icon, showDialog } from "../../dialogs";
import { hideLoadingIndicator, showLoadingIndicator } from "../../loading-indicator";
export class CampaignOverview {
    period = 4;
    requests = 0;
    visitors = 0;
    conversions = 0;
    blockedTotal = 0;
    blocked = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
        13: 0,
        14: 0,
        15: 0
    };
    blocked_1 = 0;
    blocked_2 = 0;
    blocked_3 = 0;
    blocked_4 = 0;
    blocked_5 = 0;
    blocked_6 = 0;
    blocked_7 = 0;
    blocked_8 = 0;
    blocked_9 = 0;
    blocked_10 = 0;
    blocked_11 = 0;
    blocked_12 = 0;
    blocked_13 = 0;
    blocked_14 = 0;
    blocked_15 = 0;
    async refresh(showLoading = true) {
        if (typeof _chart == 'undefined')
            return;
        try {
            showLoading && showLoadingIndicator();
            const res = await fetch(apiEndpoints.CAMPAIGN_STATISTICS_URI + `?type=${this.period}`, {});
            if (!res.ok) {
                throw new Error(res.statusText);
            }
            const json = await res.json();
            Object.assign(this, json);
            for (let i = 1; i <= 15; i++) {
                this[`blocked_${i}`] = this.blocked[i];
            }
            this.updateChart();
        }
        catch (e) {
            showDialog(e.message, {
                icon: Icon.Alert
            });
        }
        finally {
            showLoading && hideLoadingIndicator();
        }
    }
    updateChart() {
        _chart.updateOptions({
            series: [
                {
                    name: "Requests",
                    data: this.breakdown.requests,
                },
                {
                    name: "Unique Visitors",
                    data: this.breakdown.visitors,
                },
                {
                    name: "Conversions",
                    data: this.breakdown.conversions,
                },
                {
                    name: "Blocked",
                    data: this.breakdown.blocked,
                },
            ],
            xaxis: {
                categories: this.breakdown.labels
            }
        });
    }
}
