import { showError } from "../../dialogs";
import { ItemRelative } from "#page-builder/types/common";
export class PageBuilderAddNew {
    _modal;
    template = '';
    placement = ItemRelative.AfterCurrentElement;
    showModal() {
        this.template = '';
        this.placement = ItemRelative.AfterCurrentElement;
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
    async submit($dispatch) {
        if (!this.template) {
            return showError('Please select a template');
        }
        $dispatch('pbAddElement', { template: this.template, placement: this.placement });
        this.closeModal();
    }
}
