export class Datatable {
  protected _filteredItems = []
  protected _selectAllCheckbox: HTMLInputElement

  protected _currentPage: number = 0
  public itemsPerPage: number = 100

  constructor(
    protected _items: any[] = [],
    // Do this.filterItems() được thực thi ngay lập tức khi new nên cần truyền _search để khởi tạo danh sách cần hiển thị
    // Ví dụ: archived = false
    protected _search: any = null
  ) {
    this.filterItems()
  }

  protected isEqual(a: any, b: any): boolean {
    return a === b
  }

  protected matchesSearchCriteria(_item: any): boolean {
    return true
  }

  protected doBeforePageHide(): void { }

  protected doAfterPageShow(): void { }

  private checkIndeterminate() {
    if (this._selectAllCheckbox) {
      let hasSelected = false
      let hasUnSelected = false
      for (let i of this._filteredItems) {
        if (this.isSelected(i)) {
          hasSelected = true
        } else {
          hasUnSelected = true
        }
        //
        if (hasSelected && hasUnSelected) {
          break
        }
      }

      this._selectAllCheckbox.indeterminate = hasSelected && hasUnSelected
    }
  }

  protected filterItems() {
    this._filteredItems = this._items.filter(i => {
      let result = !this._search || this.matchesSearchCriteria(i)
      return result
    })
  }

  public doSearch(keepCurrentPagerIndex: boolean = false) {
    try {
      this.doBeforePageHide()
      //
      this.filterItems()
      //
      if (!keepCurrentPagerIndex) {
        this.currentPage = 0
        this.doAfterPageShow()        
      } else if (this.currentPage >= this.pageCount) {
        this.currentPage = this.currentPage - 1
        this.doAfterPageShow()
      }
    } finally {
      this.checkIndeterminate()
    }
  }

  public get currentPage(): number {
    return this._currentPage
  }

  public set currentPage(value: number) {
    if (value >= 0 && value < this.pageCount) {
      this._currentPage = value
    } else {
      this._currentPage = 0
    }
  }

  public get search(): any {
    return this._search
  }

  public set search(value: any) {
    try {
      this.doBeforePageHide()
      this._search = value
      this.filterItems()
      this.currentPage = 0
      this.doAfterPageShow()
    } finally {
      this.checkIndeterminate()
    }
  }

  public get items() {
    return this._filteredItems
  }

  public get currentPageItems() {
    let result = []
    for (let i = this.currentPage * this.itemsPerPage; i < (this.currentPage + 1) * this.itemsPerPage; i++) {
      if (i < this._filteredItems.length) {
        result.push(this._filteredItems[i])
      }
    }
    //
    return result
  }

  public get pageCount() {
    return Math.ceil(this._filteredItems.length / this.itemsPerPage)
  }

  public get firstShowingItem(): number {
    return this._filteredItems.length? this.currentPage * this.itemsPerPage + 1 : 0
  }

  public get lastShowingItem(): number {
    const result = this._filteredItems.length? (this.currentPage + 1) * this.itemsPerPage : 0
    return Math.min(this._filteredItems.length, result)
  }

  public get numOfItems(): number {
    return this._filteredItems.length
  }

  public clear(): void {
    this._items = []
    this._currentPage = 0
    this.filterItems()
  }

  public gotoPage(page: number): void {
    if (page >= 0 && page < this.pageCount)  {
      this.doBeforePageHide()
      this.currentPage = page
      this.doAfterPageShow()
    }
  }

  public add(item: any) {
    this._items.push(item)
    this.doSearch()
  }

  public addItems(items: any[]): void {
    this._items.push(...items)
    this.doSearch()
  }

  public remove(item: any) {
    this._items = this._items.filter(i => !this.isEqual(i, item))
    this._filteredItems = this._filteredItems.filter(i => !this.isEqual(i, item))
  }

  public select(item: any, select: boolean) {
    item.__selected = select
    this.checkIndeterminate()
  }

  public selectAll(select: boolean) {
    for (let item of this._filteredItems) {
      this.select(item, select)
    }
  }

  public isSelected(item: any): boolean {
    return !!item.__selected
  }

  public get isAllSelected(): boolean|undefined {
    for (let i of this._filteredItems) {
      if (!this.isSelected(i)) {
        return false
      }
    }
    return true
  }

  public get allPagerItems(): number[] {
    let result = []
    for (let i = 0; i < this.pageCount; i++) {
      result.push(i + 1)
    }
    return result
  }
}