import { makeid10 } from "#page-builder/utils";
import { Background, BgStyle, Borders, BoxShadow, D3Style, d3StyleToCss, Metadata, ShadowStyle, shadowStyleToCss, } from "#page-builder/types/common";
export var ButtonType;
(function (ButtonType) {
    ButtonType[ButtonType["Continue"] = 1] = "Continue";
    ButtonType[ButtonType["CallANumber"] = 2] = "CallANumber";
    ButtonType[ButtonType["OpenPage"] = 3] = "OpenPage";
    ButtonType[ButtonType["GoToUrl"] = 4] = "GoToUrl";
})(ButtonType || (ButtonType = {}));
export var ButtonTarget;
(function (ButtonTarget) {
    ButtonTarget["None"] = "";
    ButtonTarget["Blank"] = "_blank";
    ButtonTarget["Self"] = "_self";
    ButtonTarget["TopmostFrame"] = "_top";
    ButtonTarget["ParentFrame"] = "_parent";
})(ButtonTarget || (ButtonTarget = {}));
export const BUTTON_WIDTH_CSS_VAR = '--pb-button-width';
export class ButtonStyle {
    uuid = makeid10();
    name = 'New Button Style';
    color = '#ffffff';
    icon = '';
    iconSvg = '';
    iconSize = 100;
    iconSpacing = 50;
    fontSize = 100;
    borders = new Borders();
    background = new Background({
        style: BgStyle.SolidColor,
        color: {
            color: '#1163ce',
            opacity: 100
        }
    });
    boxShadow = new BoxShadow();
    paddingX = 75;
    paddingY = 75;
    borderRadius = 25;
    shadow = ShadowStyle.None;
    d3style = D3Style.None;
    opacity = 100;
    constructor(options = {}) {
        this.merge(options);
    }
    get pojo() {
        return {
            uuid: this.uuid,
            name: this.name,
            color: this.color,
            icon: this.icon,
            iconSvg: this.iconSvg,
            iconSize: this.iconSize,
            iconSpacing: this.iconSpacing,
            fontSize: this.fontSize,
            borders: this.borders.pojo,
            background: this.background.pojo,
            boxShadow: this.boxShadow.pojo,
            paddingX: this.paddingX,
            paddingY: this.paddingY,
            borderRadius: this.borderRadius,
            shadow: this.shadow,
            d3style: this.d3style,
            opacity: this.opacity
        };
    }
    merge(that) {
        this.uuid = that.uuid || makeid10();
        this.name = that.name || 'New Button Style';
        this.color = that.color || '#ffffff';
        this.icon = that.icon || '';
        this.iconSvg = that.iconSvg || '';
        this.iconSize = that.iconSize || 100;
        this.iconSpacing = that.iconSpacing || 50;
        this.fontSize = that.fontSize || 100;
        this.shadow = that.shadow || ShadowStyle.None;
        this.d3style = that.d3style || D3Style.None;
        this.paddingX = that.paddingX || 75;
        this.paddingY = that.paddingY || 75;
        this.borderRadius = that.borderRadius || 25;
        this.opacity = that.opacity || 100;
        that.borders && this.borders.fromJSON(that.borders);
        that.background && this.background.fromJSON(that.background);
        that.boxShadow && this.boxShadow.fromJSON(that.boxShadow);
        return this;
    }
    fromJSON(json) {
        return this.merge(json);
    }
    get btnStyle() {
        let result = [];
        if (this.paddingX) {
            result.push(`padding-left: ${(this.paddingX / 100).toFixed(2)}em`);
            result.push(`padding-right: ${(this.paddingX / 100).toFixed(2)}em`);
        }
        if (this.paddingY) {
            result.push(`padding-top: ${(this.paddingY / 100).toFixed(2)}em`);
            result.push(`padding-bottom: ${(this.paddingY / 100).toFixed(2)}em`);
        }
        result.push(`color: ${this.color}`);
        result.push(`background-color: ${this.background.color.color}`);
        result.push(`opacity: ${this.opacity}%`);
        result.push(`border-radius: ${(this.borderRadius / 100).toFixed(2)}em`);
        result.push(`font-size: ${(this.fontSize / 100).toFixed(2)}em`);
        result.push(`width: var(${BUTTON_WIDTH_CSS_VAR})`);
        result.push(this.borders.cssStyle);
        const shadow = [];
        if (this.shadow != ShadowStyle.None) {
            shadow.push(shadowStyleToCss(this.shadow));
        }
        if (this.d3style != D3Style.None) {
            shadow.push(d3StyleToCss(this.d3style));
        }
        if (shadow.length) {
            result.push(`box-shadow: ${shadow.join(',')}`);
        }
        return result.filter(i => i).join(';');
    }
    get iconStyle() {
        if (this.iconSvg) {
            return `line-height: 1em; display: inline-flex; align-items: center; justify-content: center; margin-right: ${(this.iconSpacing / 100).toFixed(2)}em`;
        }
        return '';
    }
    get iconFinalSvg() {
        return this.iconSvg ? this.iconSvg.replaceAll(`'16'`, `${(this.iconSize / 100).toFixed(2)}em`) : '';
    }
    get text() {
        return 'Preview';
    }
    clearIcon() {
        this.icon = '';
        this.iconSvg = '';
    }
    isSame(style) {
        if (typeof style == 'string') {
            return this.uuid = style;
        }
        else {
            return this.uuid == style.uuid;
        }
    }
}
var _resolveButtonStyle = function (_style) {
    return undefined;
};
export function setButtonStyleResolver(fn) {
    _resolveButtonStyle = fn;
}
export function resolveButtonStyle(style) {
    return _resolveButtonStyle ? _resolveButtonStyle(style) : undefined;
}
export class ButtonStyleManager {
    _styles = [];
    _defaultStyle = '';
    editing = false;
    editStyle = new ButtonStyle();
    setAsDefault = false;
    constructor(options = {}) {
        this.merge(options);
    }
    setAsStyleResolver() {
        const self = this;
        setButtonStyleResolver(function (style) {
            return self._styles.find(i => i.uuid == style) || self.defaultStyle;
        });
    }
    merge(that) {
        this._defaultStyle = that._defaultStyle || '';
        if (Array.isArray(that.styles)) {
            this.styles = that.styles.map(i => new ButtonStyle(i));
        }
        else {
            this.styles = [new ButtonStyle()];
        }
        return this;
    }
    fromJSON(json) {
        return this.merge(json);
    }
    get pojo() {
        return {
            styles: this._styles.map(i => i.pojo),
            defaultStyle: this._defaultStyle
        };
    }
    move(style, index) {
        if (index >= 0 && index < this._styles.length) {
            const array = this._styles.filter(i => i.isSame(style));
            array.splice(index, 0, style);
            this._styles = array;
        }
    }
    moveUp(style) {
        const index = this._styles.findIndex(i => i.isSame(style));
        this.move(style, index - 1);
    }
    moveDown(style) {
        const index = this._styles.findIndex(i => i.isSame(style));
        this.move(style, index + 1);
    }
    newStyle() {
        this.editing = true;
        this.editStyle = new ButtonStyle();
    }
    edit(style) {
        this.editing = true;
        this.editStyle = new ButtonStyle(style);
        this.editStyle._src = style;
        this.setAsDefault = (style.uuid == this._defaultStyle);
    }
    $editStyle(uuid) {
        const style = !uuid ? this.defaultStyle : this.styles.find(i => i.uuid == uuid || i.uuid == this._defaultStyle);
        if (style) {
            this.edit(style);
            return true;
        }
        else {
            showError(`Error wIlDOefpk4: style <em>${uuid}</em> not found`);
        }
    }
    delete(style) {
        if (this._defaultStyle == style.name) {
            this._defaultStyle = '';
        }
        const showDefaultStyleAdded = this._styles.length == 1 && this._styles[0].uuid == style.uuid;
        this.styles = this._styles.filter(i => !i.isSame(style));
        if (showDefaultStyleAdded) {
            showInfo(`Button style <em>${style.name}</em> is removed and new button style is created as <em>default</em> style`);
        }
        else {
            showInfo(`Button style <em>${style.name}</em> is removed`);
        }
        fireEvent('buttonStyleChange');
    }
    $deleteStyle(uuid) {
        const style = this._styles.find(i => i.uuid == uuid);
        if (style) {
            this.delete(style);
        }
    }
    cancel() {
        this.editing = false;
    }
    save() {
        if (this.editing) {
            for (let i of this._styles) {
                if (i.name.localeCompare(this.editStyle.name, undefined, { sensitivity: "base" }) == 0 && this.editStyle._src != i) {
                    showError(`The style <em>${i.name}</em> already exists. Please choose another name!`);
                    return;
                }
            }
            if (typeof this.editStyle._src == 'object') {
                this.editStyle._src.merge(this.editStyle);
            }
            else {
                this._styles.push(this.editStyle);
            }
            if (this.setAsDefault) {
                this._defaultStyle = this.editStyle.uuid;
            }
            fireEvent('buttonStyleChange');
            this.editing = false;
            return true;
        }
    }
    showButtonStyleModal(modal) {
        if (this.editStyle)
            this.editStyle.fromJSON(this.editStyle.pojo);
        bootstrap.Modal.getOrCreateInstance(modal, {}).show();
    }
    get styles() {
        return this._styles;
    }
    set styles(value) {
        this._styles = value;
        if (!this._styles || !this._styles.length) {
            this._styles = [
                new ButtonStyle({ name: 'Default' })
            ];
        }
    }
    get defaultStyle() {
        const result = this.styles.find(i => i.uuid == this._defaultStyle);
        if (result)
            return result;
        if (!this.styles.length) {
            this.styles = [new ButtonStyle({ name: 'Default' })];
        }
        return this.styles[0];
    }
}
export class Button {
    _type = ButtonType.Continue;
    uuid = '';
    text = '';
    value = '';
    target = ButtonTarget.None;
    styleUUID = '';
    meta = new Metadata();
    constructor(options = {}) {
        this.merge(options);
    }
    get isContinueButton() {
        return this.type == ButtonType.Continue;
    }
    get isCallANumberButton() {
        return this.type == ButtonType.CallANumber;
    }
    get isGoToUrlButton() {
        return this.type == ButtonType.GoToUrl;
    }
    get isOpenPageButton() {
        return this.type == ButtonType.OpenPage;
    }
    get href() {
        if (this.isCallANumberButton) {
            return 'tel:' + this.value;
        }
        return this.value;
    }
    get type() {
        return this._type;
    }
    set type(value) {
        if (this._type != value) {
            this._type = value;
            this.value = '';
        }
    }
    get btnStyle() {
        const style = resolveButtonStyle(this.styleUUID);
        return style ? style.btnStyle : '';
    }
    get iconStyle() {
        const style = resolveButtonStyle(this.styleUUID);
        return style ? style.iconStyle : '';
    }
    get iconFinalSvg() {
        const style = resolveButtonStyle(this.styleUUID);
        return style ? style.iconFinalSvg : '';
    }
    merge(that) {
        this.uuid = that.uuid || makeid10();
        this.text = that.text || '';
        this.value = that.value || '';
        this.target = that.target || ButtonTarget.None;
        this._type = that.type || ButtonType.Continue;
        this.styleUUID = that.styleUUID || '';
        if (that.meta) {
            this.meta.id = that.meta.id || '';
            this.meta.class = that.meta.class || '';
        }
        else {
            this.meta.id = '';
            this.meta.class = '';
        }
        return this;
    }
    validate() {
        if (!this.text) {
            alert('Text is required');
            return false;
        }
        if (!this.value && this.isCallANumberButton) {
            alert('Number is required');
            return false;
        }
        if (!this.value && this.isGoToUrlButton) {
            alert('URL is required');
            return false;
        }
        if (!this.value && this.isOpenPageButton) {
            alert('Target page is required');
            return false;
        }
        return true;
    }
    get pojo() {
        return {
            uuid: this.uuid,
            text: this.text,
            value: this.value,
            type: this.type,
            target: this.target,
            styleUUID: this.styleUUID,
            meta: this.meta.pojo
        };
    }
    fromJSON(json) {
        return this.merge(json);
    }
    repaint() {
        const uuid = this.styleUUID;
        (this.styleUUID = makeid10()) && (this.styleUUID = uuid);
    }
}
