//@ts-nocheck
document.addEventListener('alpine:init', () => {

  function setDropdownValue(dropdown, value) {
    if (typeof dropdown._x_model !== 'undefined')
      dropdown._x_model.set(value)
    else
      dropdown.value = value
  }

  Alpine.directive('tel', (el: HTMLElement, { value }, { evaluate } ) => {
    if ((el as any).initialized) return
    //
    const $refs = evaluate('$refs')
    //
    function countrychange() {
      if (countryDropdown) {
        const val = iti.getSelectedCountryData().iso2
        countryDropdown.value = val

        if (val == 'us') {
          if (stateDropdown.value == 'NON-US') {
            setDropdownValue(stateDropdown, '')
          }
          stateDropdown.style.removeProperty('display')
          stateOther.style.setProperty('display', 'none', 'important')
          setDropdownValue(stateOther, '')
          //@ts-ignore
          nonUSOption.setAttribute('disabled', true)
        } else {
          nonUSOption.removeAttribute('disabled')
          setDropdownValue(stateDropdown, 'NON-US')
          stateDropdown.style.setProperty('display', 'none', 'important')
          stateOther.style.removeProperty('display')
        }
      }
    }
    //
    const iti = (window as any).intlTelInput(el, {
      initialCountry: value || 'us',
      strictMode: true,
      showSelectedDialCode: true,
      nationalMode: true,
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@21.2.5/build/js/utils.js",
    })
    ;(el as any).initialized = true
    //
    const countryDropdown = typeof $refs.countryDropdown != 'undefined'? $refs.countryDropdown as HTMLSelectElement: null
    const stateDropdown = typeof $refs.stateDropdown != 'undefined'? $refs.stateDropdown as HTMLSelectElement: null
    const nonUSOption = stateDropdown? stateDropdown.querySelector('option[value="NON-US"]'): null;
    const stateOther = typeof $refs.stateOther != 'undefined'? $refs.stateOther as HTMLSelectElement: null
    // populate the country dropdown
    if (countryDropdown) {
      const countryData = (window as any).intlTelInputGlobals.getCountryData()
      for (let i = 0; i < countryData.length; i++) {
        const country = countryData[i]
        const optionNode = document.createElement("option")
        optionNode.value = country.iso2
        const textNode = document.createTextNode(country.name)
        optionNode.appendChild(textNode)
        countryDropdown.appendChild(optionNode)
      }
      // listen to the address dropdown for changes
      countryDropdown.addEventListener('change', function (e: any) {
        iti.setCountry(e.target.value)
      });
    }
    // listen to the telephone input for changes
    el.addEventListener('countrychange', function () {
      countrychange()
      el.dispatchEvent(new Event('input', { bubbles: true }))
    })
    // set it's initial value
    queueMicrotask(function() {
      countrychange()
    })

  })

})