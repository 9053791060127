import apiEndpoints from "../../api";
import { csrfToken } from "../../csrf";
import { showError } from "../../dialogs";
import { blockForm, hasInputError } from "../../utils";
export class PageCreate {
    id;
    name;
    template;
    domainId = '';
    path = '';
    working = false;
    constructor(id = 0, name = '', template = '') {
        this.id = id;
        this.name = name;
        this.template = template;
        this.reset();
    }
    reset() {
    }
    json() {
        const { $event, id, working, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    async submit() {
        if (!hasInputError(pageForm)) {
            try {
                this.working = true;
                blockForm(pageForm, true);
                const res = await fetch(apiEndpoints.PAGE_CREATE_URI, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'x-csrf-token': csrfToken()
                    },
                    body: this.json()
                });
                const json = await res.json();
                if (json.success) {
                    location.href = apiEndpoints.setParams({ page_id: json.id }).PAGE_BUILDER_URI;
                }
                else {
                    showError(json.message);
                }
            }
            finally {
                this.working = false;
                blockForm(pageForm, false);
            }
        }
    }
}
