document.addEventListener('alpine:init', () => {
    Alpine.directive('log', (el, { expression }, { evaluateLater, effect }) => {
        let getThingToLog = evaluateLater(expression);
        effect(() => {
            getThingToLog(thingToLog => {
                console.log(thingToLog);
            });
        });
    });
});
