import apiEndpoints from "../../api";
import { _fetchJson, _fetchJson2 } from "../../_fetch";
import { csrfToken } from "../../csrf";
import { ButtonKind, showSuccess, showWarning } from "../../dialogs";
import { makeid } from "#page-builder/utils";
import { Datatable } from "../datatable";
import Placement from "./types/placement";
import ResourceType from "./types/resource_type";
export class ResourceModel {
    id;
    _modal;
    working;
    loading = false;
    name = 'Javascript-' + makeid(4);
    placement = Placement.BeforeBodyEndTag;
    body = '';
    enabled = true;
    archived = false;
    formatedUpdatedAt = '';
    activeDomains = [];
    usedOnDomains = [];
    activePages = [];
    includedOnPages = [];
    constructor(id = 0) {
        this.id = id;
    }
    json() {
        const { $event, loading, working, body, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify({ ...result, body: codemirror.getContent() });
    }
    async submit($dispatch) {
        try {
            this.working = true;
            const json = await _fetchJson(apiEndpoints.RESOURCE_CREATE_OR_UPDATE, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: this.json()
            });
            if (json) {
                if (json.hasChanges) {
                    const self = this;
                    showWarning('The Javascript has been saved but the pages that use this script <b>still not published with new changes</b>! Would you like to publish now?', {
                        buttons: [
                            {
                                kind: ButtonKind.No,
                                dismiss: true,
                            },
                            {
                                kind: ButtonKind.Yes,
                                dismiss: true,
                                onclick: function () {
                                    self.publish();
                                }
                            }
                        ]
                    });
                }
                else {
                    showSuccess('The Javascript has been saved');
                }
                this.closeModal();
                $dispatch(!parseInt(this.id) ? 'addResourceSuccess' : 'editResourceSuccess', JSON.stringify(json));
            }
        }
        finally {
            this.working = false;
        }
    }
    async publish() {
        const res = await _fetchJson2(apiEndpoints.setParams({ resource_id: this.id }).RESOURCE_PUBLICATION, {
            method: 'POST',
        });
        if (res) {
            showSuccess(`The pages which the script associated have been published`);
        }
    }
    async archive($dispatch) {
        try {
            this.working = true;
            const json = await _fetchJson(apiEndpoints.setParams({ resource_id: this.id }).RESOURCE_ARCHIVE, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: JSON.stringify({ archived: !this.archived })
            });
            if (json) {
                const self = this;
                showWarning(`The Javascript has been ${this.archived ? 'unarchived' : 'archived'} but the pages that use this script <b>still not published to reflect the change</b>! Would you like to publish now?`, {
                    buttons: [
                        {
                            kind: ButtonKind.No,
                            dismiss: true,
                        },
                        {
                            kind: ButtonKind.Yes,
                            dismiss: true,
                            onclick: function () {
                                self.publish();
                            }
                        }
                    ]
                });
                this.archived = json.archived;
                this.closeModal();
                $dispatch('editResourceSuccess', JSON.stringify(json));
            }
        }
        finally {
            this.working = false;
        }
    }
    async refresh() {
        try {
            if (!this.id) {
                const json = await _fetchJson(apiEndpoints.CAMPAIGN_ACTIVE_DOMAINS_AND_PAGES_URI);
                this.name = 'Javascript-' + makeid(4);
                this.body = '';
                this.placement = Placement.BeforeBodyEndTag;
                this.enabled = true;
                this.archived = false;
                this.usedOnDomains = [];
                this.activeDomains = json.activeDomains;
                this.includedOnPages = [];
                this.activePages = json.activePages;
                return;
            }
            else {
                this.loading = true;
                const json = await _fetchJson(apiEndpoints.setParams({ resource_id: this.id }).RESOURCE_JSON, {});
                if (json) {
                    this.id = json.id;
                    this.name = json.name;
                    this.placement = json.placement;
                    this.enabled = json.enabled;
                    this.archived = json.archived;
                    this.formatedUpdatedAt = json.formatedUpdatedAt;
                    this.usedOnDomains = json.usedOnDomains || [];
                    this.activeDomains = json.activeDomains;
                    this.includedOnPages = json.includedOnPages || [];
                    this.activePages = json.activePages;
                    codemirror.setContent(json.body);
                }
                else {
                    this.closeModal();
                }
            }
        }
        finally {
            this.loading = false;
        }
    }
    isUsedOnDomain(domain) {
        const result = this.usedOnDomains.includes(domain.id);
        return result;
    }
    setUsedOnDomain(domain, used) {
        if (!!used) {
            if (!this.usedOnDomains.includes(domain.id))
                this.usedOnDomains.push(domain.id);
        }
        else {
            this.usedOnDomains = this.usedOnDomains.filter(i => i != domain.id);
        }
    }
    isIncludedOnPage(page) {
        const result = this.includedOnPages.includes(page.id);
        return result;
    }
    setIncludedOnPage(page, used) {
        if (!!used) {
            if (!this.includedOnPages.includes(page.id))
                this.includedOnPages.push(page.id);
        }
        else {
            this.includedOnPages = this.includedOnPages.filter(i => i != page.id);
        }
    }
    showModal(jsonStr) {
        this.id = jsonStr ? JSON.parse(jsonStr).id : 0;
        this.loading = false;
        this.refresh();
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
}
class ResourceInfo {
    id;
    name;
    updated_at;
    enabled;
    archived;
    formatedUpdatedAt;
    type = ResourceType.Javascript;
    constructor(i) {
        Object.assign(this, i);
    }
    get resourceTypeClass() {
        switch (this.type) {
            case ResourceType.Javascript: return 'danger';
            default: return 'primary';
        }
    }
    get resourceTypeIcon() {
        switch (this.type) {
            case ResourceType.Javascript: return 'bi-filetype-js';
            default: return 'bi-filetype-css';
        }
    }
    get resourceTypeText() {
        switch (this.type) {
            case ResourceType.Javascript: return 'Javascript';
            default: return 'Stylesheet';
        }
    }
    toJSONStr() {
        return JSON.stringify(this);
    }
}
export class CampaignResources extends Datatable {
    static create(items) {
        let resource = [];
        for (let i of items) {
            const p = new ResourceInfo(i);
            resource.push(p);
        }
        return new CampaignResources(resource);
    }
    constructor(items) {
        super(items, {
            name: '',
            archived: false
        });
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            name: '',
            archived: false
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        const result = (item.name.toLowerCase().includes(this.search.name.toLowerCase()) || !this.search.name) && (!!item.archived == this.search.archived);
        return result;
    }
    addResource(info) {
        let json = JSON.parse(info);
        this.add(new ResourceInfo(json));
    }
    editResource(info) {
        let json = JSON.parse(info);
        for (let i of this._items) {
            if (i.id == json.id) {
                i.name = json.name;
                i.enabled = json.enabled;
                i.archived = !!json.archived;
                break;
            }
        }
        this.doSearch();
    }
}
