import { Datatable } from "../datatable";
var ConversionRateType;
(function (ConversionRateType) {
    ConversionRateType["Success"] = "success";
    ConversionRateType["Good"] = "good";
    ConversionRateType["Bad"] = "bad";
    ConversionRateType["Worse"] = "worse";
})(ConversionRateType || (ConversionRateType = {}));
export class HelpItem {
    caption;
    description;
    icon;
    constructor(i) {
        Object.assign(this, i);
    }
}
export class Helps extends Datatable {
    static create(items) {
        let helps = [];
        for (let i of items) {
            const p = new HelpItem(i);
            helps.push(p);
        }
        return new Helps(helps);
    }
    constructor(items) {
        super(items, {
            text: ''
        });
    }
    isEqual(a, b) {
        return a.id == b.id;
    }
    resetSearch() {
        this._search = {
            text: ''
        };
        this.doSearch();
    }
    matchesSearchCriteria(item) {
        const result = (item.caption.toLowerCase().includes(this.search.text.toLowerCase()) || !this.search.text);
        return result;
    }
}
