import apiEndpoints from "../../api";
import { _fetchJson, _fetchJson2 } from "../../_fetch";
import { csrfToken } from "../../csrf";
import { ButtonKind, showSuccess, showWarning } from "../../dialogs";
import { blockForm } from "../../utils";
export class PageEdit {
    id;
    name;
    _modal;
    loading = true;
    working = false;
    campaignId = 0;
    activeDomains = [];
    domainId = '';
    path = '';
    archived = false;
    totalProtectionEnabled = true;
    latestRevisionPublished = false;
    constructor(id = 0, name = '') {
        this.id = id;
        this.name = name;
        this.reset();
    }
    getDomainById(id) {
        for (let i of this.activeDomains) {
            if (i.id == id) {
                return i.domain;
            }
        }
        return '<unknown domain>';
    }
    get urlPreview() {
        if (this.domainId)
            return 'https://' + this.getDomainById(this.domainId) + '/' + trim(this.path, '/');
        else
            return '<Please select a domain>';
    }
    reset() {
    }
    async refresh() {
        try {
            this.loading = true;
            const json = await _fetchJson(apiEndpoints.setParams({ page_id: this.id }).PAGE_JSON_URI);
            if (json) {
                Object.assign(this, json);
            }
            else {
                this.closeModal();
            }
        }
        finally {
            this.loading = false;
        }
    }
    showModal(jsonStr) {
        this.loading = true;
        Object.assign(this, JSON.parse(jsonStr));
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).show();
    }
    closeModal() {
        bootstrap.Modal.getOrCreateInstance(this._modal(), {}).hide();
    }
    json() {
        const { $event, working, campaign, activeDomains, ...result } = JSON.parse(JSON.stringify(this));
        return JSON.stringify(result);
    }
    showCopyPublishedPageUrlModal() {
        window.dispatchEvent(new CustomEvent('showCopyPublishedPageUrlModal', { detail: { id: this.id } }));
    }
    async submit(copy = false) {
        try {
            this.working = true;
            blockForm(pageForm, true);
            const res = await _fetchJson2(apiEndpoints.PAGE_EDIT_URI, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: this.json()
            });
            if (res) {
                const self = this;
                (typeof res.hasChanges != 'undefined' && res.hasChanges) || (typeof res.latestRevisionIsPublished != 'undefined' && !res.latestRevisionIsPublished) ?
                    showWarning('The page has been saved but <b>still not published with new changes</b>! Would you like to publish now?', {
                        buttons: [
                            {
                                kind: ButtonKind.No,
                                dismiss: true,
                                onclick: function () {
                                    if (copy) {
                                        self.showCopyPublishedPageUrlModal();
                                    }
                                }
                            },
                            {
                                kind: ButtonKind.Yes,
                                dismiss: true,
                                onclick: function () {
                                    self.publish(copy);
                                }
                            }
                        ]
                    })
                    : copy ? this.showCopyPublishedPageUrlModal() : showSuccess('The page has been saved');
            }
        }
        finally {
            this.working = false;
            blockForm(pageForm, false);
            this.closeModal();
        }
    }
    async publish(copy = false) {
        const res = await _fetchJson2(apiEndpoints.setParams({ page_id: this.id }).PAGE_PUBLISH_URI, {
            method: 'POST',
        });
        if (res) {
            this.latestRevisionPublished = true;
            if (copy) {
                this.showCopyPublishedPageUrlModal();
            }
            else {
                showSuccess(`The page has been published`);
            }
        }
    }
    async archive($dispatch) {
        try {
            this.working = true;
            const json = await _fetchJson2(apiEndpoints.setParams({ page_id: this.id }).PAGE_ARCHIVE_URI, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-csrf-token': csrfToken()
                },
                body: JSON.stringify({ id: this.id, archived: !this.archived })
            });
            if (json) {
                this.archived = json.archived;
                if (json.archived) {
                    showSuccess(`The page has been archived`);
                }
                else {
                    const self = this;
                    showWarning('The page has been unarchived but <b>still not published</b>! Would you like to publish now?', {
                        buttons: [
                            {
                                kind: ButtonKind.No,
                                dismiss: true
                            },
                            {
                                kind: ButtonKind.Yes,
                                dismiss: true,
                                onclick: function () {
                                    self.publish();
                                }
                            }
                        ]
                    });
                }
                this.closeModal();
                $dispatch('editPageSuccess', JSON.stringify(json));
            }
        }
        finally {
            this.working = false;
        }
    }
}
