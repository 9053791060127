document.addEventListener('alpine:init', () => {
    Alpine.directive('field-size-content', (el, {}, { cleanup }) => {
        function fitContentHeight(el) {
            el.style.height = '';
            el.style.height = el.scrollHeight + 'px';
            window.dispatchEvent(new CustomEvent('fieldSizeContentChange'));
        }
        el.addEventListener('input', function () {
            fitContentHeight(this);
        });
        el.addEventListener('focus', function () {
            fitContentHeight(this);
        });
        const handler = window.addEventListener('updateFieldSizeContent', function () {
            fitContentHeight(el);
        });
        cleanup(() => {
            window.removeEventListener('updateFieldSizeContent', handler);
        });
        queueMicrotask(function () {
            fitContentHeight(el);
        });
    });
});
