import { Cropt } from "../../cropt";
import { onDOMContentLoaded } from "../../util";
export const croptAvatar = function () {
    let options = {
        viewport: {
            width: 220,
            height: 220,
            type: "square",
        },
        mouseWheelZoom: "off",
        zoomerInputClass: "form-range",
    };
    onDOMContentLoaded(() => {
        let image;
        const cropt = new Cropt(croptAvatarElm, options);
        uploadAvatarModalElm.addEventListener('shown.bs.modal', function () {
            cropt.bind(image).then(() => {
            });
        });
        let croptModal = new bootstrap.Modal(uploadAvatarModalElm, {});
        uploadAvatarInput.value = "";
        uploadAvatarInput.onchange = function () {
            if (uploadAvatarInput.files && uploadAvatarInput.files[0]) {
                const file = uploadAvatarInput.files[0];
                const reader = new FileReader();
                reader.onload = (e) => {
                    if (typeof e.target?.result === "string") {
                        image = e.target.result;
                        croptModal.show();
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        uploadAvatarModalOKBtn.addEventListener('click', function () {
            cropt.toCanvas(320).then((canvas) => {
                let dataURL = canvas.toDataURL();
                userAvatarElm.src = dataURL;
                let regExMatches = dataURL.match('data:(image/.*);base64,(.*)');
                avatarInput.value = regExMatches[2];
                croptModal.hide();
            });
        });
        avatarRemoveBtn.addEventListener('click', function () {
            avatarInput.value = '';
            userAvatarElm.src = '/images/default-avatar.jpg';
        });
    });
};
